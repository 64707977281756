//Import sass files

@import 'fonts';
@import 'variables';
@import 'base';
@import 'mixins';
@import 'slick';
@import 'plyr';
@import 'fullpage';
@import 'mCustomScrollbar';
@import 'aos';
@import 'main';

//custom styling


//home section
p {
	mark {
		background-color: transparent;
		white-space: nowrap;
		color: inherit;
	}
}
.tosvg{
	&.svgPrimary{
		path{
			fill: $primary !important;
			stroke: none !important;
		}
	}
	&.svgGray{
		path{
			fill: $gray !important;
			stroke: none !important;
		}
	}
	&.svgWhite{
		path{
			fill:$white !important;
			stroke: none !important;
		}
	}
	&.svgBlack{
		path{
			fill: $black !important;
			stroke: none !important;
		}
	}
}
.fullpagesections {
	.fp-section {
		min-height: 100vh;
	}
}
.fp-enabled {
	.fullpagesections {
		.fp-section {
			min-height: 0;
		}
	}	
}
@media #{$mobileportrait}{
	.fullpagesections {
		.fp-section {
			min-height: 0;
		}
	}
}
.c-main-header{
	position: fixed;
	top: 0;
	right: 0;
	z-index: 20;
	padding: $breathingSpace/2 $breathingSpace;
	display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background: transparent;
    transition: opacity 0.35s ease 0s, background 0.2s linear 0s;
	.c-btn-account{
	    align-self: baseline;
    	margin: 0 40px 0 auto;
    }
    @media #{$desktop1600}{
		padding: $breathingSpace/2 $breathingSpace1;
    }
}
.brand-logo{
	position: relative;
	display: block;
	width: 122px;
	background-color: transparent;
	background-size: 100%;
	background-attachment: scroll;
	background-position: center;
	background-repeat: no-repeat;
	height: 54px;
	&:after{
		content: '';
		display: block;
		padding-top: 31.37254901960784%;
	}
	span{
		display: none;
	}
	&.brand-white {
		background-image: url(../img/brand/logo-white.png);
	}
	&.brand-green {
		background-image: url(../img/brand/logo-green.png);
	}
	&.brand-black {
		background-image: url(../img/brand/logo-black.png);
	}
}
.header-logo {
	position: fixed;
	left: $breathingSpace;
	top: $breathingSpace/2;
	transition: all 0.35s ease 0s;
	@media #{$desktop1600}{
    	left: $breathingSpace1;
	    height: 46px;
    }
	z-index: 21;
}
.header-right {
	display: flex;
	align-items: center;
}
.c-btn-menu {
	width: 45px;
	height: 35px;
	display: block;
	.menuicon {
		width: 100%;
		transform: scale(0.8);
	}
	span {
		display: none;
	}
	path {
		transition: all 0.35s ease 0s;
		opacity: 1;
		transform: none;
		transform-origin: center;
		transform: scale(1);
	}
}
.menuopen {
	.c-btn-menu {
		path {
			&:first-child,
			&:last-child {
				transform: scale(0);
			}
			&.midA {
				transform: rotate(45deg);
			}
			&.midB {
				transform: rotate(-45deg);
			}
		}
	}
}
.c-search-box {
	display: flex;
	margin-right: 5px;
	padding: 10px 13px;
	background-color: rgba(255,255,255,0);
	will-change: background-color;
	transition: all 0.35s linear;
	.c-btn-search {
		cursor: pointer;
		box-shadow: 0;
		outline: none;
		border: 0;
		padding: 0;
		margin: 0;
		display: inline-block;
		background-color: transparent;
		.searchicon {
			display: block;
			width: 34px;
			height: 34px;
			@media #{$desktop1600}{
				width:  26px;
				height: 26px;
			}
			path{
				fill: $white !important;
				stroke: none !important;
				transition: all 0.45s ease 0s;
			}
		}
	}
	input {
		&::placeholder {
			opacity: 1;
			color: $black;
		}
		border-radius: 0;
		border: 0;
		box-shadow: none;
		outline: none;
		background-color: transparent;
		padding: 0;
		margin: 0;
		width: 0;
		margin-right: 10px;
		font-size: 20px;
		font-family: $fontsecondary;
		opacity: 0;
		visibility: hidden;
		will-change: opacity, visibility, width;
		transition: opacity 0.35s ease 0.0s, visibility 0.35s ease 0.0s, width 0.35s ease 0.2s;
	}
	&.searching {
		background-color: rgba(255,255,255,1);
		transition: all 0.45s ease 0s;
		.c-btn-search {
			.searchicon {
				path{
					fill: $black !important;
					stroke: none !important;
				}
			}
		}
		input {
			opacity: 1;
			visibility: visible;
			transition: opacity 0.35s ease 0.2s, visibility 0.35s ease 0.0s, width 0.35s ease 0s;
			width: 400px;
			@media #{$tabletportrait}{
				width: 280px;
			}
		}
	}
}
.c-fullpage-menu{
    position: fixed;
    z-index: 17;
    top: 0;
    right: $breathingSpace + 20px;
    height: 100%;
    padding: ($breathingSpace+$breathingSpace+50px) 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:center;
    text-align: center;
    > div {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		justify-content: center;
    }
    &:before, &:after{
    	content: '';
    	display: block;
    	height: 100%;
    	background: rgba(255,255,255,.25);
    	width: 1px;
    	margin-left: 3px;
    }
	&:before{
		margin-bottom: 30px;
	}
	&:after{
		margin-top: 30px;
	}
	li{
		display: block;
		margin: 20px 0;
		&.active{
			a{
				span{
					right: 32px;
					opacity: 1;
				}
				&:before{
					opacity: 0.24;
					width: 41px;
					height: 41px;
				}
			}
		}
		&:hover{
			a{
				
				&:before{
					opacity: 0.15;
					width: 27px;
					height: 27px;
				}
			}
		}
	}
	a{
		display: block;
		font-size: 13px;
		line-height: 1.3;
		letter-spacing: 0.75px;
		position: relative;
		width: 7px;
		height: 7px;
		color: $white;
		background-color: $white;
		border-radius: $circle;
		text-transform: uppercase;
		text-align: right;
		&:before{
			content: '';
			display: block;
			position: absolute;
			width:  0;
			height: 0;
			opacity: 0;
			background-color: $white;
			border-radius: $circle;
			left:50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition-property: width, height, opacity;
			transition-duration: .35s;

		}
		span{
			position: absolute;
			top: 50%;
			right: 0;
			opacity: 0;
			transform: translateY(-50%);
			transition-property: opacity, right;
			transition-duration: .35s;
			overflow: hidden;
			min-width: 100px;
			will-change: opacity, right;
			@media #{$tabletlandscape}{
				display: none;
			}
		}
	}
	@media #{$desktop1600}{
		right: $breathingSpace1 + 20px;
	}
	@media #{$tabletportrait}{
		display: none;
	}
}
.fp-viewing-deiramall,
.fp-viewing-megamall,
.fp-viewing-page-sec-about,
.fp-viewing-page-sec-cexp,
.fp-viewing-page-sec-featured,
.fp-viewing-page-sec-showcase,
.fp-viewing-about,
// .fp-viewing-cexp,
.fp-viewing-featured,
.fp-viewing-showcase,
.fp-viewing-ourhistory,
.fp-viewing-ourhistory1,
.fp-viewing-aboutus,
.fp-viewing-ourteam,
.fp-viewing-awards,
.fp-viewing-teammembers,
.fp-viewing-careers,
.fp-viewing-projectintro,
.fp-viewing-projectfacts,
.fp-viewing-journeybegins,
.fp-viewing-theparking,
.fp-viewing-more,
.fp-viewing-yassouth,
.fp-viewing-contact,
.fp-viewing-Enquiryform,
.fp-viewing-whatwedo,
.fp-viewing-clients,
.fp-viewing-casestudies,
.fp-viewing-contact {
	.c-fullpage-menu{
	    > div {
	    }
	    &:before, &:after{
	    	background: rgba(0,0,0,.3);
	    }
		&:before{
			margin-bottom: 30px;
		}
		&:after{
			margin-top: 30px;
		}
		li{
			&.active{
				a{
					span{
					}
					&:before{
					}
				}
			}
			&:hover{
				a{
					
					&:before{
					}
				}
			}
		}
		a{
			color: #c2c2c2;
			background-color: #c2c2c2;
			&:before{
				background-color: #c2c2c2;
			}
			span{
			}
		}
	}
}
.c-social{
	position: fixed;
    z-index: 16;
    top: 0;
    left: $breathingSpace + 20px;
    height: 100%;
    padding: ($breathingSpace+$breathingSpace+50px) 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:center;
    text-align: center;
    > div {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		justify-content: center;
    }
	&:before, &:after{
    	content: '';
    	display: block;
    	height: 100%;
    	background: rgba(255,255,255,.25);
    	width: 1px;
    	margin-left: 3px;
    }
	&:before{
		margin-bottom: 30px;
	}
	&:after{
		margin-top: 30px;
	}
	a{
		display: flex;
		width: 32px;
		height: 32px;
		position: relative;
		margin: 10px 0;
		text-align: center;
		background-color: rgba(0,0,0,0);
		color: $black;
		padding: 0;
		flex-direction: column;
		justify-content: center;
		will-change: background-color;
		transition: all 0.35s ease 0s;
		.scicons {
			max-width: 100%;
			max-height: 100%;
			display: block;
			path{
				transition: all 0.35s ease 0s;
				fill: rgba(255,255,255,0.75);
			}
		}
		span{
			display: none;
		}
		&:hover {
			path{
				fill: rgba(255,255,255,1)
			}
		}
	}
	@media #{$desktop1600}{
		left: $breathingSpace1 + 20px;
	}
	@media #{$tabletportrait}{
		display: none;
	}
}
.shadedImg {
	&:after {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		background: linear-gradient(to bottom, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0.5) 100%);
		opacity: 0.8;
		content: "";
	}
	
}
.c-background{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	overflow: hidden;
	.plyr--video {
		position: absolute;
		width: 120%;
		height: 120%;
		max-width: 120%;
		left: -10%;
		top: -10%;
	}
	// &:after {
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	// 	z-index: 2;
	// 	left: 0;
	// 	top: 0;
	// 	background: linear-gradient(to bottom, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0.5) 100%);
	// 	opacity: 0.8;
	// 	content: "";
	// }
	.bg-img,
	.bg-video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000 no-repeat center center;
		// background-size: cover;
		overflow: hidden;
		video {
			width: 150%;
			left: -25%;
			position: absolute;
		}
		&.bg-img--exp-sec{
			opacity: 0;
			transition: opacity .35s;
			filter: blur(5px);
			padding: 20px;
			left: -10px;
			top:  -10px;
			box-sizing: content-box;
			will-change: opacity;

			&:after{
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0,0,0,0.75);
			}
		}
	}
	.custom--bg-img{
		img{
			max-width: none;
			min-width: 100%;
			min-height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

}
.custom--bg-img{
	img{
		max-width: none;
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.clientSlider {
	.client-logo {
		height: 140px;
		padding: 20px 10px;
		text-align: center;
		margin-top: 15px;
		@media #{$tabletportrait}{
			padding: 10px;
			height: 120px;
		}
		img {
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
			filter: saturate(0px)
		}
	}
}
.c-scroll-down {
	position: fixed;
	padding: $breathingSpace 0;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
	z-index: 15;
	opacity: 0;
	visibility: hidden;
	transition: all .35s;
	will-change: visibility, opacity;
	span{
		display: none;
	}
	.scrollicon {
		display: block;
		width: 30px;
		height: 45px;
	}
	.mousewheel {
	  animation: dash 1s linear infinite;
	}
	@media #{$desktop1600}{
		padding: $breathingSpace1 0;
	}
	@media #{$tabletportrait}{
		display: none;
	}
}
@keyframes dash {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.c-chat-icons {
	position: fixed;
	padding: $breathingSpace 0;
	right: $breathingSpace;
	bottom: 0;
	z-index: 14;
	opacity: 0.75;
	transition: all .35s;
	will-change: transform, opacity;
	span{
		display: none;
	}
	.chaticon {
		display: block;
		width: 45px;
		height: 45px;
	}
	&:hover {
		opacity: 1
	}
	@media #{$desktop1600}{
		right: $breathingSpace1;
		padding: $breathingSpace1 0;
	}
}

@keyframes pulse {
    0% {
    	transform: translateY(0px);
    }
    10% {
    	transform: translateY(4px);
    }
    20% {
    	transform: translateY(0px);
    }
    30% {
    	transform: translateY(4px);
    }
    40% {
    	transform: translateY(0px);
    }
    100% {
    	transform: translateY(0px);
    }
}

.banner-playvdo {
	display: block;
	position: absolute;
	z-index: 12;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.45;
	width: 120px;
	height: 120px;
	transition: all 0.35s ease 0s;
	.playicon {
		width: 100%;
		height: 100%;
	}
	&:after {
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
	    border: 1px solid $white;
	    border-radius: $circle;
	    height: 120px;
	    width: 120px;
	    // margin: -60px 0 0 -60px;
	    opacity: 0.0;
	    transition: all 0.35s ease 0s;
	    transform: translate(-50%,-50%) scale(1);
	    transform-origin: center center;
	}
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
	    border: 1px solid $white;
	    border-radius: $circle;
	    height: 120px;
	    width: 120px;
	    // margin: -60px 0 0 -60px;
	    opacity: 0.0;
	    transition: all 0.35s ease 0s;
	    transform: translate(-50%,-50%) scale(1);
	    transform-origin: center center;
	}
    @media #{$mobileportrait}{
    	width: 60px;
    	height: 60px;
	    &:after,
	    &:before {
	        width:  60px;
	        height: 60px;
	        // margin: -30px 0 0 -30px;
	    }
    }
    &:hover {
		opacity: 1;
		&:after {
			animation: pulsate 2s ease-out;
		    animation-iteration-count: infinite; 
			animation-play-state: running;	
		}
		&:before {
			animation: pulsate 1.4s ease-out;
		    animation-iteration-count: infinite; 
			animation-play-state: running;
		}
	}
	&.small {
	    width: 80px;
	    height: 80px;
	    &:after,
	    &:before {
	        width: 80px;
	        height: 80px;
	        // margin: -40px 0 0 -40px;
	    }
	    @media #{$mobileportrait}{
	    	width: 60px;
	    	height: 60px;
		    &:after,
		    &:before {
		        width:  60px;
		        height: 60px;
		        // margin: -20px 0 0 -20px;
		    }
	    }
	}
	span {
		display: none;
	}

	&.no--hover{
		&:after, &:before{
			display: none !important;
		}
	}
}
.js-popup-link {
	&:hover {
		.banner-playvdo {
			opacity: 1;
			&:after {
				animation: pulsate 2s ease-out;
				animation-iteration-count: infinite; 
				animation-play-state: running;	
			}
			&:before {
				animation: pulsate 1.4s ease-out;
				animation-iteration-count: infinite; 
				animation-play-state: running;
			}
		}
	}
}
@keyframes pulsate {
    0% {transform: translate(-50%,-50%) scale(1); opacity: 0.0; }
    50% {opacity: 0.7;}
    100% {transform: translate(-50%,-50%) scale(1.4); opacity: 0.0; }
}

.sec-home {
	position: relative;
	.container{
		position: relative;
		z-index: 3;
		height: 100%;
	}
	.banner-content {
		padding: 60px 60px 0 0;
		position: absolute;
		left: 0;
		bottom: 0;
		color: #FFF;
		z-index: 10;
		font-family: $fontprimary;
		max-width: 720px;
		p {
			font-size: 20px;
			font-family: $fontprimary;
			font-weight: normal;
			line-height: 1.23;
			margin-bottom: 15px;
		}
		h2 {
			font-size: 52px;
			font-weight: bold;
			font-style: italic;
			text-transform: uppercase;
		    @media #{$desktop1600} {
		        font-size: 46px;
		    }
		    @media #{$desktop1280}{
		        font-size: 40px;
		    }
		    @media #{$tabletlandscape}{
		        font-size: 36px
		    }
		    @media #{$mobileportrait} {
		        font-size: 30px;
		    }
		}
		.cta-btn {
			margin-top: 25px;
		}
	    @media #{$desktop1280}{
	        padding: 0;
	    }
		&.content-relative {
			position: relative;
			padding: ($breathingSpace*4) 0 ($breathingSpace+$breathingSpace) 0;
			@media #{$mobileportrait}{
					padding: ($breathingSpace*4) 0 ($breathingSpace) 0;
			}
		}
	}
}
.fullpagesections {
	position: relative;
	.fp-section {
		position: relative;
		.fp-tableCell {
			padding: ($breathingSpace+$breathingSpace+55px) 210px;
			
		}
		.fp-slides {
			position: relative;
			.fp-tableCell {
				padding: 0;
			}
		}
		&.sec-footer {
			.fp-tableCell {
				padding: $breathingSpace $breathingSpace 0 $breathingSpace;
			}
		}
		&.sec-nopadding {
			.fp-tableCell {
				padding: 0;
			}
		}
		&.sec-nopaddingX {
			.fp-tableCell {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	@media #{$desktop1600}{
		.fp-section {
			.fp-tableCell {
				padding: ($breathingSpace1+$breathingSpace1+55px) 160px;
			}
			&.sec-footer {
				.fp-tableCell {
					padding: $breathingSpace1 $breathingSpace1 0 $breathingSpace1
				}
			}
		}	
	}
	@media #{$tabletlandscape}{
		.fp-section {
			.fp-tableCell {
				padding: ($breathingSpace1+$breathingSpace1+55px) 115px;
			}
			&.sec-footer {
				.fp-tableCell {
					padding: $breathingSpace1 $breathingSpace1 0 $breathingSpace1
				}
			}
		}	
	}
	@media #{$tabletportrait}{
		.fp-section {
			.fp-tableCell {
				padding: ($breathingSpace1+$breathingSpace1+55px) $breathingSpace1 $breathingSpace1 $breathingSpace1 ;
			}
		}	
	}
}
.fullpagesections .fp-section.sec-nopadding.sec-teammembers .fp-tableCell{
	@media #{$desktop1600}{
		padding-left: 160px;
	}
	@media #{$tabletportrait}{
		padding-left: 0;
	}
}
.text-uppercase {
	text-transform: uppercase;
}
.txt-light {
	//color: #d1d1d1 !important;
	font-weight: lighter !important;
}
.cta-btn {
	display: inline-block;
	font-size: 20px;
	padding: 13px 20px;
	line-height: 1;
	border: 1px solid $black;
	color: $black;
	text-transform: capitalize;
	background-color: transparent; 
	box-shadow: none;
	cursor: pointer;
	font-weight: 300;
	position: relative;
	transition: all 0.35s ease 0s;
	font-family: $fontprimary;
	@media #{$tabletlandscape}{
		font-size: 18px;
		padding: 13px 15px;
	}
	&.nocase {
		text-transform: none;
	}
	&:before,
	&:after {
		width: 30px;
		height: 30px;
		position: absolute;
		border: 0;
		content: "";
		opacity: 0;
		transition: all 0.35s ease 0s;
	}
	&:before {
		top: 0;
		left: 0;
		border-top: 1px solid;
		border-left: 1px solid;
	}
	&:after {
		bottom: 0;
		right: 0;
		border-bottom: 1px solid;
		border-right: 1px solid;
	}
	&:hover,
	&.active {
		&:before,
		&:after {
			opacity: 0.5;
		}
		&:before {
			top: -10px;
			left: -10px;
		}
		&:after {
			bottom: -10px;
			right: -10px;
		}
	}
	.ctaicon {
		width: 16px;
		height: 12px;
		margin-left: 10px;
		path{
			fill:$black !important;
			stroke: none !important;
		}
	}
	&.btn-filled {
		background-color: $black;
		color: $white;
		border-color: $black;
		.ctaicon {
			path{
				fill:$white !important;
				stroke: none !important;
			}
		}
		&:hover {
			background-color: rgba(41,40,41,0.8);
		}
	}
	&.btn-white {
		color: $white;
		border-color: $white;
		.ctaicon {
			path{
				fill:$white !important;
				stroke: none !important;
			}
		}
		&.btn-filled {
			background-color: $white;
			color: $black;
			border-color: $white;
			.ctaicon {
				path{
					fill:$black !important;
					stroke: none !important;
				}
			}
			&:hover {
				background-color: rgba(255,255,255,0.8);
			}
		}
	}
	&.btn-gray {
		color: $gray;
		border-color: $gray;
		.ctaicon {
			path{
				fill:$gray !important;
				stroke: none !important;
			}
		}
		&.btn-filled {
			background-color: $gray;
			color: $white;
			border-color: $gray;
			.ctaicon {
				path{
					fill:$white !important;
					stroke: none !important;
				}
			}
			&:hover {
				background-color: rgba(202,202,202,0.8);
			}			
		}
	}
	&.btn-primary {
		color: $primary;
		border-color: $primary;
		.ctaicon {
			path{
				fill:$primary !important;
				stroke: none !important;
			}
		}
		&.btn-filled {
			background-color: $primary;
			color: $white;
			border-color: $primary;
			.ctaicon {
				path{
					fill:$white !important;
					stroke: none !important;
				}
			}
			&:hover {
				background-color: rgba(141,198,63,0.8);
			}			

		}
	}
	&.btn-right {
		display: block;
		margin-left: auto;
	}
}

.fp-viewing-page-sec-home, .fp-viewing-limahhome, .fp-viewing-pgbanner  {
	.c-scroll-down {
		opacity: 1;
		visibility: visible;
	}
}
.fp-viewing-page-sec-about,
.fp-viewing-page-sec-cexp,
.fp-viewing-page-sec-featured,
.fp-viewing-page-sec-showcase,
.fp-viewing-about,
.fp-viewing-cexp,
.fp-viewing-featured,
.fp-viewing-showcase,
// .fp-viewing-ourhistory,
.fp-viewing-ourhistory1,
.fp-viewing-aboutus,
.fp-viewing-ourteam,
.fp-viewing-awards,
.fp-viewing-teammembers,
.fp-viewing-careers,
.fp-viewing-projectintro,
.fp-viewing-projectfacts,
.fp-viewing-journeybegins,
.fp-viewing-theparking,
.fp-viewing-more,
.fp-viewing-yassouth,
.fp-viewing-contact,
.fp-viewing-Enquiryform,
.fp-viewing-whatwedo,
.fp-viewing-clients,
.fp-viewing-casestudies,
.fp-viewing-contact {
	.c-social{
		&:before, &:after{
	    	background: rgba(0,0,0,.3);
		}
		a{
			.scicons {
				path{
					fill: rgba(0,0,0,0.3);
				}
			}
			&:hover {
				.scicons {
					path{
						fill: rgba(0,0,0,0.6);
					}
				}

			}
		}
	}
}

.sec-about {
	.c-background {
		width: 40%;
		left: auto;
		right: 0;
		@media #{$tabletportrait}{
			position: relative;
			width: 100%;
			height: 40%;
		}
		// &:after {
		// 	display: none;
		// }
	}
	.about-content {
		width: 60%;
		max-width: 590px;
		@media #{$tabletportrait}{
			width: 100%;
			max-width: none;
			margin-top: 30px;
		}
	}
	.hd-style1 {
		padding-right: 80px;
	}
	.cta-btn {
		margin-top: 90px;
		@media #{$desktop1600}{
			margin-top: 60px;
		}
		@media #{$tabletlandscape}{
			margin-top: 40px;
		}
	}
	&.toLeft{
		.c-background{
			right: auto;
			left: 0;
		}
		.about-content {
			margin-left: auto;
		}
	}
}
.hd-style1 {
	font-size: 30px;
	line-height: 1.5;
	font-style: italic;
	text-transform: uppercase;
	font-weight: bold;
	small {
		display: block;
		font-size: 20px;
		font-weight: normal;
		font-style: normal;
	}
	@media #{$desktop1600}{
		font-size: 26px;
		small {
			font-size: 19px;
		}
	}
	@media #{$tabletlandscape}{
		font-size: 22px;
		small {
			font-size: 18px;
		}
	}
}
.servicesSlider {
	position: relative;
	width: 100%;
	height: 100%;
	.section-heading {
		display: none;
	}
	.sliderbackground {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $black;
		top: 0;
		left: 0;
		.slick-list,
		.slick-track,
		.slick-slide, {
			width: 100%;
			height: 100%;
		}
		.slick-slide div {
			width: 100%;
			height: 100%;
		}
		.bg-img {
			width: 100%;
			height: 100%;
			background-position: center center;
			background-size: cover;
			background-attachment: scroll;
			background-repeat: no-repeat;
			overflow: hidden;
		}
	}
	.contentarea {
		display: flex;
		align-items: center;
		height: 100%;
		.flexarea {
			display: flex;
			padding: 0 $breathingSpace+$breathingSpace;
			position: relative;
			@media #{$tabletlandscape}{
				padding: 0
			}
			.leftside  {
				display: block;
				width: 40%;
				flex-shrink: 0;
				min-height: 100%;
				background-color: $primary;
				.slick-list,
				.slick-track,
				.slick-slide, {
					width: 100%;
					height: 100%;
				}
				.slick-slide div {
					width: 100%;
					height: 100%;
				}
				.thumb {
					width: 100%;
					height: 100%;
					display: block;
					background-repeat: no-repeat;
					background-size: cover;
					background-position:  center;
					background-attachment: scroll;
				}
			}
			.rightside {
				background-color: $white;
				position: relative;
				padding: $breathingSpace $breathingSpace ($breathingSpace+$breathingSpace) $breathingSpace; 
				width: 60%;
				flex-shrink: 0;
				.slide-content {
					position: relative;
					width: 100%;
					padding: 10px;
					.cta-btn {
						margin-top: 20px;
					}
				}
				@media #{$tabletlandscape}{
					padding: $breathingSpace1 $breathingSpace1 ($breathingSpace1+$breathingSpace1) $breathingSpace1; 
				}
			}
			&::before {
			    content: '';
			    height: 100%;
			    width: calc(100% - 160px);
			    position: absolute;
			    left: 80px;
			    top: 0;
			    background-color: transparent;
			    box-shadow: -30px 30px 50px rgba(33,36,34,0.35);
			    z-index: 0;
			}
		}
	}
	@media #{$tabletportrait}{
		.sliderbackground {
			.slick-list,
			.slick-track,
			.slick-slide, {
			}
			.slick-slide div {
			}
			.bg-img {
			}
		}
		.contentarea {
			.flexarea {
				flex-direction: column;
				.leftside  {
					width: 100%; 
					.slick-list,
					.slick-track,
					.slick-slide, {
					}
					.slick-slide div {
					}
					.thumb {
						padding-top: 48%;
						display: block !important;
					}
				}
				.rightside {
					width: 100%; 
					padding: $breathingSpace; 
					.slide-content {
						.cta-btn {
							margin-top: 10px;
						}
					}
				}
				&::before {
				}
			}
		}
	}
}
.casestudySlider {
	position: relative;
	width: 100%;
	height: 100%;
	.sliderbackground {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $black;
		top: 0;
		left: 0;
		.slick-list,
		.slick-track,
		.slick-slide, {
			width: 100%;
			height: 100%;
		}
		.slick-slide div {
			width: 100%;
			height: 100%;
		}
		.bg-img {
			width: 100%;
			height: 100%;
			background-position: center center;
			background-size: cover;
			background-attachment: scroll;
			background-repeat: no-repeat;
			overflow: hidden;
		}
	}
	.contentarea {
		display: flex;
		align-items: center;
		height: 100%;
		.flexarea {
			display: flex;
			padding: 0 ($breathingSpace+$breathingSpace);
			.leftside  {
				display: block;
				width: 40%;
				flex-shrink: 0;
				min-height: 100%;
			}
			.rightside {
				position: relative;
				padding: $breathingSpace $breathingSpace ($breathingSpace+$breathingSpace+$breathingSpace) $breathingSpace; 
				width: 60%;
				flex-shrink: 0;
				.slide-content {
					position: relative;
					width: 100%;
					padding: 10px;
					.cta-btn {
						margin-top: ($breathingSpace)
					}
				}
			}
		}
	}
	.slidenumber {
		small {
			display: inline-block;
		}
	}
	@media #{$tabletportrait}{
		.sliderbackground {
			.slick-list,
			.slick-track,
			.slick-slide, {
			}
			.slick-slide div {
			}
			.bg-img {
			}
		}
		.contentarea {
			align-items:flex-end;
			.flexarea {
				padding: 0 0 80px 0;
				margin-bottom: 100px;
				.leftside  {
					display: none;
				}
				.rightside {
					width: 100%;
					position: relative;
					padding: 0;
					.slide-content {
						.cta-btn {
							margin-top: 10px;
						}
					}
				}
			}
		}
		.slidenumber {
			small {
				display: inline-block;
			}
		}	
	}
}
.slidernavigation {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: $breathingSpace/2 $breathingSpace;
	font-size: 0;
	a {
		display: inline-block;
		padding: 8px 15px;
		.ctaicon {
			width: 30px;
		}
		&:first-child {
			border-right: 1px solid rgba(0,0,0,.2)
		}
		&.disabled,
		&.slick-disabled {
			pointer-events: none;
			.ctaicon {
				opacity: 0.4
			}
		}
	}
	@media #{$tabletlandscape}{
		padding: $breathingSpace1;
	}
	@media #{$tabletportrait}{
		left: 0;
		top: 100%;
		right: auto;
		bottom: auto;
		padding: $breathingSpace1 0;
	}
}
@media #{$mobileportrait} {
	.sec-services {
		.slidernavigation {
			a {
				&:first-child {
					border-right-color: hsla(0,0%,100%,.2);
					padding-left: 0;
				}
				.tosvg{
					&.svgBlack {
						path {
							fill: $white !important;
						}
					}
				}
			}
		}
	}
}
.slidenumber {
	display: block;
	position: absolute;
	font-size: 50px;
	font-family: $fontprimary;
	color: rgba(255,255,255,0.70);
	font-style: italic;
	right: 0;
	bottom: -70px;
	font-weight: bold;
	.slick-dots{
		font-size: inherit;
	}
	small {
		font-size: 25px;
		font-weight: normal;
		display: none;
	}
	ul {
		padding: 0;
		list-style: none;
		margin: 0;
		li {
			display: none;
			&.slick-active {
				display: block;
			}
		}
	}
	@media #{$tabletlandscape}{
		font-size: 40px;
	}
}
.historyTimeline {
	.slidenumber {
		text-align: right;
		position: relative;
		color: $black;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
		.slick-dots{
			text-align: right;
		}
		small {
			display: inline-block;
		}
	}	
}
.fphomeslide {
	position: relative;
	.c-background {
		position: absolute;
	}
}

.sec-casestudy {
	color: $white;
	.slide-data {
		.thumb {
			background-color: transparent;
		}
		.slide-content {
			background-color: transparent;
		}
	}
	.slidernavigation {
		padding: $breathingSpace 0;
		a {
			&:first-child {
				border-right: 1px solid rgba(255,255,255,.2)
			}
			&:last-child {
				padding-right: 0
			}
		}
	}
}
.footer-nav {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	.brand-logo {
		margin-right: 40px;
		height: auto;
	}
	.foo-nav-item {
		padding: 0 20px;
		text-transform: uppercase;
		font-size: 13px;
	}
}
.innerpage {
	.footer-nav {
		padding-top: $breathingSpace;
		border-top: 1px solid rgba(#898989,.3)
	}
	.banner-content {
		max-width: 50%;
		@media #{$tabletlandscape}{
			padding-right: 0;
			max-width: 60%;
		}
	}
}
.copyright {
	padding: 25px 0 25px 0;
	margin-top: 20px;
	border-top: 1px solid rgba(#898989,.3);
	font-size: 0;
	text-align: center;
	.foo-nav-item {
		display: inline-block;
		padding: 0 40px;
		text-transform: uppercase;
		font-size: 13px;
		line-height: 1.3;
		&:last-child {
			border-left: 1px solid
		}
	}		
}
.footer-search {
	display: flex;
	padding: 50px 0 20px 0;
	align-items: flex-end;
    justify-content: space-between;
	.search-box {
		display: block;
		position: relative;
		font-size: 0;
		form {
			font-size: 0;
		}
		label {
			display: block;
			font-size: 13px;
			text-transform: uppercase;
			margin-bottom: 10px;
		}
		input {
			display: inline-block;
			vertical-align: top;
			width: 260px;
			height: 40px;
			border: 1px solid $gray;
		    border-right: 0;
		    border-radius: 0;
			line-height: 40px;
			padding: 0 10px;
			margin: 0;
			font-family: $fontprimary;
			font-size: 15px;
			&.error {
				border-color: red
			}
			&.valid {
				border-color: green;
				+button +.error +.valid {
					display: block;
				}
			}

		}
		button {
			display: inline-block;
			vertical-align: top;
			background-color: $gray;
			border: 1px solid $gray;
			height: 40px;
			width: auto;
			max-width: 60px;
			box-shadow: none;
			cursor: pointer;
			margin-left: -1px;
			transition: all 0.35s ease 0s;
			&:hover {
				background-color: $black
			}
			.searchicon {
				height: 12px;
				line-height: 40px;
				padding: 0 10px
			}
		}
		.validation-error {
			position: absolute;
			color: red;
			font-size: 12px !important;
			margin: 0;
			padding: 5px 0;
			label {
				text-transform: none;
				font-size: 12px
			}
		}
		.validation-valid {
			display: none;
			position: absolute;
			color: green;
			font-size: 12px !important;
			margin: 0;
			padding: 5px 0;
			text-transform: none;
		}
	}
}
.footer-social {
	display: flex;
	flex-direction: row;
	a{
		display: block;
		width: 36px;
		height: 36px;
		position: relative;
		margin: 0 10px;
		text-align: center;
		background-color: rgba(255,255,255,0);
		border: 1px solid $gray; 
		color: $white;
		padding: 10px;
		border-radius: $circle;
		will-change: background-color;
		transition: all 0.35s ease 0s;
		.scicons {
			max-width: 100%;
			max-height: 100%;
			display: block;
			path{
				transition: all 0.35s ease 0s;
				fill: $gray !important;
			}

		}
		span{
			display: none;
		}
		&:hover {
			background-color: $gray;
			.scicons {
				path{
					fill: $white !important;
				}

			}
		}
	}
	&.contact-social {
		a{
			width:  59px;
			height: 59px;
			margin: 0 12px;
			padding: 15px;
			line-height: 39px;
			.scicons {
				display: inline-block;
			}
		}
	}
}


.c-menuScreen {
	z-index: 19;
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0,0,0,0.9) url(../img/background/bg25.jpg) center center no-repeat scroll;
	background-size: cover;
	display: none;
	overflow: hidden;
    .menuscreen-cover {
    	display: flex;
    	height: 100%;
    	width: 100%;
    	flex-direction: column;
    	overflow: hidden;
    	padding-top: 120px;
    	position: relative;
    }
	.c-btn-menu {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
		padding: 55px 40px 0 40px;
		width: auto;
		height: auto;
		display: table;
		.closeicon {
			width: 20px;
			height: 20px;
			polygon {
				fill: $white;
			}
		}
		@media #{$desktop1600}{
	    	padding: 37px $breathingSpace1;
	    }
	}
	.c-navigation {
	    position: relative;
	    display: block;
	    width: 100%;
	    overflow-y: auto;
	    > ul {
			display: block;
			list-style: none;
			padding: 0;
			margin: 0;
      		> li {
				padding: $breathingSpace $breathingSpace*4;
				border-bottom: 1px solid rgba(255,255,255,0.05);
				background-color: rgba(255,255,255,0.0);
				transition: all 0.35s ease 0s;
        		> a  {
					display: block;
					color: rgba(255,255,255,0.25);
					font-size: 32px;
					font-weight: bold;
					font-style: italic;
					text-transform: uppercase;
					transition: all 0.35s ease 0s;
					max-width: 1200px;
					margin: 0 auto;
        		}
        		.c-sub-nevigation{
        			display: none;
        			list-style: none;
        			padding: 0;
        			margin: 0;
        			font-size: 0;
					max-width: 1200px;
					margin: 0 auto;
        			> li {
        				position: relative;
        				display: inline-block;
						> a {
        					display: block;
        					font-size: 22px;
        					font-weight: normal;
							padding: 40px 0 2px 0;
							margin: 0 30px;
							color: #ffffff;
							border-bottom: 1px solid rgba(255,255,255,0);
							transition: all 0.35s ease 0s;
							will-change: color, border-bottom-color;
							text-transform: uppercase;
        				}
        				&:first-child{
        					a{
        						margin-left: 0;
        					}
        				}
						&.active,
						&:hover{
							a{
								color: $primary;
								border-bottom-color: $primary; 
							}
						}
        			}
        		}
        		&.js-hasChild{
        			> a:after {
					    content: "";
					    width: 24px;
					    height: 24px;
					    margin-left: 50px;
					    display: inline-block;
					    background-image: url(../img/icons/ic-angle-right-white.svg);
					    background-size: contain;
					    background-repeat: no-repeat;
					    background-position: center;
					    transform-origin: center;
						transform: rotate(0deg);
						opacity: 0.25;
					    transition: 0.35s all ease 0s;
					}
					&.active{
	        			> a:after{
	        				transform: rotate(90deg);
	        				opacity: 1;
	        			}
	        		}
        		}
				&.active,
        		&:hover {
					background-color: rgba(255,255,255,0.1);
					a {
						color: rgba(255,255,255,1);
					&:after{
						opacity:1;		
						}
					}
        		}
      		}
    	}
  	}
  	@media #{$tabletlandscape}{
  		.c-navigation {
			> ul {
				> li {
					padding: $breathingSpace1 $breathingSpace1*3;
					> a  {
						font-size: 24px;
	        		}
	        		.c-sub-nevigation{
	        			> li {
							> a {
	        					font-size: 18px;
								padding: 25px 0 2px 0;
	        				}
	        			}
	        		}
	      		}
	    	}
	  	}
	}
}
.fp-viewing-footer,
.fp-viewing-awards,
.fp-viewing-showcase,
.fp-viewing-Solutuon,
.fp-viewing-video {
	.c-social,
	.c-fullpage-menu,
	.c-chat-icons {
		opacity: 0;
		visibility: hidden;
	}
}
.fp-viewing-teammembers {
	.c-fullpage-menu,
	.c-chat-icons {
		opacity: 0;
		visibility: hidden;
	}
}
.c-social,
.c-chat-icons {
	opacity: 1;
	transition: all 0.35s ease 0s;
	visibility: visible;
}
.popup-is-active {
	body {
		overflow: hidden !important;
	}
}
.menuopen {
	overflow: hidden !important;
	.fullpagesections {
		// filter: blur(30px);
		// transform: scale(1.02);
		overflow: hidden;
	}
	> .c-fullpage-menu,
	> .c-social,
	> .c-scroll-down,
	> .c-chat-icons {
		opacity: 0;
		transition: opacity 0.25s ease 0s, margin 0s;
	}
	// > .c-fullpage-menu,
	// > .c-chat-icons {
	// 	margin-right: 17px;
	// }
	.c-search-box {
		opacity: 0;
		visibility: hidden;
	}

}
.fullpagesections {
	// // filter: blur(0);
	// // transform-origin: center;
	// // transform: scale(1);
	// // overflow: inherit;
	// transition: all 0.15s ease-in-out 0s;
}



/* ==================================== */
/* Popup CSS */
/* ==================================== */

.c-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}

.c-popup .overlay {
    display: block;
    height: 100%;
    width: 100%;
    background: transparent;
    transition: all .3s;
}

.c-popup.popup--open .overlay {
    background: rgba(0, 0, 0, 0.5);
}

.c-popup .popup {
    display: none;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 740px;
    padding: 20px;
    max-height: 100%;
    width: 100%;
    opacity: 0;
    top: 40%;
    transition: all .3s 0s;
    overflow-y: auto;
}

.c-popup .popup.popup--full-screen {
    top: 0;
    left: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    width: 100%;
    height: 100%;
    padding: 0;
    max-width: none;
}

.c-popup .popup.popup--full-screen.active {
    top: 0;
}

.c-popup .popup.popup--full-screen .popup-wrap {
    padding: 0;
    height: 100%;
}

.c-popup .popup.popup--full-screen .popup-wrap .plyr {
    height: 100%;
}

.c-popup .popup .popup-wrap {
    background: #fff;
    padding: 20px;
}

.c-popup .popup.active {
    top: 50%;
    opacity: 1;
    transition-delay: .3s;
}

.c-popup .popup.popup--full-screen .popup-action {
    position: absolute;
    top: 10px;
    right: 17px;
    z-index: 999;
    margin: 0;
}

.c-popup .title {
    font-size: 1.5625em;
    line-height: 1.3em;
    margin: 0 0 15px;
    font-weight: bold;
    color: #8dc63f;
}

.c-popup .txt-wrap {
    max-width: 500px;
    margin: 0 auto;
}

.c-popup .deco {
    display: block;
    margin: 5px 0 15px 0;
    border: none;
    position: relative;
    height: 1px;
    background: #9f9a9a;
}

.c-popup .deco:after {
    content: '';
    display: block;
    width: 80px;
    height: 6px;
    background: #8dc63f;
    position: absolute;
    bottom: 0px;
    left: 20px;
}

.c-popup .popup-action {
    text-align: center;
    margin-top: 20px;
}
.c-popup .js-video{
    height: 100%;
}

/* ==================================== */
/* END - Popup CSS */
/* ==================================== */




/* ==================================== */
/* Popup Close BUtton  */
/* ==================================== */

.c-close {
    display: inline-block;
    width: 27px;
    height: 27px;
    background: url(../img/icons/ic-close.svg) center no-repeat scroll;
    background-size: 16px 16px;
    background-color: #292829;
    border-radius: 50%;


}
.c-close span {
    display: none;
}
/* ==================================== */
/* END - Popup Close BUtton  */
/* ==================================== */
/* Some responsive css for close button and popup */

@media (max-width: 767px) {
    .c-popup .deco:after {
        width: 60px;
        height: 4px;
        left: 15px;
    }
    .c-popup .popup .popup-wrap {
        padding: 15px;
    }
    .popup .c-form-1 {
        position: static;
        margin: 0;
    }
    .popup .c-form-1 .wrap {
        padding: 0;
    }
    .popup .c-form-1 select,
    .popup .c-form-1 .select2-container {
        width: 100% !important;
    }
}
/* This will keep the video to be complete visible rather than partially! */
.plyr__video-wrapper{
    height: 100%;
    padding: 0 !important;
}

.customsections {
	.sec-footer {
		padding: $breathingSpace $breathingSpace 0;
	}
	@media #{$tabletportrait}{
		.sec-footer {
			padding-left: 25px;
			padding-right: 25px;
			padding-top: 25px;
		}
	}
	@media #{$mobileportrait}{
		.sec-footer {
			padding-top: 0;
		}
	}
	@media #{$mobilemidium}{
		.sec-footer {
			padding-left:  10px;
			padding-right: 10px;
		}
	}
}

.sec-pgcontent {
	padding: ($breathingSpace*2) 0;
	&.form-sec {
		padding-bottom: 0;
	}
	@media #{$tabletportrait}{
		padding: ($breathingSpace) 0;
	}
	@media #{$mobileportrait}{
		padding: ($breathingSpace1) 0;
		&.form-sec {
			padding-bottom: $breathingSpace1;
		}
	}
}
.blog-detail {
	.blog-head{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}
	.blog-info {
		margin-top: 20px;
		display: flex;
		flex-align: center;
		.author-thumb {
			width: 60px;
			height: 60px;
			display: flex;
			overflow: hidden;
			border-radius: $circle;
			background-color: $white;
			flex-direction: column;
			justify-content: center;
			margin-right: 15px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.author {
			p {
				font-family: $fontprimary;
				font-size: 20px;
				line-height: 1.5;
				small {
					display: block;
					font-size: 18px;
					opacity: 0.8;
				}
			}
		}
	}
	.blog-social {
		display: flex;
		flex-direction: row;
		a{
			display: block;
			width: 60px;
			height: 60px;
			position: relative;
			margin: 0 10px;
			text-align: center;
			background-color: rgba(255,255,255,0);
			border: 1px solid $gray; 
			color: $white;
			padding: 16px;
			border-radius: $circle;
			will-change: background-color;
			transition: all 0.35s ease 0s;
			.scicons {
				max-width: 100%;
				max-height: 100%;
				display: block;
				path{
					transition: all 0.35s ease 0s;
					fill: $gray !important;
				}

			}
			span{
				display: none;
			}
			&:hover {
				background-color: $gray;
				.scicons {
					path{
						fill: $white !important;
					}

				}
			}
		}
	}
}
.blog-listing {
	display: flex;
	position: relative;
	margin: 0 -12px;
	flex-direction: row;
	flex-wrap: wrap;
	.blog-item {
		display: block;
		position: relative;
		padding: 20px 12px;
		width: 33.3333%;
		@media #{$mobileportrait}{
			width: 100%;
		}
		h3 {
			display: block;
			font-size: 30px;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 5px;
		}
		p {
			font-size: 20px;
			line-height: 1.5;
			font-family: $fontprimary;
			max-height: 70px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.blog-thumb {
			width: 100%;
			padding-top: 80%;
			margin: 15px 0;
			position: relative;
			background-color: $gray;
			background-attachment: scroll;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center top;
			@media #{$mobileportrait}{
				margin: 0;
			}
		}
		.blog-info {
			margin-top: 20px;
			display: flex;
			flex-align: center;
			.author-thumb {
				width: 60px;
				height: 60px;
				display: flex;
				overflow: hidden;
				border-radius: $circle;
				background-color: $white;
				flex-direction: column;
				justify-content: center;
				margin-right: 15px;
				img {
					width: 100%;
					height: auto;
				}
			}
			.author {
				p {
					font-family: $fontprimary;
					font-size: 20px;
					line-height: 1.5;
					small {
						display: block;
						font-size: 18px;
						opacity: 0.8;
					}
				}
			}
		}
	}
	@media #{$desktop1600}{
		.blog-item {
			h3 {
				font-size: 26px;
			}
		}
	}
	@media #{$desktop1280}{
		.blog-item {
			h3 {
				font-size: 23px;
			}
		}
	}
	@media #{$tabletlandscape}{
		.blog-item {
			h3 {
				font-size: 21px;
			}
		}
	}
	@media #{$mobileportrait}{
		.blog-item {
			h3 {
				font-size: 18px;
				margin-bottom: 10px;
			}
		}
	}
	&.slick-slider{
		.blog-item{
			width: 100%;
		}
	}
	.carousellisting{
		width: 100%;
		.slick-track{
			margin-left: 0;
			margin-right: 0; 
		}
	}
	.sliderOptions{
		width: 100%;
	}
}
.sliderOptions {
	display: flex;
	justify-content: flex-end;
	align-items:  center;
	padding: $breathingSpace 0;
	margin-bottom: $breathingSpace;
	@media #{$tabletportrait}{
		justify-content: space-between;
	}
	@media #{$mobileportrait} {
		padding: $breathingSpace1 0;
		margin-bottom: 0px;
	}
	&.flexend {
		justify-content: flex-end;
	}
}
.sliderarrows {
	position: relative;
	font-size: 0;
	margin-left: $breathingSpace;
	a {
		display: inline-block;
		padding: 8px 15px;
		.ctaicon {
			width: 30px;
		}
		&:first-child {
			border-right: 1px solid rgba(0,0,0,.2)
		}
		&.disabled,
		&.slick-disabled {
			pointer-events: none;
			.ctaicon {
				opacity: 0.4
			}
		}
	}
}
.slick-dots {
	display: block;
	position: relative;
	text-align: center;
	padding: 20px 0;
	margin: 0;
	list-style: none;
	font-size: 0;
	clear: both;
	li {
		display: inline-block;
		margin: 0 3px;
		button {
			width: 13px;
			height: 13px;
			display: block;
			background-color: rgba(0,0,0,0);
			border: 1px solid #727272;
			border-radius: $circle;
			padding: 0;
			font-size: 0;
			overflow: hidden;
			cursor: pointer;
			transition: all 0.35s ease 0s;
			@media #{$mobileportrait}{
				width: 10px;
				height: 10px;
			}
		}
		&.slick-active,
		&:hover {
			button {
				background-color: #727272;
			}		
		}
	}
}
.customgrid {
	display: block;
	margin-right: -12px;
	margin-left: -12px;
	font-size: 0;
	.grid {
		width: 33.333333%;
		display: inline-block;
		vertical-align: top;
		padding-right: 12px;
		padding-left: 12px;
		&.grid-half {
			width: 50%;
		}
	}
}
.sec-subscribe {
	background-color: #fcfcfc;
	padding: 60px 0;
	color: #656667;
	h3 {
		margin-bottom: 5px;
	}
	@media #{$mobileportrait}{
		display: none;
	}
}

.subscribeform {
	display: flex;
	flex-direction: row;
	margin-top: 30px;
	input{
		margin-right: 30px;
		border-color: #e7eaeb;
		color: #abacac;
	}
	.cta-btn {
		flex-shrink: 0;
	}
	.form-control::-moz-placeholder {
		color:  #abacac;
		opacity: 1;
	}
	.form-control:-ms-input-placeholder {
		color:  #abacac;
	}
	.form-control::-webkit-input-placeholder {
		color:  #abacac;
	}
	@media #{$tabletportrait}{
		input {
			margin-right: 10px;
		}
	}
}

.flexcontrol {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    align-items: center;
}
.sticky-cover {
	position: relative;
	height: 94px;
	@media #{$desktop1600}{
		height: 86px;
	}
	@media #{$mobileportrait}{
		height: 74px;
	}
	@media #{$mobilesmall}{
		height: 54px;
	}
}
.sec-blognav {
	position: absolute;
	padding: 30px 0;
	background-color: #fcfcfc;
	z-index: 5;
	width: 100%;
	top: 0;
	&.stick {
		position: fixed;
		top: 94px;
		@media #{$desktop1600}{
			top: 86px;
		}
		@media #{$mobileportrait}{
			top: 74px;
		}
		@media #{$mobilesmall}{
			top: 54px;
		}
	}
	.nav {
		display: inline-block;
		a {
			text-transform: uppercase;
			font-size: 19px;
			opacity: 0.6;
			padding: 0 30px;
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
			&.active,
			&:hover {
				opacity: 1;
				position: relative;
				&:after {
					content: "";
					height: 2px;
					background-color: #292829;
					position: absolute;
					bottom: -5px;
					left: 30px;
					right: 30px;
				}
				&:first-child:after{
					left: 0;
				}
				&:last-child:after{
					right: 0;
				}
			}
		}
	}
	.blog-search {
		display: flex;

		.c-btn-search {
			cursor: pointer;
			box-shadow: 0;
			outline: none;
			border: 0;
			padding: 0;
			margin: 0;
			display: inline-block;
			background-color: transparent;
			.searchicon {
				display: block;
				width: 34px;
				height: 34px;
				@media #{$desktop1600}{
					width:  26px;
					height: 26px;
				}
				path{
					fill: $black !important;
					stroke: none !important;
					transition: all 0.45s ease 0s;
				}
			}
		}
		input {
			&::placeholder {
				opacity: 1;
				color: $black;
			}
			border-radius: 0;
			border: 0;
			box-shadow: none;
			outline: none;
			background-color: transparent;
			padding: 0;
			margin: 0;
			width: 250px;
			margin-right: 10px;
			font-size: 19px;
			font-family: $fontprimary;
			opacity: 1;
			visibility: visible;
		}
	}
}

.historyTimeline {
	display: block;
	position: relative;
	padding: $breathingSpace 0 0 0;
	overflow: hidden;
	.timlineButtons {
		position: relative;
		width: 100%;
		margin-bottom: ($breathingSpace+$breathingSpace);

		@media #{$tabletportrait}{
			margin-bottom: $breathingSpace;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 15px;
			width: 100%;
			height: 1px;
			background-color: #c9c9c9;
			z-index: 0;
		}
		.timelinebtn {
			display: block;
			position: relative;
			padding: 0;
			margin: 0;
			font-size: 0;
			z-index: 1;
			.itemSlide {
				padding: 80px 80px 0 80px;
				display: inline-block;
				font-size: 20px;
				position: relative;
				margin-bottom: 12px;
				cursor: pointer;
				    text-align: center;
				    @media #{$tabletlandscape}{
						font-size: 14px;
				    }
				> span {
					position: absolute;
					display: block;
					left: 50%;
					transform:translateX(-50%) scale(1);
					bottom: 20px;
					z-index: 3;
					color: #c9c9c9;
					transition: all 0.35s ease 0s
				}
				&:before {
					content: "";
					position: absolute;
					left: 50%;
					bottom: 0;
					width: 8px;
					height: 8px;
					background-color: #c9c9c9;
					transform: translateX(-50%);
					border-radius: $circle;
					z-index: 2;
				}
				&:after {
					content: "";
					position: absolute;
					left: 50%;
					bottom: -11px;
					width: 30px;
					height: 30px;
					background-color: $white;
					transform: translateX(-50%);
					border-radius: $circle;
					z-index: 1;
					opacity: 0;
				}
			}
			.slick-current {
				.itemSlide {
					> span {
						transform:translateX(-50%) scale(1.3);
						color: $black;
					}
					&:after {
						opacity: 1;
					}
					&:before {
						background-color: $black;
					}
				}	
			}
		}
	}
}
.js-timelineBlocks-1{
	.slick-dots{
		@media #{$tabletportrait}{
			padding: 10px 0;
		}
	}
}
.timelineBlocks {
	display: block;
	position: relative;
	.slideContent {
		padding: $breathingSpace+$breathingSpace $breathingSpace+$breathingSpace 0 $breathingSpace+$breathingSpace;
		width: 70%;
		@media #{$tabletportrait}{
			padding: $breathingSpace 0 0 0;
		}
	}
	.thumbsBox {
		display: block;
		text-align: center;
		position: relative;
		ul {
			width: 100%;
			position: relative;
			font-size: 0;
			li {
				width: 33.3333%;
				display: inline-block;
				z-index: 1;
				position: relative;
				vertical-align: top;
				text-align: center;
				transform: translate(125%, 0) translateZ(0);
				transform-origin: center;
				transition: all 0.35s ease 1s;
				opacity: 0;
				img {
					max-width: 100%;
					position: relative;
					z-index: 0;
					max-height: 300px;
					display: block;
					margin: 0 auto;
				}
				.banner-playvdo {
					position: absolute;
					z-index: 1;
					left: 50%;
					top: 50%;
				}
				&:nth-child(1) {
					img {
						margin-top: 5%
					}
				}
				&:nth-child(2) {
					transform: translate(150%, 0) translateZ(0);
					img {
						margin-top: 25%
					}
				}
			}
		}
	}
	.slick-current {
		ul {
			li {
				opacity: 1;
				transform: translate(0%, 0%) translateZ(0);
				transition: all 0.4s ease 0.8s;
				&:nth-child(2) {
					transform: translate(25%,0) translateZ(0);
					transition: all 0.9s ease 0.9s;
				}
				&:nth-child(1) {
					transition: all 0.6s ease 0.6s;
				}
			}
		}
	}
}



/*FIle Input*/
.custom-fileselect {
	input {
	    width: 0.1px;
	    height: 0.1px;
	    opacity: 0;
	    overflow: hidden;
	    position: absolute;
	    z-index: -1;
	    &.error {
	    	+ label {
	    		border-color: red;
	    	}
	    }
		+ label {
			position: relative;
		    display: block;
		    width: 100%;
			height: 54px;
			padding: 7px 20px;
			font-size: 18px;
			line-height: 54px;
		    cursor: pointer;
		    overflow: hidden;
		    border: 1px solid $black;
		    background-color: $white;
			span {
			    width: 100%;
			    display: block;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    overflow: hidden;
			    padding-right: 50px;
			    height: 100%;
			    line-height: 38px;
			}
			.iconUpload {
				position: absolute;
				width: 23px;
				right: 20px;
				top: 10px;
			}
		}
	}
}
.formBox {
	padding: $breathingSpace 0;
	.form-group {
		margin-bottom: 30px;
	}
	@media #{$mobileportrait}{
		padding: $breathingSpace1 0;
		overflow: hidden;
	}
	.validation-error {
		color: red;
		font-size: 12px !important;
		margin: 0;
		padding: 5px 0;
		label {
			font-size: 12px;
			display: block;
		}
	}
}

.textContent + .formBox {
	padding-top: $breathingSpace+$breathingSpace;
	@media #{$mobileportrait}{
		padding-top: $breathingSpace
	}
}

.block-img {
	margin: 0 0 40px 0;
}
.para-img {
	float: left;
	max-width: 40%;
	margin-right: 20px;
	display: inline-block;
	margin-top: 10px;
	position: relative;
	img {
		width: 100%;
	}
	&.right {
		float: right;
		margin-right: 0px;
		margin-left: 20px;
	}
}
hr {
	border: none;
    border-bottom: 1px solid #a8a8aa;
	margin: $breathingSpace 0;
	&.padded-hr {
		margin: $breathingSpace*3 0
	}
	@media #{$mobileportrait}{
		&.padded-hr {
			margin: $breathingSpace1 0
		}
	}
}
.sec-teammembers{
	.fp-tableCell{
		display: block;
		padding-top: ($breathingSpace+$breathingSpace+14px) !important;
	}
	.flexcontrol {
		height: 100%;
		overflow: hidden;
	}
	.teamsection {
		display: block;
		height: 100%;
	}
	.team-leftSide,
	.team-rightSide {
		height: 100%;
		width: 50%;
		background-color: $white
	}
	.teamscroll {
		max-width: 585px;
		margin: 0 0 0 auto;
		padding: $breathingSpace*2 $breathingSpace/2 $breathingSpace*2 0;
		overflow: hidden;
		height: 100%;
		@media #{$desktop1600}{
			margin: 0 auto 0 0;
		}
		@media #{$desktop1280}{
	        max-width: 496px;
	    }
	    @media #{$tabletlandscape}{
	        max-width: 375px;
	    }
	    @media #{$tabletportrait}{
	        max-width: 100%;
	    }
	}
	.memberitem {
		display: block;
		position: relative;
		padding: 15px 0;
		border-bottom: 1px solid rgba(95,95,95,0.5);
		opacity: 0.5;
		transition: all 0.35s ease 0s;
		will-change: opacity;
		&:last-child {
			border-bottom-color: rgba(0,0,0,0); 
		}
		&.active {
			opacity: 1;
		}
		.hd-style1 {
			margin: 0;
			small {
				font-family: $fontsecondary;
				text-transform: none;
			}
		}
	}
	.team-detail {
		position: relative;
		height: 100%;
	}
	.member-detail {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		z-index: 2;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		transition: all 0.35s ease 0s;
		&.active {
			opacity: 1;
			visibility: visible;
		}
		.bg {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.content {
			opacity: 0;
			padding: $breathingSpace*2;	
			background-color: rgba(41,40,41,0.80);
			height: 100%;
			position: relative;
			z-index: 2;
			color: $gray;
			font-family: $fontprimary;
			display: flex;
			flex-direction: column;
			transition: all 0.35s ease 0s;
			@media #{$tabletlandscape}{
				padding: $breathingSpace $breathingSpace*2 $breathingSpace $breathingSpace1 ;
			}
			.about-team {
				height: calc(100% - 46px);
			}
			p {
				font-family: $fontprimary;
			}
			.scrollable {
				padding-right: $breathingSpace;
				@media #{$tabletlandscape}{
					padding-right: 0
				}
			}
		}
		&.active {
			opacity: 1;
			visibility: visible;
		}
		&:hover,
		&.selected {
			.content {
				opacity: 1;
			}
		}
	}
	
	.mCustomScrollBox {
		.mCSB_scrollTools {
			opacity: 0;
			.mCSB_dragger .mCSB_dragger_bar {
				background-color: #cacaca;
				width: 8px;
				border-radius: 0;
			}
			.mCSB_draggerRail {
				display: none;
			}
		}
		&:hover {
			.mCSB_scrollTools {
				opacity: 1
			}
		}
	}
}
.mCSB_inside>.mCSB_container {
	margin-right: 15px;
}
.content-head {

	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	.teamicons {
		height: 23px;
		width: 23px;
	}
	.member-social {
		a {
			margin: 0 10px;
			display: inline-block;
		}
	}
	@media #{$tabletportrait}{
		margin-top: 20px;
	}
}
.is--not-on-top{
	.c-main-header {
		background: $white;
	}
	.header-logo.brand-logo{
		&.brand-white {
			background-image: url(../img/brand/logo-green.png);
		}
	}
	.c-btn-menu {
		.tosvg.svgWhite{
			path {
				fill: $primary !important;
			}
		}
	}
	.c-search-box {
		.c-btn-search {
			.searchicon path {
				fill: $primary !important;
			}
		}
	}
	.c-search-box.searching {
		background-color: #f0f0f0;
	}
	.c-search-box.searching {
		.c-btn-search {
			.searchicon path {
				fill: $black !important;
			}
		}
	}
	&.menuopen {
		.c-main-header {
			background: transparent;
		}
		.brand-logo{
			&.brand-white {
				background-image: url(../img/brand/logo-white.png);
			}
		}
		.c-search-box.searching {
			background-color: $white;
		}
		.c-search-box {
			.c-btn-search {
				.searchicon path {
					fill: $white !important;
				}
			}
		}
		.c-btn-menu {
			.tosvg.svgWhite{
				path {
					fill: $white !important;
				}
			}
		}
	}
}
.visible-mobile {
	display: none !important; 
}
.visible-tabletP {
	display: none !important;
}
.sec-services,
.sec-casestudy {
	.fp-tableCell {
		.servicesSlider,
		.casestudySlider{
			padding-top: 94px !important;
			@media #{$desktop1600}{
				padding-top: 86px !important;
			}
			@media #{$mobileportrait}{
				padding-top: 74px !important;
			}
			@media #{$mobilesmall}{
				padding-top: 54px !important;
			}
		}
	}
}
.showcase {
	.slick-dots {
		// padding-top: 0;
		clear: both;
	}
	.slick-slide {
		.item {
			pointer-events: none;
		}
		&.slick-current {
			.item {
				pointer-events: auto;
				&.no-hover{
					pointer-events: none;
				}
			}
		}
	}
}
input,
select,
textarea {
    -webkit-appearance: none;
}
select {
	&.form-control{
		background-image: url(../img/icons/ic-angle-down.svg);
		background-position: calc(100% - 10px) center;
		background-repeat: no-repeat;
		background-size: 18px;
		background-origin: right
	}
}
@media #{$tabletlandscape}{
	.menuopen {
		>.c-chat-icons,
		>.c-fullpage-menu,
		>.c-main-header {
			margin-right: 0;
		}
	}
}
@media #{$tabletportrait}{
	.hidden-tabletP {
		display: none !important;
	}
	.visible-tabletP {
		display: block !important;
		&.fp-section {
			display: table !important;
		}
	}
	.teamAccordian {
		&.accordion-box {
			margin: 20px 0 0 0;
			.accordion-item:first-child:after {
				display: none;
			}
		}
	}
	.showcase .grid.grid-half {
		width: 100%;
	}
	.fullpagesections {
		.fp-auto-height.fp-section {
			.fp-tableCell {
				padding: 25px 0;
			}
		}
	}
	.fullpagesections .fp-section.sec-footer .fp-tableCell {
		padding: 25px 25px 0;
	}
	.counter-box>.counter-item {
		padding: 10px 20px;
		p  {
			font-size: 16px;
		}
	}
}
@media #{$mobileportrait}{
	.showcase .grid .item .item-content .hd-style1 {
		text-align: center;
		padding-bottom: 10px;
	}
	.showcase .grid .item .item-content p {
		font-size: 14px;
		padding-top: 10px;
		line-height: 1.6;
		text-align: center;
	}
	.customsections .sec-footer {
		padding: 0
	}
	.sec-featured {
		background-color: transparent;
	}
	.sec-clients {
		.textContent.textCenter {
		    padding: 30px 0;
		    .hd-style1 {
		    	margin-bottom: 10px;
		    }
		}	
	}
	.sec-video-fp {
		padding: 25px;
		width: 100%;
		.c-background {
			padding-top: 80%;
			position: relative;
		}
	}
	.sec-home {
		.banner-content {
			max-width: none;
			width: 100%;
			padding: 0 25px 40px 25px;
		}
	}
	.header-logo {
		width: 85px;
		height: 34px;
	}
	.c-btn-menu {
	    width: 30px;
	    height: 23px;
	}
	.c-search-box {
		padding: 6px 8px;
		.c-btn-search {
			.searchicon {
			    width: 22px;
			    height: 22px;
			}
		}
		input{
			font-size: 14px;
		}
		&.searching {
			input{
				width: 140px;
			}
		}
	}
	.sec-home {
		.banner-content {
			.cta-btn {
				margin-top: 10px;
			}
		}

	}
	.is--not-on-top {
		.c-main-header {
		    background: #fff;
		}
		.header-logo.brand-logo {
		}
	}
	.sec-about,
	.sec-featured,
	.sec-casestudies,
	.sec-careers,
	.sec-contact {
		padding-top: $breathingSpace1;
		padding-bottom: $breathingSpace1;
		.c-background{	
			height: auto;
			width: auto;
			margin-left: 25px;
			margin-right: 25px;
			.bg-img {
				padding-top: 60%;
				position: relative;
				transform: none;
				top: auto;
				left: auto;
			}
		}
		.hd-style1 {
			padding: 0;
		}
		.cta-btn {
			margin-top: 10px;
		}
		.about-content {
			// padding-bottom: 25px;
		}
	}
	.servicesSlider {
		.contentarea {
			padding: $breathingSpace1 0;
			position: relative;
		    padding-bottom: 70px;
			.section-heading {
				text-align: left;
				color: $white;
				display: block;
				margin-bottom: 10px;
			}
			.flexarea {
				.rightside {
					padding: 20px;
					.slide-content {
						padding: 0;
						.hd-style1 {
							margin-bottom: 10px;
						}
						p {
							font-size: 14px;
						}
						.cta-btn {
						    font-size: 16px;
						    padding: 12px 12px;
						}
					}
				}
				&:before {
					width: 100%;
					left: 0;
					top: 0;
				}
			}
		}
	}
	.casestudySlider {
		.contentarea {
			padding: 210px 0 40px;
			.flexarea{
				margin-bottom: 0;
				.rightside {
					.slide-content{
						padding: 0;
					}
				}
			}
		}
	}
	.sec-footer {
		.footer-search {
			align-items: center;
			flex-direction: column;
			text-align: center;
		}
		.copyright {
			border: 0; 
			margin-top: 0;
			padding-top: 10px;
			.foo-nav-item {
				font-size: 12px;
				padding: 10px 0 0;
			}
			.foo-nav-item {
				&:last-child {
					display: none;
				}
			}
		}
		.footer-nav {
			display: none;
			text-align: center;
			font-size: 0;
			.foo-nav-item{
				padding: 0 18px;
				font-size: 16px;
				border-right: 1px solid;
				line-height: 10px;
				&:last-child {
					border-right: 0;
				}	
			}
		}
		.footer-search {
			padding-top: 20px;
			padding-bottom: 40px;
			.search-box {
				border-bottom: 1px solid hsla(0,0%,54%,.3);
				width: 100%;
				padding-bottom: 40px;
				margin-bottom: 30px;
				padding: 0 25px 40px;
				text-align: left;
				label{
					font-size: 16px;
					margin-bottom: 20px;
					text-align: left;
				    max-width: 290px;
				}
				input {
					width: 280px;
					width: calc(100% - 65px);
				}
			}
			.brand-logo {
				margin-bottom: 30px;
			}
		}
		.footer-social a {
			width: 46px;
			height: 46px;
			margin: 0 7px;
			padding: 13px;
		}
	}
	.slidenumber {
		font-size: 36px;
		bottom: 0;
		small{
		    font-size: 20px;
		}
	}
	.servicesSlider {
		.slidenumber {
		    bottom: 10px;
		    color: #aaa;
		    right: 10px;
		}
	}
	.slidernavigation {
		padding-top: 25px;
		a {
			padding-top: 0;
			padding-bottom: 0;
			&:first-child {
				padding-left: 0;
			}
		}
	}
	.visible-mobile {
		display: block !important;
	}
	.c-menuScreen {
		.menuscreen-cover {
			padding-top: 90px;
		}
		.c-navigation {
			>ul {
				>li {
					padding: 30px 35px;
					.c-sub-nevigation {
						>li {
							>a {
								font-size: 16px;
								margin: 0 25px 0 0;
								padding-top: 15px;
							}
						}
					}
					&.js-hasChild {
						>a {
							&:after{
								width: 18px;
								height: 18px;
								margin-left: 20px;
							}
						}
					}
				}
			}
		}
	}
	.sec-aboutus,
	.sec-team {
		padding-top: 25px;
		padding-bottom: 25px
	}
	.textContent {
		padding: 20px 0;
		p {
			margin-bottom: 20px;
		}
	}
	hr {
		margin: 20px 25px;
	}
	.container {
		>hr {
			margin: 20px 0;
			+ .hd-style1 {
				padding-top: 20px;
			}
		}
	}
	.para-styleBold {
		margin-bottom: 15px;
	}
	.fp-section {
		> .container {
			> hr {
				margin-bottom: 0;
				margin-top: 25px;
			}
		}
	}
	.menuopen.is--not-on-top {
		.c-main-header {
			background: transparent;
		}
		.c-menuScreen .c-btn-menu {
		}
	}
	.showcase {
		.grid {
			.item {
				text-align: left;
			}
		}
	}
	.sec-history {
		padding-top: 25px;
	}
	.historyTimeline {
		padding: 40px 0 25px;
	}
	.textContent.textCenter {
		padding: 40px 0;
		text-align: left;
	}
	.counter-box {
		flex-direction: column;
		>.counter-item {
			margin: 0;
			background-color: transparent;
			border: 0;
			border-bottom: 1px solid rgba(0,0,0,0.5);
			color: $black;
			padding: 40px 0;
			text-align: left;
			display: flex;
			align-items: center;
			span {
				font-size: 50px;
			}
			> span {
				min-width: 140px;
			}
			p {
				width: 100%;
				margin: 0;
				font-size: 16px;
			}
		}
	}
	.timelineBlocks {
		.slideContent {
			width: 100%;
			padding: 0;
		}
		.thumbsBox {
			ul {
				text-align: left;
				margin-top: 30px;
				li {
					width: 75%;
					&:first-child{
						display: none;
					}
					&:nth-child(2) {
					}
					&:nth-child(3) {
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}
		}
		.slick-current {
			ul {
				li {
					&:nth-child(2) {
						transform: translate(0%) translateZ(0);
						img {}
					}
				}
			}
		}
	}
	.container-fluid .container {
		padding-left: 0;
		padding-right: 0;
	}
	.c-menuScreen .c-btn-menu {
		padding-top: 28px; 
	}
	.innerpage {
		.footer-nav {
			border: none;
			padding-top: 0;
		}
	}
	.historyTimeline {
		.slidenumber {
			display: none;
			margin-top: 10px;
			.slick-dots{

			}
		}
	}
	.showcase{
		&.slick-initialized {
			padding: 0 25px;
			overflow: hidden;
			.slick-list {
				overflow: visible;
			}
			.grid {
				padding: 25px 3px;
				.item {
					.item-content {
						display: none;
					}
				}
			}
			.slick-slide {
				opacity: 0.4;
				transition: opacity 0.35s ease 0s;
				will-change: opacity;
				&.slick-current {
					opacity: 1;
				}
			}
			&.title-visible {
				.grid {
					.item {
						.item-content {
							display: block;
						}
					}
				}	
			}
		}
	}
	.sec-blognav {
		.blog-search {
			display: none;
		}
		.nav a {
			padding: 0 10px;
			&.active,
			&:hover {
				&:after {
					left: 10px;
					right: 10px;
				}
			}
		}
		.flexcontrol {
			justify-content: center
		}
	}
	.blog-detail {
		.blog-social {
			a {
				width: 40px;
				height: 40px;
				padding: 10px;
			}
		}
		.blog-info {
			.author-thumb {
				width: 50px;
				height: 50px;
			}
			.author {
				p {
					margin-bottom: 0;
				}
			}
		}
	}
	.para-img {
		margin-top: 4px;
		max-width: 100%;
		&.right {
			margin:0;
		}
	}
	.accordion-box{
		.acc-heads {
			display: none;
		}
		.accordion-item {
			.acc-title {
				flex-wrap: wrap;
				padding: 10px 0;
				> h2 {
					width: auto;
					flex: 1 1 50%;
					margin: 0;
					order : 3;
					font-size: 18px;
					padding: 10px 0;
					&:first-child {
						width: 100%;
						font-size: 22px;
						order : 1
					}
				}
				&:after {
					order : 2
				}
			}
			.acc-content {
				.acc-cta {
					justify-content: space-between;
					.cta-btn {
						margin-left: 0
					}
				}
			}
		}
	}
	.customgrid .grid.grid-half {
		width: 100%;
	}
	.footer-social.contact-social a {
	    width: 45px;
	    height: 45px;
	    margin: 0px 3px;
	    padding: 13px;
	    line-height: 24px;
	    svg {
	    	width: 17px;
	    	height: 17px; 
	    }
	}
	.footer-social a .scicons {
    	width:  18px;
    	height: 18px; 		
	}
	.slidingBox {
		flex-direction: column;
		h2 {
			padding: 0 15px;
			margin: 10px 0;
			font-size: 26px;
		}
		.sB-item {
			padding: 0px;
			width: 100% !important;
			margin: 10px 0;
			.sB-title {
				display: none
			}
			.sB-itemInner {
				width: 100%;
				visibility: visible;
				opacity: 1;
				.sB-content {
					padding: 0 15px;
					flex-direction: column;
					.addressTXT,
					.numbersTXT {
						margin-bottom: 10px;
						p {
							font-size: 16px;
						}
						span {
							font-size: 14px;
						}
					}
					.empTXT {
						width: 100%;
						padding: 10px 0;
						display: none;
						svg {
							margin-left: 0;
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
	.showcase .grid .item .item-content {
		padding-left: 10px;
		padding-right: 10px;
	}
	.form-control {
		padding: 7px 10px;
	}
	.showcase.slick-initialized.title-visible .slick-current .grid .item {
		&:before {
		    background-color: rgba(0,0,0,.85);
		    transform: translateY(0);
		}
		.item-content {
			top: 0;
			.hd-style1 {
				transform: translateY(0);
				margin-top: 0;
			}
			p {
				max-height: 999px;
				transition: all 1s ease .25s;
				display: block;
				opacity: 1;
			}
		}
	}
	.showcase .grid .item .item-content {
		padding-top: 20px;
		padding-bottom: 20px;
		.hd-style1 {
			font-size: 18px;
		}
		p {
			font-size: 13px;
		}
	}
}
@media #{$mobilesmall}{
	.hd-style1 {
		font-size: 20px;
	}
	.sec-video-fp {
		padding: 10px;
	}
	.showcase{
		&.slick-initialized {
			padding: 0 10px;
			.grid {
				padding: 25px 2px;
			}
		}
	}
	.c-main-header {
	    padding: 10px;
	}
	.header-logo {
		width: 75px;
	    left: 10px;
	    top: 10px;
	}
	.sec-about,
	.sec-featured {
		.c-background {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
	.sec-home {
		.banner-content {
			padding: 0 10px 20px;
			p {
				font-size: 16px;
			}
			h2 {
				font-size: 34px;
			}
		}
	}
	.cta-btn {
	    font-size: 16px;
	    padding: 10px 12px;
	}
	.c-search-box {
		&.searching {
			input {
		    	width: 130px;
		    }
		}
	}
	
	.c-chat-icons {
		right: 10px;
		padding: 10px 0;
	}
	.c-menuScreen {
		.c-btn-menu {
	    	padding: 17px 10px;
		}
	}
	.sec-footer {
		.footer-nav {
			.foo-nav-item{
				padding: 0 14px;
			}
		}
	}
	hr {
		margin: 15px 10px;
	}
	.container {
		>hr {
			margin: 15px 0;
			+ .hd-style1 {
				padding-top: 15px;
			}
		}
	}
	.sec-footer {
		.footer-search {
			.search-box {
				padding-left:10px;
				padding-right:10px;
			}
		}
	}
}
@media (min-width: 1024px){
	@media (max-height: 850px) {
		.casestudySlider .contentarea .flexarea .rightside {
			padding: 20px 0 80px;
		}
		.sec-casestudy .slidernavigation {
		    padding: 0 0 90px;
		}
		.slidenumber {
			bottom: -60px;
		}
		.servicesSlider .contentarea .flexarea .rightside {
			padding: 20px 40px 60px;
		}
		.servicesSlider .contentarea .flexarea .rightside .slide-content .cta-btn {
		    margin-top: 15px;
		}
		.casestudySlider .contentarea .flexarea .rightside{
			.slidenumber {
				bottom: -20px;
			}
		}
	}
	@media (max-height: 700px) {
		.casestudySlider .contentarea .flexarea .rightside .slide-content {
			padding: 1px;
			h1 {
				margin-bottom: 15px;
			}
			p {
				line-height: 1.4
			}
			.cta-btn {
				margin-top: 10px;
			}
		}
		.casestudySlider .contentarea .flexarea .rightside{
			.slidenumber {
				bottom: 30px;
			}
		}
	}
}