//author stylng
.hd-style2 {
    font-size: 52px;
    line-height: 1.5;
    font-style: italic;
    text-transform: uppercase;
    font-weight: bold;
    @media #{$desktop1600} {
        font-size: 46px;
    }
    @media #{$desktop1280}{
        font-size: 40px;
    }
    @media #{$tabletlandscape}{
        font-size: 36px
    }
    @media #{$mobileportrait} {
        font-size: 30px;
    }
}
.para-styleBold{
    font-weight:700;
    font-style: italic;
    margin-bottom: 30px;
}

.standalone-btn{
    text-align: center;
    padding: 130px 0;
    .cta-btn{
        margin: 0 auto;
    }
    @media #{$mobileportrait}{
        padding: 25px 0 35px;
       .cta-btn{
            display: flex;
            width: 100%;
            text-align: left;
            align-items: center;
            justify-content: space-between;
       } 
    }
}
// .sec-showcase {
//     overflow: hidden;
// }

.sec-customerExp {
    &.p-0 {
        >.fp-tableCell {
            padding: 0!important;
        }
    }
}

.sec-featured{
	@extend .sec-about;
	background-color: #fdfdfd;
    @media #{$tabletportrait}{
        background-color: none;
    }
}

.textContent {
    width: 70%;
    padding: $breathingSpace*3 0;
    clear: both;
    p {
        margin-bottom: 30px;
    &:last-child{
    	margin-bottom: 0px;
    }
    }
    &.p-0 {
        padding: 0 !important;
    }
    &.textCenter {
        margin: 0 auto;
        text-align: center;
        padding: 120px 0 60px;
    }
    &.fullwidth{
        width: 100%;
    }
    &.awards-content {
        padding-bottom: 60px; 
    }
}
.js-bg-video-hover {
    &:hover {
            .bg-video{
                .preview-img{
                    display: none;
                }
            }
    }
}
.showcase {
    font-size: 0;
    .bg-video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .preview-img{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000 no-repeat center center;
            background-size: cover;
            z-index: 1;
            @media #{$tabletlandscape}{
                display: none;
            }
        }
        .cover-video{
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .cover-wrap{
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                background: #000;

                video{
                    min-width: 100%;
                    min-height: 100%;
                    width: auto;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    opacity: 0.5;
                    transition: opacity .35s;
                }
            }
        }
    }
    .item{
        &:hover{
            .bg-video{
                .cover-video{
                    .cover-wrap{
                        video{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .grid {
        width: 33.333333%;
        padding: 2px; 
        position: relative;
        display: inline-block;
        overflow: hidden;
        @media #{$tabletportrait}{
            width: 100%;
            padding: 15px 25px;
        }
        @media #{$mobilesmall}{
            width: 100%;
            padding: 10px 10px;
        }
        .item {
            width: 100%;
            position: relative;
            display: block;
            text-align: center;
            padding-top: 82%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            transition: 0.5s all ease;
            overflow: hidden;
            color: $white;
            @media #{$tabletportrait}{
                padding-top: 50%;
            }
            @media #{$mobileportrait}{
                padding-top: 80%;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                height: 100%;
                width: 100%;
                background-color: rgba(0,0,0,0);
                transition: all 0.35s ease 0s;
                transform: translateY(100%);
            }
            .item-content {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                transition: all 0.35s ease 0s;
                padding: $breathingSpace $breathingSpace+$breathingSpace;
                height: 100%;
                z-index: 2;
                .hd-style1 {
                    line-height: 1.5;
                    font-style: italic;
                    text-transform: uppercase;
                    font-weight: 700;
                    transform: translateY(-100%);
                    margin: 0;
                    padding-bottom: $breathingSpace;
                    margin-top: -$breathingSpace;
                    transition: all 0.35s ease 0s
                }
                p {
                    position: relative;
                    color: #fff;
                    font-size: 18px;
                    max-height: 0px;
                    padding-top: $breathingSpace;
                    transition: all 0.35s ease 0s;
                    display: block;
                    overflow: hidden;
                    margin: 0;
                    opacity: 0;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 30%;
                        display: block;
                        height: 2px;
                        width: 40%;
                        background-color: $white;
                    }
                }
                @media #{$desktop1440}{
                    padding: $breathingSpace1;
                    .hd-style1 {
                        padding-bottom: $breathingSpace1;
                        margin-top: -$breathingSpace1;
                    }
                    p {
                        padding-top: $breathingSpace1;
                    }
                }
            }
            &:hover {
                .item-content {
                    top: 0;
                    .hd-style1 {
                        transform: translateY(0);
                        margin-top: 0;
                    }
                    p {
                        max-height: 999px;
                        transition: all 0.35s ease 0.25s;
                        display: block;
                        opacity: 1;
                    }
                }
                &:before {
                    background-color: rgba(0,0,0,0.85);
                    transform: translateY(0);
                }

            }
            &.no-hover:hover {
                .item-content {
                    top: 100%;
                    .hd-style1 {
                        transform: translateY(-100%);
                        margin-top: 0-$breathingSpace;
                    }
                    p {
                        max-height: 0;
                    }
                }
                &:before {
                    background-color: rgba(0,0,0,0);
                }
            }
            &.hovered-item {
                 &:before {
                    background-color: rgba(0,0,0,0.3);
                }
                .item-content {
                    top: auto;
                    bottom: 0;
                    height: auto;
                    .hd-style1 {
                        transform: translateY(0);
                        margin: 0;
                    }
                    p {
                        max-height: none;
                        padding-top: 0;
                        &:before {
                            display: none;
                        }
                    }
                }
            }            
        }
        &.grid-half {
            width: 50%; 
            @media (min-width: 1400px) {
                .hovered-item {
                    .item-content {
                        padding-bottom: 80px;
                    }
                }
            }
        }
    }
    &.video {
        .item {
            .item-content{
                top: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                .hd-style1 {
                    transform: translateY(0);
                    margin-top: 0;
                    color: $white;
                }
                a {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%)
                }
            }
            &:hover {
                .item-content{
                    .iconsPlay {
                        opacity: 1;
                    }
                }
            }
        }
        @media #{$mobileportrait}{
            .grid .item .item-content {
                .hd-style1 {
                    padding-bottom: 0;
                    text-align: center;
                }
            }
        }

    }
    .slick-dots {
        padding-top: 40px;
    }
}
.sec-video {
	overflow-x:hidden;
}

.sec-ourProjects{
    .textContent{
        width: 100%;
        padding: 0px 20px 60px;
        text-align: center;
    }
}
.counter-box{
    display:flex;
    margin: 0 -2px;
    > .counter-item{
        flex:1;
        background-color: transparent;
        border-right: 1px solid rgba(0,0,0,0.5);
        color: $black;
        padding: 20px 40px;
        text-align: left;
        text-align: center;
        &:last-child {
            border-right: 0;
        }
        span{
            font-size:58px;
            font-weight: 700;
            font-style: italic;

            &:after{
                //content:"+";
            }
        }
        p {
            font-size: 18px;
            margin: 20px 0 0 0;

        }      
    }
}
// .counter-box {
//         flex-direction: column;
//         >.counter-item {
//             margin: 0;
//             background-color: transparent;
//             border-bottom: 1px solid rgba(0,0,0,0.5);
//             color: $black;
//             padding: 40px 0;
//             text-align: left;
//             display: flex;
//             align-items: center;
//             span {
//                 font-size: 50px;
//             }
//             > span {
//                 min-width: 140px;
//             }
//             p {
//                 width: 100%;
//                 margin: 0;
//                 font-size: 16px;
//             }
//         }
//     }

.accordion-box{
    margin: 80px 0 40px;

    .acc-heads{
        display: flex;
        position: relative;
        margin-bottom:35px;
        padding-right: 40px;
        > h3 {
            flex: 1;
            font-size: 22px;
            color: #5f5e5f;
            padding: 10px 25px 0 0;
            margin-bottom: 0px;
        }
    }
    .accordion-item{
        position: relative;
        .acc-title{
            display:flex;
            cursor: pointer;
            color: #727272;
            clear:both;
            transition: all .35s ease 0s;
            > h2 {
                flex: 1;
                font-size: 26px;
                font-weight: 700;
                padding: $breathingSpace 0;
                margin: 0 $breathingSpace 0 0;
                text-transform: uppercase;
                font-style: italic;
                @media #{$desktop1440}{
                    font-size: 24px;
                    padding: $breathingSpace1 0;
                }
                @media #{$desktop1280}{
                    font-size: 22px
                }
                @media #{$tabletportrait}{
                    margin-right: 10px;
                }
            }
            .hd-style1 {
                padding: $breathingSpace 0;
                margin: 0 $breathingSpace 0 0;
                @media #{$desktop1440}{
                    padding: $breathingSpace1 0;
                }
            }
            &.active{
                color: #292829;
                &:after{
                    transform: rotate(90deg);
                    opacity: 1;
                }
            }
            &:after{
                content: "";
                width: 40px;
                height: 40px;
                margin: auto 0 auto auto;
                display: inline-block;
                background-image: url(../img/icons/ic-angle-right.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50%;
                transform-origin: center;
                transform: rotate(0deg);
                opacity: .5;
                transition: all .35s ease 0s;
                @media #{$desktop1440}{
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .acc-content{
            display: none;
            position: relative;
            margin-bottom: 70px;
            p{
                font-size:18px;
                color:#292829;
                margin-bottom: 50px;
                @media #{$desktop1440}{
                    margin-bottom: 30px;
                }
            }
            .acc-cta{
                display:flex;
                flex-direction: row-reverse;
                .cta-btn{
                    margin-left:40px;
                }
            }
            @media #{$tabletlandscape}{
                margin-bottom: 30px;
            }
        }
        &:after{
            content:'';
            height: 1px;
            width: 100%;
            background-color: #a8a8aa;
            position: absolute;
            left:0;
            top:0px;
        }
    }
}

.sec-contact {
    .footer-social{
        margin: 50px 0;
    }
}
.listingcontent {
    display: block;
    list-style: circle;
    padding: 0 0 0 20px;
    li {
        display: block;
        padding: 10px 0;
        &:before {
            content: '-  ';
            display: inline-block;
            margin-right: 10px;

        }
    }
}
.slidingBox {
    display:flex;
    overflow: hidden;
    margin: $breathingSpace 0 $breathingSpace*2 0;
    h2 {
        font-size: 30px;
        font-weight: 700;
        font-family:$fontprimary;
        font-style: italic;
        text-transform: uppercase;
        padding: 40px;
        margin:0;
    }
    .sB-item {
        position: relative;
        background-color: #fbfbfb;
        border: 2px solid #fff;
        display:block;
        overflow: hidden;
        width: 15%;
        cursor: pointer;
        flex-shrink: 1;
        transition: all 0.6s ease 0s;
        @media #{$tabletlandscape}{
            width: 10%;
        }
        .sB-title{
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            width: 1px;
            opacity: 1;
            visibility: visible;
            transition: all 0.3s ease 0.3s;
            h2 {
                padding: 0;
                transform: rotate(-90deg);
            }
        }
        .sB-itemInner {
            flex-shrink: 0;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease 0s;
            min-width: 815px;
            @media #{$desktop1440}{
                min-width: 780px;
            }
            @media #{$desktop1280}{
                min-width: 0;
                width: 840px;
            }
            @media #{$tabletlandscape}{
                width: 670px;
            }
            @media #{$tabletportrait}{
                width: 818px;
            }
        }
        .sB-content {
            padding:0px $breathingSpace $breathingSpace*2;
            color: #292829;
            display: flex;
            flex-shrink: 0;
            justify-content: space-between;
            .secleft {
            }
            .empTXT {
                padding-right: 80px;
                a {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    margin-bottom: 15px;
                }
                svg {
                    width: 25px;
                    height: 25px;
                    float: left;
                    margin: 8px 20px 8px 8px;
                }
                
                span {
                    font-weight:100;
                    font-family:$fontprimary;
                }
            }
            p {
                font-size: 18px;
                margin-bottom: 5px;
                font-family:$fontprimary;
            }
            a {
                display:block;
             }
            .addressTXT {
                margin-bottom:40px;
            }
            .numbersTXT {
                svg {
                    width: 25px;
                    height: 25px;
                    margin-right: 20px;
                    display: inline-block;
                    vertical-align: middle;
                }
                span{
                    display: inline-block;
                    font-size: 18px;
                    font-family:$fontprimary;
                }
                
            }

        }
        .sB-map {
            display: block;
            clear:both;
            width: 100%;
            height: 280px;
        }
        &.active {
            width: 85%;
            cursor: default;
            @media #{$tabletlandscape}{
                width: 90%;
            }
            .sB-itemInner {
                opacity: 1;
                visibility: visible;
            }
            .sB-title{
                bottom: -100%;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s ease 0s;
            }
        }
    }
}