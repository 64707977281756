@font-face {
    font-family: 'Futura';
    src: url('../fonts/FuturaLT-Book.eot');
    src: url('../fonts/FuturaLT-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaLT-Book.woff2') format('woff2'),
        url('../fonts/FuturaLT-Book.woff') format('woff'),
        url('../fonts/FuturaLT-Book.ttf') format('truetype'),
        url('../fonts/FuturaLT-Book.svg#FuturaLT-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/FuturaLT-Bold.eot');
    src: url('../fonts/FuturaLT-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaLT-Bold.woff2') format('woff2'),
        url('../fonts/FuturaLT-Bold.woff') format('woff'),
        url('../fonts/FuturaLT-Bold.ttf') format('truetype'),
        url('../fonts/FuturaLT-Bold.svg#FuturaLT-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/FuturaLT-BookOblique.eot');
    src: url('../fonts/FuturaLT-BookOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaLT-BookOblique.woff2') format('woff2'),
        url('../fonts/FuturaLT-BookOblique.woff') format('woff'),
        url('../fonts/FuturaLT-BookOblique.ttf') format('truetype'),
        url('../fonts/FuturaLT-BookOblique.svg#FuturaLT-BookOblique') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Futura';
    src: url('../fonts/FuturaLT-BoldOblique.eot');
    src: url('../fonts/FuturaLT-BoldOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaLT-BoldOblique.woff2') format('woff2'),
        url('../fonts/FuturaLT-BoldOblique.woff') format('woff'),
        url('../fonts/FuturaLT-BoldOblique.ttf') format('truetype'),
        url('../fonts/FuturaLT-BoldOblique.svg#FuturaLT-BoldOblique') format('svg');
    font-weight: bold;
    font-style: italic;
}
//Georgia
@font-face {
    font-family: 'Georgia';
    src: url('../fonts/Georgia.eot');
    src: url('../fonts/Georgia.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Georgia.woff2') format('woff2'),
        url('../fonts/Georgia.woff') format('woff'),
        url('../fonts/Georgia.ttf') format('truetype'),
        url('../fonts/Georgia.svg#Georgia') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Georgia';
    src: url('../fonts/Georgia-Bold.eot');
    src: url('../fonts/Georgia-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Georgia-Bold.woff2') format('woff2'),
        url('../fonts/Georgia-Bold.woff') format('woff'),
        url('../fonts/Georgia-Bold.ttf') format('truetype'),
        url('../fonts/Georgia-Bold.svg#Georgia-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Georgia';
    src: url('../fonts/Georgia-BoldItalic.eot');
    src: url('../fonts/Georgia-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Georgia-BoldItalic.woff2') format('woff2'),
        url('../fonts/Georgia-BoldItalic.woff') format('woff'),
        url('../fonts/Georgia-BoldItalic.ttf') format('truetype'),
        url('../fonts/Georgia-BoldItalic.svg#Georgia-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Georgia';
    src: url('../fonts/Georgia-Italic.eot');
    src: url('../fonts/Georgia-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Georgia-Italic.woff2') format('woff2'),
        url('../fonts/Georgia-Italic.woff') format('woff'),
        url('../fonts/Georgia-Italic.ttf') format('truetype'),
        url('../fonts/Georgia-Italic.svg#Georgia-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

