// Custom Variables
// Set Colors

$white:				#FFFFFF !default;
$black: 			#292829 !default;
$gray: 		   		#cacaca !default;

$primary:			#8dc63f !default; //green

//circle
$circle: 50%;
$fontprimary: 'Futura';
$fontsecondary: 'Georgia';


$breathingSpace: 40px;
$breathingSpace1: 25px;

//Media Sizes
$desktop1600: 		"(max-width: 1919px)";
$desktop1440: 		"(max-width: 1599px)";
$desktop1280: 		"(max-width: 1439px)";
$tabletlandscape: 	"(max-width: 1199px)";
$tabletportrait: 	"(max-width: 991px)";
$mobileportrait: 	"(max-width: 767px)";
$mobilemidium: 		"(max-width: 374px)";
$mobilesmall: 		"(max-width: 359px)";


/*Media Queries

@media #{$desktop1600}{}
@media #{$desktop1440}{}
@media #{$desktop1280}{}
@media #{$tabletlandscape}{}
@media #{$tabletportrait}{}
@media #{$mobileportrait}{}
@media #{$mobilemidium}{}
@media #{$mobilesmall}{}
*/

