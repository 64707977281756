@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaLT-Book.eot");
  src: url("../fonts/FuturaLT-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaLT-Book.woff2") format("woff2"), url("../fonts/FuturaLT-Book.woff") format("woff"), url("../fonts/FuturaLT-Book.ttf") format("truetype"), url("../fonts/FuturaLT-Book.svg#FuturaLT-Book") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaLT-Bold.eot");
  src: url("../fonts/FuturaLT-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaLT-Bold.woff2") format("woff2"), url("../fonts/FuturaLT-Bold.woff") format("woff"), url("../fonts/FuturaLT-Bold.ttf") format("truetype"), url("../fonts/FuturaLT-Bold.svg#FuturaLT-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaLT-BookOblique.eot");
  src: url("../fonts/FuturaLT-BookOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaLT-BookOblique.woff2") format("woff2"), url("../fonts/FuturaLT-BookOblique.woff") format("woff"), url("../fonts/FuturaLT-BookOblique.ttf") format("truetype"), url("../fonts/FuturaLT-BookOblique.svg#FuturaLT-BookOblique") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaLT-BoldOblique.eot");
  src: url("../fonts/FuturaLT-BoldOblique.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaLT-BoldOblique.woff2") format("woff2"), url("../fonts/FuturaLT-BoldOblique.woff") format("woff"), url("../fonts/FuturaLT-BoldOblique.ttf") format("truetype"), url("../fonts/FuturaLT-BoldOblique.svg#FuturaLT-BoldOblique") format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Georgia';
  src: url("../fonts/Georgia.eot");
  src: url("../fonts/Georgia.eot?#iefix") format("embedded-opentype"), url("../fonts/Georgia.woff2") format("woff2"), url("../fonts/Georgia.woff") format("woff"), url("../fonts/Georgia.ttf") format("truetype"), url("../fonts/Georgia.svg#Georgia") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Georgia';
  src: url("../fonts/Georgia-Bold.eot");
  src: url("../fonts/Georgia-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Georgia-Bold.woff2") format("woff2"), url("../fonts/Georgia-Bold.woff") format("woff"), url("../fonts/Georgia-Bold.ttf") format("truetype"), url("../fonts/Georgia-Bold.svg#Georgia-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Georgia';
  src: url("../fonts/Georgia-BoldItalic.eot");
  src: url("../fonts/Georgia-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Georgia-BoldItalic.woff2") format("woff2"), url("../fonts/Georgia-BoldItalic.woff") format("woff"), url("../fonts/Georgia-BoldItalic.ttf") format("truetype"), url("../fonts/Georgia-BoldItalic.svg#Georgia-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Georgia';
  src: url("../fonts/Georgia-Italic.eot");
  src: url("../fonts/Georgia-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Georgia-Italic.woff2") format("woff2"), url("../fonts/Georgia-Italic.woff") format("woff"), url("../fonts/Georgia-Italic.ttf") format("truetype"), url("../fonts/Georgia-Italic.svg#Georgia-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

/*Media Queries

@media (max-width: 1919px){}
@media (max-width: 1599px){}
@media (max-width: 1439px){}
@media (max-width: 1199px){}
@media (max-width: 991px){}
@media (max-width: 767px){}
@media (max-width: 374px){}
@media (max-width: 359px){}
*/
* {
  outline: none !important;
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html, body {
  font-family: "Futura";
  font-weight: normal;
  color: #292829;
  background: #FFFFFF; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Futura";
  margin-bottom: 20px;
  font-weight: normal; }

p {
  margin: 0;
  font-size: 18px;
  line-height: 1.6;
  font-family: "Georgia";
  margin-bottom: 10px; }
  @media (max-width: 1919px) {
    p {
      font-size: 18px; } }
  @media (max-width: 1599px) {
    p {
      font-size: 16px; } }

strong {
  font-weight: bold; }

i {
  font-style: italic; }

a {
  text-decoration: none;
  color: #292829; }

img {
  max-width: 100%; }

.o-hidden {
  overflow: hidden; }

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
  min-height: 2px; }
  @media (max-width: 1439px) {
    .container {
      max-width: 992px; } }
  @media (max-width: 1199px) {
    .container {
      max-width: 750px; } }
  @media (max-width: 991px) {
    .container {
      max-width: 100%;
      padding-right: 25px;
      padding-left: 25px; } }
  @media (max-width: 359px) {
    .container {
      padding-right: 10px;
      padding-left: 10px; } }
  .container-fluid {
    max-width: 1380px; }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555; }

button {
  -webkit-appearance: none; }

.form-control {
  display: block;
  width: 100%;
  height: 54px;
  padding: 7px 20px;
  font-size: 18px;
  line-height: 40px;
  color: #292829;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #292829;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  font-family: "Futura";
  -webkit-appearance: none; }
  .form-control.error {
    border-color: red; }

.form-control:focus {
  outline: 0; }

.form-control::-moz-placeholder {
  color: #292829;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #292829; }

.form-control::-webkit-input-placeholder {
  color: #292829; }

.form-control::-ms-expand {
  border: 0;
  background-color: transparent; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #cacaca;
  opacity: 1; }

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed; }

textarea.form-control {
  height: auto;
  resize: none; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; }
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm,
  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"] {
    line-height: 30px; }
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"] {
    line-height: 46px; } }

.form-group {
  margin-bottom: 25px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px; }

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

textarea.input-sm,
select[multiple].input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

select.input-lg {
  height: 46px;
  line-height: 46px; }

textarea.input-lg,
select[multiple].input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.has-feedback {
  position: relative; }

.has-feedback .form-control {
  padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.clearfix:before,
.clearfix:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: " ";
  display: table; }

.clearfix:after,
.form-horizontal .form-group:after {
  clear: both; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

/* HEIGHT MEDIA */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* -ms-touch-action: pan-y;
    touch-action: pan-y; */
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  overflow: hidden;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transform: translateY(-40px);
  transition: transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr--hide-controls .plyr__captions {
  transform: translateY(-15px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #8dc63f;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(141, 198, 63, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  height: 20px;
  left: 2px;
  position: relative;
  width: 20px; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #8dc63f; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls > .plyr__control {
  margin-left: 5px; }

.plyr__controls .plyr__menu:first-child, .plyr__controls .plyr__menu:first-child + [data-plyr=pause], .plyr__controls .plyr__progress:first-child, .plyr__controls .plyr__progress:first-child + [data-plyr=pause], .plyr__controls .plyr__time:first-child, .plyr__controls .plyr__time:first-child + [data-plyr=pause], .plyr__controls > .plyr__control:first-child, .plyr__controls > .plyr__control:first-child + [data-plyr=pause] {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__volume {
  margin-left: 5px; }

@media (min-width: 480px) {
  .plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls > .plyr__control {
    margin-left: 10px; }
  .plyr__controls .plyr__menu + .plyr__control, .plyr__controls > .plyr__control + .plyr__control, .plyr__controls > .plyr__control + .plyr__menu {
    margin-left: 5px; } }

.plyr--video .plyr__controls {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 35px 10px 10px;
  position: absolute;
  right: 0;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  z-index: 2; }

.plyr--video .plyr__controls .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus, .plyr--video .plyr__controls .plyr__control:hover, .plyr--video .plyr__controls .plyr__control[aria-expanded=true] {
  background: #8dc63f;
  color: #fff; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__video-embed {
  height: 0;
  padding-bottom: 56.25%;
  position: relative; }

.plyr__video-embed iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%); }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  transition: transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container ul {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 7px; }

.plyr__menu__container ul li {
  margin-top: 2px; }

.plyr__menu__container ul li:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container label.plyr__control {
  padding-left: 7px; }

.plyr__menu__container label.plyr__control input[type=radio] + span {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  position: relative;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container label.plyr__control input[type=radio] + span::after {
  background: #fff;
  border-radius: 100%;
  content: '';
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: scale(0);
  transition: transform .3s ease,opacity .3s ease;
  width: 6px; }

.plyr__menu__container label.plyr__control input[type=radio]:checked + span {
  background: #8dc63f; }

.plyr__menu__container label.plyr__control input[type=radio]:checked + span::after {
  opacity: 1;
  transform: scale(1); }

.plyr__menu__container label.plyr__control input[type=radio]:focus + span {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0; }

.plyr__menu__container label.plyr__control.plyr__tab-focus input[type=radio] + span, .plyr__menu__container label.plyr__control:hover input[type=radio] + span {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -7px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 28px;
  color: #8dc63f;
  display: block;
  height: 20px;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  box-sizing: border-box;
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  box-sizing: border-box;
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 3px;
  height: 6px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  box-sizing: border-box;
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
  pointer-events: none; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0, 0) scale(1); }

.plyr--video {
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__progress {
  display: flex;
  flex: 1;
  left: 7px;
  margin-right: 14px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 6px;
  left: 0;
  margin-top: -3px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0;
  transition: width .2s ease; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 6px; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 6px;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  flex: 1;
  position: relative; }

.plyr__volume input[type=range] {
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 50px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 80px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible; }

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible; }

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible; }

.plyr:fullscreen .plyr__video-embed {
  overflow: visible; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback .plyr__video-embed {
  overflow: visible; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 6px;
  left: 0;
  margin: -3px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr--no-transition {
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

/*!
 * fullPage 3.0.0
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*Avoid flicker on slides transitions for mobile phones #336 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box;
  /* <=28 */
  box-sizing: border-box; }

.fp-slide {
  float: left; }

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block; }

.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease-out;
  /* Safari<=6 Android<=4.3 */
  transition: all 0.3s ease-out; }

.fp-section.fp-table, .fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%; }

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.fp-slidesContainer {
  float: left;
  position: relative; }

.fp-controlArrow {
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
  position: absolute;
  z-index: 4;
  top: 50%;
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: -38px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fp-controlArrow.fp-prev {
  left: 15px;
  width: 0;
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent; }

.fp-controlArrow.fp-next {
  right: 15px;
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff; }

.fp-scrollable {
  overflow: hidden;
  position: relative; }

.fp-scroller {
  overflow: hidden; }

.iScrollIndicator {
  border: 0 !important; }

.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important; }

#fp-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0); }

#fp-nav.fp-right {
  right: 17px; }

#fp-nav.fp-left {
  left: 17px; }

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important; }

.fp-slidesNav.fp-bottom {
  bottom: 17px; }

.fp-slidesNav.fp-top {
  top: 17px; }

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0; }

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative; }

.fp-slidesNav ul li {
  display: inline-block; }

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none; }

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%; }

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0px 0px -5px; }

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer; }

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1; }

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px; }

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px; }

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
  height: auto !important; }

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
  height: auto !important; }

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform; }

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0); }

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0); }

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0); }

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0); }

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0); }

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0); }

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0); }

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1); }

[data-aos=zoom-in] {
  transform: scale(0.6); }

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos=zoom-out] {
  transform: scale(1.2); }

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0); }

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0); }

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0); }

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0); }

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0); }

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0); }

.hd-style2 {
  font-size: 52px;
  line-height: 1.5;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold; }
  @media (max-width: 1919px) {
    .hd-style2 {
      font-size: 46px; } }
  @media (max-width: 1439px) {
    .hd-style2 {
      font-size: 40px; } }
  @media (max-width: 1199px) {
    .hd-style2 {
      font-size: 36px; } }
  @media (max-width: 767px) {
    .hd-style2 {
      font-size: 30px; } }

.para-styleBold {
  font-weight: 700;
  font-style: italic;
  margin-bottom: 30px; }

.standalone-btn {
  text-align: center;
  padding: 130px 0; }
  .standalone-btn .cta-btn {
    margin: 0 auto; }
  @media (max-width: 767px) {
    .standalone-btn {
      padding: 25px 0 35px; }
      .standalone-btn .cta-btn {
        display: flex;
        width: 100%;
        text-align: left;
        align-items: center;
        justify-content: space-between; } }

.sec-customerExp.p-0 > .fp-tableCell {
  padding: 0 !important; }

.sec-featured {
  background-color: #fdfdfd; }
  @media (max-width: 991px) {
    .sec-featured {
      background-color: none; } }

.textContent {
  width: 70%;
  padding: 120px 0;
  clear: both; }
  .textContent p {
    margin-bottom: 30px; }
    .textContent p:last-child {
      margin-bottom: 0px; }
  .textContent.p-0 {
    padding: 0 !important; }
  .textContent.textCenter {
    margin: 0 auto;
    text-align: center;
    padding: 120px 0 60px; }
  .textContent.fullwidth {
    width: 100%; }
  .textContent.awards-content {
    padding-bottom: 60px; }

.js-bg-video-hover:hover .bg-video .preview-img {
  display: none; }

.showcase {
  font-size: 0; }
  .showcase .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .showcase .bg-video .preview-img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000 no-repeat center center;
      background-size: cover;
      z-index: 1; }
      @media (max-width: 1199px) {
        .showcase .bg-video .preview-img {
          display: none; } }
    .showcase .bg-video .cover-video {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; }
      .showcase .bg-video .cover-video .cover-wrap {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #000; }
        .showcase .bg-video .cover-video .cover-wrap video {
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          opacity: 0.5;
          transition: opacity .35s; }
  .showcase .item:hover .bg-video .cover-video .cover-wrap video {
    opacity: 1; }
  .showcase .grid {
    width: 33.333333%;
    padding: 2px;
    position: relative;
    display: inline-block;
    overflow: hidden; }
    @media (max-width: 991px) {
      .showcase .grid {
        width: 100%;
        padding: 15px 25px; } }
    @media (max-width: 359px) {
      .showcase .grid {
        width: 100%;
        padding: 10px 10px; } }
    .showcase .grid .item {
      width: 100%;
      position: relative;
      display: block;
      text-align: center;
      padding-top: 82%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: 0.5s all ease;
      overflow: hidden;
      color: #FFFFFF; }
      @media (max-width: 991px) {
        .showcase .grid .item {
          padding-top: 50%; } }
      @media (max-width: 767px) {
        .showcase .grid .item {
          padding-top: 80%; } }
      .showcase .grid .item:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0);
        transition: all 0.35s ease 0s;
        transform: translateY(100%); }
      .showcase .grid .item .item-content {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        transition: all 0.35s ease 0s;
        padding: 40px 80px;
        height: 100%;
        z-index: 2; }
        .showcase .grid .item .item-content .hd-style1 {
          line-height: 1.5;
          font-style: italic;
          text-transform: uppercase;
          font-weight: 700;
          transform: translateY(-100%);
          margin: 0;
          padding-bottom: 40px;
          margin-top: -40px;
          transition: all 0.35s ease 0s; }
        .showcase .grid .item .item-content p {
          position: relative;
          color: #fff;
          font-size: 18px;
          max-height: 0px;
          padding-top: 40px;
          transition: all 0.35s ease 0s;
          display: block;
          overflow: hidden;
          margin: 0;
          opacity: 0; }
          .showcase .grid .item .item-content p:before {
            content: '';
            position: absolute;
            top: 0;
            left: 30%;
            display: block;
            height: 2px;
            width: 40%;
            background-color: #FFFFFF; }
        @media (max-width: 1599px) {
          .showcase .grid .item .item-content {
            padding: 25px; }
            .showcase .grid .item .item-content .hd-style1 {
              padding-bottom: 25px;
              margin-top: -25px; }
            .showcase .grid .item .item-content p {
              padding-top: 25px; } }
      .showcase .grid .item:hover .item-content {
        top: 0; }
        .showcase .grid .item:hover .item-content .hd-style1 {
          transform: translateY(0);
          margin-top: 0; }
        .showcase .grid .item:hover .item-content p {
          max-height: 999px;
          transition: all 0.35s ease 0.25s;
          display: block;
          opacity: 1; }
      .showcase .grid .item:hover:before {
        background-color: rgba(0, 0, 0, 0.85);
        transform: translateY(0); }
      .showcase .grid .item.no-hover:hover .item-content {
        top: 100%; }
        .showcase .grid .item.no-hover:hover .item-content .hd-style1 {
          transform: translateY(-100%);
          margin-top: -40px; }
        .showcase .grid .item.no-hover:hover .item-content p {
          max-height: 0; }
      .showcase .grid .item.no-hover:hover:before {
        background-color: rgba(0, 0, 0, 0); }
      .showcase .grid .item.hovered-item:before {
        background-color: rgba(0, 0, 0, 0.3); }
      .showcase .grid .item.hovered-item .item-content {
        top: auto;
        bottom: 0;
        height: auto; }
        .showcase .grid .item.hovered-item .item-content .hd-style1 {
          transform: translateY(0);
          margin: 0; }
        .showcase .grid .item.hovered-item .item-content p {
          max-height: none;
          padding-top: 0; }
          .showcase .grid .item.hovered-item .item-content p:before {
            display: none; }
    .showcase .grid.grid-half {
      width: 50%; }
      @media (min-width: 1400px) {
        .showcase .grid.grid-half .hovered-item .item-content {
          padding-bottom: 80px; } }
  .showcase.video .item .item-content {
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .showcase.video .item .item-content .hd-style1 {
      transform: translateY(0);
      margin-top: 0;
      color: #FFFFFF; }
    .showcase.video .item .item-content a {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .showcase.video .item:hover .item-content .iconsPlay {
    opacity: 1; }
  @media (max-width: 767px) {
    .showcase.video .grid .item .item-content .hd-style1 {
      padding-bottom: 0;
      text-align: center; } }
  .showcase .slick-dots {
    padding-top: 40px; }

.sec-video {
  overflow-x: hidden; }

.sec-ourProjects .textContent {
  width: 100%;
  padding: 0px 20px 60px;
  text-align: center; }

.counter-box {
  display: flex;
  margin: 0 -2px; }
  .counter-box > .counter-item {
    flex: 1;
    background-color: transparent;
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    color: #292829;
    padding: 20px 40px;
    text-align: left;
    text-align: center; }
    .counter-box > .counter-item:last-child {
      border-right: 0; }
    .counter-box > .counter-item span {
      font-size: 58px;
      font-weight: 700;
      font-style: italic; }
    .counter-box > .counter-item p {
      font-size: 18px;
      margin: 20px 0 0 0; }

.accordion-box {
  margin: 80px 0 40px; }
  .accordion-box .acc-heads {
    display: flex;
    position: relative;
    margin-bottom: 35px;
    padding-right: 40px; }
    .accordion-box .acc-heads > h3 {
      flex: 1;
      font-size: 22px;
      color: #5f5e5f;
      padding: 10px 25px 0 0;
      margin-bottom: 0px; }
  .accordion-box .accordion-item {
    position: relative; }
    .accordion-box .accordion-item .acc-title {
      display: flex;
      cursor: pointer;
      color: #727272;
      clear: both;
      transition: all .35s ease 0s; }
      .accordion-box .accordion-item .acc-title > h2 {
        flex: 1;
        font-size: 26px;
        font-weight: 700;
        padding: 40px 0;
        margin: 0 40px 0 0;
        text-transform: uppercase;
        font-style: italic; }
        @media (max-width: 1599px) {
          .accordion-box .accordion-item .acc-title > h2 {
            font-size: 24px;
            padding: 25px 0; } }
        @media (max-width: 1439px) {
          .accordion-box .accordion-item .acc-title > h2 {
            font-size: 22px; } }
        @media (max-width: 991px) {
          .accordion-box .accordion-item .acc-title > h2 {
            margin-right: 10px; } }
      .accordion-box .accordion-item .acc-title .hd-style1 {
        padding: 40px 0;
        margin: 0 40px 0 0; }
        @media (max-width: 1599px) {
          .accordion-box .accordion-item .acc-title .hd-style1 {
            padding: 25px 0; } }
      .accordion-box .accordion-item .acc-title.active {
        color: #292829; }
        .accordion-box .accordion-item .acc-title.active:after {
          transform: rotate(90deg);
          opacity: 1; }
      .accordion-box .accordion-item .acc-title:after {
        content: "";
        width: 40px;
        height: 40px;
        margin: auto 0 auto auto;
        display: inline-block;
        background-image: url(../img/icons/ic-angle-right.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        transform-origin: center;
        transform: rotate(0deg);
        opacity: .5;
        transition: all .35s ease 0s; }
        @media (max-width: 1599px) {
          .accordion-box .accordion-item .acc-title:after {
            width: 30px;
            height: 30px; } }
    .accordion-box .accordion-item .acc-content {
      display: none;
      position: relative;
      margin-bottom: 70px; }
      .accordion-box .accordion-item .acc-content p {
        font-size: 18px;
        color: #292829;
        margin-bottom: 50px; }
        @media (max-width: 1599px) {
          .accordion-box .accordion-item .acc-content p {
            margin-bottom: 30px; } }
      .accordion-box .accordion-item .acc-content .acc-cta {
        display: flex;
        flex-direction: row-reverse; }
        .accordion-box .accordion-item .acc-content .acc-cta .cta-btn {
          margin-left: 40px; }
      @media (max-width: 1199px) {
        .accordion-box .accordion-item .acc-content {
          margin-bottom: 30px; } }
    .accordion-box .accordion-item:after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: #a8a8aa;
      position: absolute;
      left: 0;
      top: 0px; }

.sec-contact .footer-social {
  margin: 50px 0; }

.listingcontent {
  display: block;
  list-style: circle;
  padding: 0 0 0 20px; }
  .listingcontent li {
    display: block;
    padding: 10px 0; }
    .listingcontent li:before {
      content: '-  ';
      display: inline-block;
      margin-right: 10px; }

.slidingBox {
  display: flex;
  overflow: hidden;
  margin: 40px 0 80px 0; }
  .slidingBox h2 {
    font-size: 30px;
    font-weight: 700;
    font-family: "Futura";
    font-style: italic;
    text-transform: uppercase;
    padding: 40px;
    margin: 0; }
  .slidingBox .sB-item {
    position: relative;
    background-color: #fbfbfb;
    border: 2px solid #fff;
    display: block;
    overflow: hidden;
    width: 15%;
    cursor: pointer;
    flex-shrink: 1;
    transition: all 0.6s ease 0s; }
    @media (max-width: 1199px) {
      .slidingBox .sB-item {
        width: 10%; } }
    .slidingBox .sB-item .sB-title {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      width: 1px;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease 0.3s; }
      .slidingBox .sB-item .sB-title h2 {
        padding: 0;
        transform: rotate(-90deg); }
    .slidingBox .sB-item .sB-itemInner {
      flex-shrink: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease 0s;
      min-width: 815px; }
      @media (max-width: 1599px) {
        .slidingBox .sB-item .sB-itemInner {
          min-width: 780px; } }
      @media (max-width: 1439px) {
        .slidingBox .sB-item .sB-itemInner {
          min-width: 0;
          width: 840px; } }
      @media (max-width: 1199px) {
        .slidingBox .sB-item .sB-itemInner {
          width: 670px; } }
      @media (max-width: 991px) {
        .slidingBox .sB-item .sB-itemInner {
          width: 818px; } }
    .slidingBox .sB-item .sB-content {
      padding: 0px 40px 80px;
      color: #292829;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between; }
      .slidingBox .sB-item .sB-content .empTXT {
        padding-right: 80px; }
        .slidingBox .sB-item .sB-content .empTXT a {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          margin-bottom: 15px; }
        .slidingBox .sB-item .sB-content .empTXT svg {
          width: 25px;
          height: 25px;
          float: left;
          margin: 8px 20px 8px 8px; }
        .slidingBox .sB-item .sB-content .empTXT span {
          font-weight: 100;
          font-family: "Futura"; }
      .slidingBox .sB-item .sB-content p {
        font-size: 18px;
        margin-bottom: 5px;
        font-family: "Futura"; }
      .slidingBox .sB-item .sB-content a {
        display: block; }
      .slidingBox .sB-item .sB-content .addressTXT {
        margin-bottom: 40px; }
      .slidingBox .sB-item .sB-content .numbersTXT svg {
        width: 25px;
        height: 25px;
        margin-right: 20px;
        display: inline-block;
        vertical-align: middle; }
      .slidingBox .sB-item .sB-content .numbersTXT span {
        display: inline-block;
        font-size: 18px;
        font-family: "Futura"; }
    .slidingBox .sB-item .sB-map {
      display: block;
      clear: both;
      width: 100%;
      height: 280px; }
    .slidingBox .sB-item.active {
      width: 85%;
      cursor: default; }
      @media (max-width: 1199px) {
        .slidingBox .sB-item.active {
          width: 90%; } }
      .slidingBox .sB-item.active .sB-itemInner {
        opacity: 1;
        visibility: visible; }
      .slidingBox .sB-item.active .sB-title {
        bottom: -100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease 0s; }

p mark {
  background-color: transparent;
  white-space: nowrap;
  color: inherit; }

.tosvg.svgPrimary path {
  fill: #8dc63f !important;
  stroke: none !important; }

.tosvg.svgGray path {
  fill: #cacaca !important;
  stroke: none !important; }

.tosvg.svgWhite path {
  fill: #FFFFFF !important;
  stroke: none !important; }

.tosvg.svgBlack path {
  fill: #292829 !important;
  stroke: none !important; }

.fullpagesections .fp-section {
  min-height: 100vh; }

.fp-enabled .fullpagesections .fp-section {
  min-height: 0; }

@media (max-width: 767px) {
  .fullpagesections .fp-section {
    min-height: 0; } }

.c-main-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  padding: 20px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background: transparent;
  transition: opacity 0.35s ease 0s, background 0.2s linear 0s; }
  .c-main-header .c-btn-account {
    align-self: baseline;
    margin: 0 40px 0 auto; }
  @media (max-width: 1919px) {
    .c-main-header {
      padding: 20px 25px; } }

.brand-logo {
  position: relative;
  display: block;
  width: 122px;
  background-color: transparent;
  background-size: 100%;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  height: 54px; }
  .brand-logo:after {
    content: '';
    display: block;
    padding-top: 31.37254901960784%; }
  .brand-logo span {
    display: none; }
  .brand-logo.brand-white {
    background-image: url(../img/brand/logo-white.png); }
  .brand-logo.brand-green {
    background-image: url(../img/brand/logo-green.png); }
  .brand-logo.brand-black {
    background-image: url(../img/brand/logo-black.png); }

.header-logo {
  position: fixed;
  left: 40px;
  top: 20px;
  transition: all 0.35s ease 0s;
  z-index: 21; }
  @media (max-width: 1919px) {
    .header-logo {
      left: 25px;
      height: 46px; } }

.header-right {
  display: flex;
  align-items: center; }

.c-btn-menu {
  width: 45px;
  height: 35px;
  display: block; }
  .c-btn-menu .menuicon {
    width: 100%;
    transform: scale(0.8); }
  .c-btn-menu span {
    display: none; }
  .c-btn-menu path {
    transition: all 0.35s ease 0s;
    opacity: 1;
    transform: none;
    transform-origin: center;
    transform: scale(1); }

.menuopen .c-btn-menu path:first-child, .menuopen .c-btn-menu path:last-child {
  transform: scale(0); }

.menuopen .c-btn-menu path.midA {
  transform: rotate(45deg); }

.menuopen .c-btn-menu path.midB {
  transform: rotate(-45deg); }

.c-search-box {
  display: flex;
  margin-right: 5px;
  padding: 10px 13px;
  background-color: rgba(255, 255, 255, 0);
  will-change: background-color;
  transition: all 0.35s linear; }
  .c-search-box .c-btn-search {
    cursor: pointer;
    box-shadow: 0;
    outline: none;
    border: 0;
    padding: 0;
    margin: 0;
    display: inline-block;
    background-color: transparent; }
    .c-search-box .c-btn-search .searchicon {
      display: block;
      width: 34px;
      height: 34px; }
      @media (max-width: 1919px) {
        .c-search-box .c-btn-search .searchicon {
          width: 26px;
          height: 26px; } }
      .c-search-box .c-btn-search .searchicon path {
        fill: #FFFFFF !important;
        stroke: none !important;
        transition: all 0.45s ease 0s; }
  .c-search-box input {
    border-radius: 0;
    border: 0;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 0;
    margin-right: 10px;
    font-size: 20px;
    font-family: "Georgia";
    opacity: 0;
    visibility: hidden;
    will-change: opacity, visibility, width;
    transition: opacity 0.35s ease 0.0s, visibility 0.35s ease 0.0s, width 0.35s ease 0.2s; }
    .c-search-box input::placeholder {
      opacity: 1;
      color: #292829; }
  .c-search-box.searching {
    background-color: white;
    transition: all 0.45s ease 0s; }
    .c-search-box.searching .c-btn-search .searchicon path {
      fill: #292829 !important;
      stroke: none !important; }
    .c-search-box.searching input {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.35s ease 0.2s, visibility 0.35s ease 0.0s, width 0.35s ease 0s;
      width: 400px; }
      @media (max-width: 991px) {
        .c-search-box.searching input {
          width: 280px; } }

.c-fullpage-menu {
  position: fixed;
  z-index: 17;
  top: 0;
  right: 60px;
  height: 100%;
  padding: 130px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  .c-fullpage-menu > div {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center; }
  .c-fullpage-menu:before, .c-fullpage-menu:after {
    content: '';
    display: block;
    height: 100%;
    background: rgba(255, 255, 255, 0.25);
    width: 1px;
    margin-left: 3px; }
  .c-fullpage-menu:before {
    margin-bottom: 30px; }
  .c-fullpage-menu:after {
    margin-top: 30px; }
  .c-fullpage-menu li {
    display: block;
    margin: 20px 0; }
    .c-fullpage-menu li.active a span {
      right: 32px;
      opacity: 1; }
    .c-fullpage-menu li.active a:before {
      opacity: 0.24;
      width: 41px;
      height: 41px; }
    .c-fullpage-menu li:hover a:before {
      opacity: 0.15;
      width: 27px;
      height: 27px; }
  .c-fullpage-menu a {
    display: block;
    font-size: 13px;
    line-height: 1.3;
    letter-spacing: 0.75px;
    position: relative;
    width: 7px;
    height: 7px;
    color: #FFFFFF;
    background-color: #FFFFFF;
    border-radius: 50%;
    text-transform: uppercase;
    text-align: right; }
    .c-fullpage-menu a:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      background-color: #FFFFFF;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition-property: width, height, opacity;
      transition-duration: .35s; }
    .c-fullpage-menu a span {
      position: absolute;
      top: 50%;
      right: 0;
      opacity: 0;
      transform: translateY(-50%);
      transition-property: opacity, right;
      transition-duration: .35s;
      overflow: hidden;
      min-width: 100px;
      will-change: opacity, right; }
      @media (max-width: 1199px) {
        .c-fullpage-menu a span {
          display: none; } }
  @media (max-width: 1919px) {
    .c-fullpage-menu {
      right: 45px; } }
  @media (max-width: 991px) {
    .c-fullpage-menu {
      display: none; } }

.fp-viewing-deiramall .c-fullpage-menu:before, .fp-viewing-deiramall .c-fullpage-menu:after,
.fp-viewing-megamall .c-fullpage-menu:before,
.fp-viewing-megamall .c-fullpage-menu:after,
.fp-viewing-page-sec-about .c-fullpage-menu:before,
.fp-viewing-page-sec-about .c-fullpage-menu:after,
.fp-viewing-page-sec-cexp .c-fullpage-menu:before,
.fp-viewing-page-sec-cexp .c-fullpage-menu:after,
.fp-viewing-page-sec-featured .c-fullpage-menu:before,
.fp-viewing-page-sec-featured .c-fullpage-menu:after,
.fp-viewing-page-sec-showcase .c-fullpage-menu:before,
.fp-viewing-page-sec-showcase .c-fullpage-menu:after,
.fp-viewing-about .c-fullpage-menu:before,
.fp-viewing-about .c-fullpage-menu:after,
.fp-viewing-featured .c-fullpage-menu:before,
.fp-viewing-featured .c-fullpage-menu:after,
.fp-viewing-showcase .c-fullpage-menu:before,
.fp-viewing-showcase .c-fullpage-menu:after,
.fp-viewing-ourhistory .c-fullpage-menu:before,
.fp-viewing-ourhistory .c-fullpage-menu:after,
.fp-viewing-ourhistory1 .c-fullpage-menu:before,
.fp-viewing-ourhistory1 .c-fullpage-menu:after,
.fp-viewing-aboutus .c-fullpage-menu:before,
.fp-viewing-aboutus .c-fullpage-menu:after,
.fp-viewing-ourteam .c-fullpage-menu:before,
.fp-viewing-ourteam .c-fullpage-menu:after,
.fp-viewing-awards .c-fullpage-menu:before,
.fp-viewing-awards .c-fullpage-menu:after,
.fp-viewing-teammembers .c-fullpage-menu:before,
.fp-viewing-teammembers .c-fullpage-menu:after,
.fp-viewing-careers .c-fullpage-menu:before,
.fp-viewing-careers .c-fullpage-menu:after,
.fp-viewing-projectintro .c-fullpage-menu:before,
.fp-viewing-projectintro .c-fullpage-menu:after,
.fp-viewing-projectfacts .c-fullpage-menu:before,
.fp-viewing-projectfacts .c-fullpage-menu:after,
.fp-viewing-journeybegins .c-fullpage-menu:before,
.fp-viewing-journeybegins .c-fullpage-menu:after,
.fp-viewing-theparking .c-fullpage-menu:before,
.fp-viewing-theparking .c-fullpage-menu:after,
.fp-viewing-more .c-fullpage-menu:before,
.fp-viewing-more .c-fullpage-menu:after,
.fp-viewing-yassouth .c-fullpage-menu:before,
.fp-viewing-yassouth .c-fullpage-menu:after,
.fp-viewing-contact .c-fullpage-menu:before,
.fp-viewing-contact .c-fullpage-menu:after,
.fp-viewing-Enquiryform .c-fullpage-menu:before,
.fp-viewing-Enquiryform .c-fullpage-menu:after,
.fp-viewing-whatwedo .c-fullpage-menu:before,
.fp-viewing-whatwedo .c-fullpage-menu:after,
.fp-viewing-clients .c-fullpage-menu:before,
.fp-viewing-clients .c-fullpage-menu:after,
.fp-viewing-casestudies .c-fullpage-menu:before,
.fp-viewing-casestudies .c-fullpage-menu:after,
.fp-viewing-contact .c-fullpage-menu:before,
.fp-viewing-contact .c-fullpage-menu:after {
  background: rgba(0, 0, 0, 0.3); }

.fp-viewing-deiramall .c-fullpage-menu:before,
.fp-viewing-megamall .c-fullpage-menu:before,
.fp-viewing-page-sec-about .c-fullpage-menu:before,
.fp-viewing-page-sec-cexp .c-fullpage-menu:before,
.fp-viewing-page-sec-featured .c-fullpage-menu:before,
.fp-viewing-page-sec-showcase .c-fullpage-menu:before,
.fp-viewing-about .c-fullpage-menu:before,
.fp-viewing-featured .c-fullpage-menu:before,
.fp-viewing-showcase .c-fullpage-menu:before,
.fp-viewing-ourhistory .c-fullpage-menu:before,
.fp-viewing-ourhistory1 .c-fullpage-menu:before,
.fp-viewing-aboutus .c-fullpage-menu:before,
.fp-viewing-ourteam .c-fullpage-menu:before,
.fp-viewing-awards .c-fullpage-menu:before,
.fp-viewing-teammembers .c-fullpage-menu:before,
.fp-viewing-careers .c-fullpage-menu:before,
.fp-viewing-projectintro .c-fullpage-menu:before,
.fp-viewing-projectfacts .c-fullpage-menu:before,
.fp-viewing-journeybegins .c-fullpage-menu:before,
.fp-viewing-theparking .c-fullpage-menu:before,
.fp-viewing-more .c-fullpage-menu:before,
.fp-viewing-yassouth .c-fullpage-menu:before,
.fp-viewing-contact .c-fullpage-menu:before,
.fp-viewing-Enquiryform .c-fullpage-menu:before,
.fp-viewing-whatwedo .c-fullpage-menu:before,
.fp-viewing-clients .c-fullpage-menu:before,
.fp-viewing-casestudies .c-fullpage-menu:before,
.fp-viewing-contact .c-fullpage-menu:before {
  margin-bottom: 30px; }

.fp-viewing-deiramall .c-fullpage-menu:after,
.fp-viewing-megamall .c-fullpage-menu:after,
.fp-viewing-page-sec-about .c-fullpage-menu:after,
.fp-viewing-page-sec-cexp .c-fullpage-menu:after,
.fp-viewing-page-sec-featured .c-fullpage-menu:after,
.fp-viewing-page-sec-showcase .c-fullpage-menu:after,
.fp-viewing-about .c-fullpage-menu:after,
.fp-viewing-featured .c-fullpage-menu:after,
.fp-viewing-showcase .c-fullpage-menu:after,
.fp-viewing-ourhistory .c-fullpage-menu:after,
.fp-viewing-ourhistory1 .c-fullpage-menu:after,
.fp-viewing-aboutus .c-fullpage-menu:after,
.fp-viewing-ourteam .c-fullpage-menu:after,
.fp-viewing-awards .c-fullpage-menu:after,
.fp-viewing-teammembers .c-fullpage-menu:after,
.fp-viewing-careers .c-fullpage-menu:after,
.fp-viewing-projectintro .c-fullpage-menu:after,
.fp-viewing-projectfacts .c-fullpage-menu:after,
.fp-viewing-journeybegins .c-fullpage-menu:after,
.fp-viewing-theparking .c-fullpage-menu:after,
.fp-viewing-more .c-fullpage-menu:after,
.fp-viewing-yassouth .c-fullpage-menu:after,
.fp-viewing-contact .c-fullpage-menu:after,
.fp-viewing-Enquiryform .c-fullpage-menu:after,
.fp-viewing-whatwedo .c-fullpage-menu:after,
.fp-viewing-clients .c-fullpage-menu:after,
.fp-viewing-casestudies .c-fullpage-menu:after,
.fp-viewing-contact .c-fullpage-menu:after {
  margin-top: 30px; }

.fp-viewing-deiramall .c-fullpage-menu a,
.fp-viewing-megamall .c-fullpage-menu a,
.fp-viewing-page-sec-about .c-fullpage-menu a,
.fp-viewing-page-sec-cexp .c-fullpage-menu a,
.fp-viewing-page-sec-featured .c-fullpage-menu a,
.fp-viewing-page-sec-showcase .c-fullpage-menu a,
.fp-viewing-about .c-fullpage-menu a,
.fp-viewing-featured .c-fullpage-menu a,
.fp-viewing-showcase .c-fullpage-menu a,
.fp-viewing-ourhistory .c-fullpage-menu a,
.fp-viewing-ourhistory1 .c-fullpage-menu a,
.fp-viewing-aboutus .c-fullpage-menu a,
.fp-viewing-ourteam .c-fullpage-menu a,
.fp-viewing-awards .c-fullpage-menu a,
.fp-viewing-teammembers .c-fullpage-menu a,
.fp-viewing-careers .c-fullpage-menu a,
.fp-viewing-projectintro .c-fullpage-menu a,
.fp-viewing-projectfacts .c-fullpage-menu a,
.fp-viewing-journeybegins .c-fullpage-menu a,
.fp-viewing-theparking .c-fullpage-menu a,
.fp-viewing-more .c-fullpage-menu a,
.fp-viewing-yassouth .c-fullpage-menu a,
.fp-viewing-contact .c-fullpage-menu a,
.fp-viewing-Enquiryform .c-fullpage-menu a,
.fp-viewing-whatwedo .c-fullpage-menu a,
.fp-viewing-clients .c-fullpage-menu a,
.fp-viewing-casestudies .c-fullpage-menu a,
.fp-viewing-contact .c-fullpage-menu a {
  color: #c2c2c2;
  background-color: #c2c2c2; }
  .fp-viewing-deiramall .c-fullpage-menu a:before,
  .fp-viewing-megamall .c-fullpage-menu a:before,
  .fp-viewing-page-sec-about .c-fullpage-menu a:before,
  .fp-viewing-page-sec-cexp .c-fullpage-menu a:before,
  .fp-viewing-page-sec-featured .c-fullpage-menu a:before,
  .fp-viewing-page-sec-showcase .c-fullpage-menu a:before,
  .fp-viewing-about .c-fullpage-menu a:before,
  .fp-viewing-featured .c-fullpage-menu a:before,
  .fp-viewing-showcase .c-fullpage-menu a:before,
  .fp-viewing-ourhistory .c-fullpage-menu a:before,
  .fp-viewing-ourhistory1 .c-fullpage-menu a:before,
  .fp-viewing-aboutus .c-fullpage-menu a:before,
  .fp-viewing-ourteam .c-fullpage-menu a:before,
  .fp-viewing-awards .c-fullpage-menu a:before,
  .fp-viewing-teammembers .c-fullpage-menu a:before,
  .fp-viewing-careers .c-fullpage-menu a:before,
  .fp-viewing-projectintro .c-fullpage-menu a:before,
  .fp-viewing-projectfacts .c-fullpage-menu a:before,
  .fp-viewing-journeybegins .c-fullpage-menu a:before,
  .fp-viewing-theparking .c-fullpage-menu a:before,
  .fp-viewing-more .c-fullpage-menu a:before,
  .fp-viewing-yassouth .c-fullpage-menu a:before,
  .fp-viewing-contact .c-fullpage-menu a:before,
  .fp-viewing-Enquiryform .c-fullpage-menu a:before,
  .fp-viewing-whatwedo .c-fullpage-menu a:before,
  .fp-viewing-clients .c-fullpage-menu a:before,
  .fp-viewing-casestudies .c-fullpage-menu a:before,
  .fp-viewing-contact .c-fullpage-menu a:before {
    background-color: #c2c2c2; }

.c-social {
  position: fixed;
  z-index: 16;
  top: 0;
  left: 60px;
  height: 100%;
  padding: 130px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  .c-social > div {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center; }
  .c-social:before, .c-social:after {
    content: '';
    display: block;
    height: 100%;
    background: rgba(255, 255, 255, 0.25);
    width: 1px;
    margin-left: 3px; }
  .c-social:before {
    margin-bottom: 30px; }
  .c-social:after {
    margin-top: 30px; }
  .c-social a {
    display: flex;
    width: 32px;
    height: 32px;
    position: relative;
    margin: 10px 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
    color: #292829;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    will-change: background-color;
    transition: all 0.35s ease 0s; }
    .c-social a .scicons {
      max-width: 100%;
      max-height: 100%;
      display: block; }
      .c-social a .scicons path {
        transition: all 0.35s ease 0s;
        fill: rgba(255, 255, 255, 0.75); }
    .c-social a span {
      display: none; }
    .c-social a:hover path {
      fill: white; }
  @media (max-width: 1919px) {
    .c-social {
      left: 45px; } }
  @media (max-width: 991px) {
    .c-social {
      display: none; } }

.shadedImg:after {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.5) 100%);
  opacity: 0.8;
  content: ""; }

.c-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden; }
  .c-background .plyr--video {
    position: absolute;
    width: 120%;
    height: 120%;
    max-width: 120%;
    left: -10%;
    top: -10%; }
  .c-background .bg-img,
  .c-background .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000 no-repeat center center;
    overflow: hidden; }
    .c-background .bg-img video,
    .c-background .bg-video video {
      width: 150%;
      left: -25%;
      position: absolute; }
    .c-background .bg-img.bg-img--exp-sec,
    .c-background .bg-video.bg-img--exp-sec {
      opacity: 0;
      transition: opacity .35s;
      filter: blur(5px);
      padding: 20px;
      left: -10px;
      top: -10px;
      box-sizing: content-box;
      will-change: opacity; }
      .c-background .bg-img.bg-img--exp-sec:after,
      .c-background .bg-video.bg-img--exp-sec:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.75); }
  .c-background .custom--bg-img img {
    max-width: none;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.custom--bg-img img {
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.clientSlider .client-logo {
  height: 140px;
  padding: 20px 10px;
  text-align: center;
  margin-top: 15px; }
  @media (max-width: 991px) {
    .clientSlider .client-logo {
      padding: 10px;
      height: 120px; } }
  .clientSlider .client-logo img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    filter: saturate(0px); }

.c-scroll-down {
  position: fixed;
  padding: 40px 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  transition: all .35s;
  will-change: visibility, opacity; }
  .c-scroll-down span {
    display: none; }
  .c-scroll-down .scrollicon {
    display: block;
    width: 30px;
    height: 45px; }
  .c-scroll-down .mousewheel {
    animation: dash 1s linear infinite; }
  @media (max-width: 1919px) {
    .c-scroll-down {
      padding: 25px 0; } }
  @media (max-width: 991px) {
    .c-scroll-down {
      display: none; } }

@keyframes dash {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0px); } }

.c-chat-icons {
  position: fixed;
  padding: 40px 0;
  right: 40px;
  bottom: 0;
  z-index: 14;
  opacity: 0.75;
  transition: all .35s;
  will-change: transform, opacity; }
  .c-chat-icons span {
    display: none; }
  .c-chat-icons .chaticon {
    display: block;
    width: 45px;
    height: 45px; }
  .c-chat-icons:hover {
    opacity: 1; }
  @media (max-width: 1919px) {
    .c-chat-icons {
      right: 25px;
      padding: 25px 0; } }

@keyframes pulse {
  0% {
    transform: translateY(0px); }
  10% {
    transform: translateY(4px); }
  20% {
    transform: translateY(0px); }
  30% {
    transform: translateY(4px); }
  40% {
    transform: translateY(0px); }
  100% {
    transform: translateY(0px); } }

.banner-playvdo {
  display: block;
  position: absolute;
  z-index: 12;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.45;
  width: 120px;
  height: 120px;
  transition: all 0.35s ease 0s; }
  .banner-playvdo .playicon {
    width: 100%;
    height: 100%; }
  .banner-playvdo:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    opacity: 0.0;
    transition: all 0.35s ease 0s;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: center center; }
  .banner-playvdo:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    opacity: 0.0;
    transition: all 0.35s ease 0s;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: center center; }
  @media (max-width: 767px) {
    .banner-playvdo {
      width: 60px;
      height: 60px; }
      .banner-playvdo:after, .banner-playvdo:before {
        width: 60px;
        height: 60px; } }
  .banner-playvdo:hover {
    opacity: 1; }
    .banner-playvdo:hover:after {
      animation: pulsate 2s ease-out;
      animation-iteration-count: infinite;
      animation-play-state: running; }
    .banner-playvdo:hover:before {
      animation: pulsate 1.4s ease-out;
      animation-iteration-count: infinite;
      animation-play-state: running; }
  .banner-playvdo.small {
    width: 80px;
    height: 80px; }
    .banner-playvdo.small:after, .banner-playvdo.small:before {
      width: 80px;
      height: 80px; }
    @media (max-width: 767px) {
      .banner-playvdo.small {
        width: 60px;
        height: 60px; }
        .banner-playvdo.small:after, .banner-playvdo.small:before {
          width: 60px;
          height: 60px; } }
  .banner-playvdo span {
    display: none; }
  .banner-playvdo.no--hover:after, .banner-playvdo.no--hover:before {
    display: none !important; }

.js-popup-link:hover .banner-playvdo {
  opacity: 1; }
  .js-popup-link:hover .banner-playvdo:after {
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    animation-play-state: running; }
  .js-popup-link:hover .banner-playvdo:before {
    animation: pulsate 1.4s ease-out;
    animation-iteration-count: infinite;
    animation-play-state: running; }

@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.0; }
  50% {
    opacity: 0.7; }
  100% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 0.0; } }

.sec-home {
  position: relative; }
  .sec-home .container {
    position: relative;
    z-index: 3;
    height: 100%; }
  .sec-home .banner-content {
    padding: 60px 60px 0 0;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #FFF;
    z-index: 10;
    font-family: "Futura";
    max-width: 720px; }
    .sec-home .banner-content p {
      font-size: 20px;
      font-family: "Futura";
      font-weight: normal;
      line-height: 1.23;
      margin-bottom: 15px; }
    .sec-home .banner-content h2 {
      font-size: 52px;
      font-weight: bold;
      font-style: italic;
      text-transform: uppercase; }
      @media (max-width: 1919px) {
        .sec-home .banner-content h2 {
          font-size: 46px; } }
      @media (max-width: 1439px) {
        .sec-home .banner-content h2 {
          font-size: 40px; } }
      @media (max-width: 1199px) {
        .sec-home .banner-content h2 {
          font-size: 36px; } }
      @media (max-width: 767px) {
        .sec-home .banner-content h2 {
          font-size: 30px; } }
    .sec-home .banner-content .cta-btn {
      margin-top: 25px; }
    @media (max-width: 1439px) {
      .sec-home .banner-content {
        padding: 0; } }
    .sec-home .banner-content.content-relative {
      position: relative;
      padding: 160px 0 80px 0; }
      @media (max-width: 767px) {
        .sec-home .banner-content.content-relative {
          padding: 160px 0 40px 0; } }

.fullpagesections {
  position: relative; }
  .fullpagesections .fp-section {
    position: relative; }
    .fullpagesections .fp-section .fp-tableCell {
      padding: 135px 210px; }
    .fullpagesections .fp-section .fp-slides {
      position: relative; }
      .fullpagesections .fp-section .fp-slides .fp-tableCell {
        padding: 0; }
    .fullpagesections .fp-section.sec-footer .fp-tableCell {
      padding: 40px 40px 0 40px; }
    .fullpagesections .fp-section.sec-nopadding .fp-tableCell {
      padding: 0; }
    .fullpagesections .fp-section.sec-nopaddingX .fp-tableCell {
      padding-left: 0;
      padding-right: 0; }
  @media (max-width: 1919px) {
    .fullpagesections .fp-section .fp-tableCell {
      padding: 105px 160px; }
    .fullpagesections .fp-section.sec-footer .fp-tableCell {
      padding: 25px 25px 0 25px; } }
  @media (max-width: 1199px) {
    .fullpagesections .fp-section .fp-tableCell {
      padding: 105px 115px; }
    .fullpagesections .fp-section.sec-footer .fp-tableCell {
      padding: 25px 25px 0 25px; } }
  @media (max-width: 991px) {
    .fullpagesections .fp-section .fp-tableCell {
      padding: 105px 25px 25px 25px; } }

@media (max-width: 1919px) {
  .fullpagesections .fp-section.sec-nopadding.sec-teammembers .fp-tableCell {
    padding-left: 160px; } }

@media (max-width: 991px) {
  .fullpagesections .fp-section.sec-nopadding.sec-teammembers .fp-tableCell {
    padding-left: 0; } }

.text-uppercase {
  text-transform: uppercase; }

.txt-light {
  font-weight: lighter !important; }

.cta-btn {
  display: inline-block;
  font-size: 20px;
  padding: 13px 20px;
  line-height: 1;
  border: 1px solid #292829;
  color: #292829;
  text-transform: capitalize;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  font-weight: 300;
  position: relative;
  transition: all 0.35s ease 0s;
  font-family: "Futura"; }
  @media (max-width: 1199px) {
    .cta-btn {
      font-size: 18px;
      padding: 13px 15px; } }
  .cta-btn.nocase {
    text-transform: none; }
  .cta-btn:before, .cta-btn:after {
    width: 30px;
    height: 30px;
    position: absolute;
    border: 0;
    content: "";
    opacity: 0;
    transition: all 0.35s ease 0s; }
  .cta-btn:before {
    top: 0;
    left: 0;
    border-top: 1px solid;
    border-left: 1px solid; }
  .cta-btn:after {
    bottom: 0;
    right: 0;
    border-bottom: 1px solid;
    border-right: 1px solid; }
  .cta-btn:hover:before, .cta-btn:hover:after, .cta-btn.active:before, .cta-btn.active:after {
    opacity: 0.5; }
  .cta-btn:hover:before, .cta-btn.active:before {
    top: -10px;
    left: -10px; }
  .cta-btn:hover:after, .cta-btn.active:after {
    bottom: -10px;
    right: -10px; }
  .cta-btn .ctaicon {
    width: 16px;
    height: 12px;
    margin-left: 10px; }
    .cta-btn .ctaicon path {
      fill: #292829 !important;
      stroke: none !important; }
  .cta-btn.btn-filled {
    background-color: #292829;
    color: #FFFFFF;
    border-color: #292829; }
    .cta-btn.btn-filled .ctaicon path {
      fill: #FFFFFF !important;
      stroke: none !important; }
    .cta-btn.btn-filled:hover {
      background-color: rgba(41, 40, 41, 0.8); }
  .cta-btn.btn-white {
    color: #FFFFFF;
    border-color: #FFFFFF; }
    .cta-btn.btn-white .ctaicon path {
      fill: #FFFFFF !important;
      stroke: none !important; }
    .cta-btn.btn-white.btn-filled {
      background-color: #FFFFFF;
      color: #292829;
      border-color: #FFFFFF; }
      .cta-btn.btn-white.btn-filled .ctaicon path {
        fill: #292829 !important;
        stroke: none !important; }
      .cta-btn.btn-white.btn-filled:hover {
        background-color: rgba(255, 255, 255, 0.8); }
  .cta-btn.btn-gray {
    color: #cacaca;
    border-color: #cacaca; }
    .cta-btn.btn-gray .ctaicon path {
      fill: #cacaca !important;
      stroke: none !important; }
    .cta-btn.btn-gray.btn-filled {
      background-color: #cacaca;
      color: #FFFFFF;
      border-color: #cacaca; }
      .cta-btn.btn-gray.btn-filled .ctaicon path {
        fill: #FFFFFF !important;
        stroke: none !important; }
      .cta-btn.btn-gray.btn-filled:hover {
        background-color: rgba(202, 202, 202, 0.8); }
  .cta-btn.btn-primary {
    color: #8dc63f;
    border-color: #8dc63f; }
    .cta-btn.btn-primary .ctaicon path {
      fill: #8dc63f !important;
      stroke: none !important; }
    .cta-btn.btn-primary.btn-filled {
      background-color: #8dc63f;
      color: #FFFFFF;
      border-color: #8dc63f; }
      .cta-btn.btn-primary.btn-filled .ctaicon path {
        fill: #FFFFFF !important;
        stroke: none !important; }
      .cta-btn.btn-primary.btn-filled:hover {
        background-color: rgba(141, 198, 63, 0.8); }
  .cta-btn.btn-right {
    display: block;
    margin-left: auto; }

.fp-viewing-page-sec-home .c-scroll-down, .fp-viewing-limahhome .c-scroll-down, .fp-viewing-pgbanner .c-scroll-down {
  opacity: 1;
  visibility: visible; }

.fp-viewing-page-sec-about .c-social:before, .fp-viewing-page-sec-about .c-social:after,
.fp-viewing-page-sec-cexp .c-social:before,
.fp-viewing-page-sec-cexp .c-social:after,
.fp-viewing-page-sec-featured .c-social:before,
.fp-viewing-page-sec-featured .c-social:after,
.fp-viewing-page-sec-showcase .c-social:before,
.fp-viewing-page-sec-showcase .c-social:after,
.fp-viewing-about .c-social:before,
.fp-viewing-about .c-social:after,
.fp-viewing-cexp .c-social:before,
.fp-viewing-cexp .c-social:after,
.fp-viewing-featured .c-social:before,
.fp-viewing-featured .c-social:after,
.fp-viewing-showcase .c-social:before,
.fp-viewing-showcase .c-social:after,
.fp-viewing-ourhistory1 .c-social:before,
.fp-viewing-ourhistory1 .c-social:after,
.fp-viewing-aboutus .c-social:before,
.fp-viewing-aboutus .c-social:after,
.fp-viewing-ourteam .c-social:before,
.fp-viewing-ourteam .c-social:after,
.fp-viewing-awards .c-social:before,
.fp-viewing-awards .c-social:after,
.fp-viewing-teammembers .c-social:before,
.fp-viewing-teammembers .c-social:after,
.fp-viewing-careers .c-social:before,
.fp-viewing-careers .c-social:after,
.fp-viewing-projectintro .c-social:before,
.fp-viewing-projectintro .c-social:after,
.fp-viewing-projectfacts .c-social:before,
.fp-viewing-projectfacts .c-social:after,
.fp-viewing-journeybegins .c-social:before,
.fp-viewing-journeybegins .c-social:after,
.fp-viewing-theparking .c-social:before,
.fp-viewing-theparking .c-social:after,
.fp-viewing-more .c-social:before,
.fp-viewing-more .c-social:after,
.fp-viewing-yassouth .c-social:before,
.fp-viewing-yassouth .c-social:after,
.fp-viewing-contact .c-social:before,
.fp-viewing-contact .c-social:after,
.fp-viewing-Enquiryform .c-social:before,
.fp-viewing-Enquiryform .c-social:after,
.fp-viewing-whatwedo .c-social:before,
.fp-viewing-whatwedo .c-social:after,
.fp-viewing-clients .c-social:before,
.fp-viewing-clients .c-social:after,
.fp-viewing-casestudies .c-social:before,
.fp-viewing-casestudies .c-social:after,
.fp-viewing-contact .c-social:before,
.fp-viewing-contact .c-social:after {
  background: rgba(0, 0, 0, 0.3); }

.fp-viewing-page-sec-about .c-social a .scicons path,
.fp-viewing-page-sec-cexp .c-social a .scicons path,
.fp-viewing-page-sec-featured .c-social a .scicons path,
.fp-viewing-page-sec-showcase .c-social a .scicons path,
.fp-viewing-about .c-social a .scicons path,
.fp-viewing-cexp .c-social a .scicons path,
.fp-viewing-featured .c-social a .scicons path,
.fp-viewing-showcase .c-social a .scicons path,
.fp-viewing-ourhistory1 .c-social a .scicons path,
.fp-viewing-aboutus .c-social a .scicons path,
.fp-viewing-ourteam .c-social a .scicons path,
.fp-viewing-awards .c-social a .scicons path,
.fp-viewing-teammembers .c-social a .scicons path,
.fp-viewing-careers .c-social a .scicons path,
.fp-viewing-projectintro .c-social a .scicons path,
.fp-viewing-projectfacts .c-social a .scicons path,
.fp-viewing-journeybegins .c-social a .scicons path,
.fp-viewing-theparking .c-social a .scicons path,
.fp-viewing-more .c-social a .scicons path,
.fp-viewing-yassouth .c-social a .scicons path,
.fp-viewing-contact .c-social a .scicons path,
.fp-viewing-Enquiryform .c-social a .scicons path,
.fp-viewing-whatwedo .c-social a .scicons path,
.fp-viewing-clients .c-social a .scicons path,
.fp-viewing-casestudies .c-social a .scicons path,
.fp-viewing-contact .c-social a .scicons path {
  fill: rgba(0, 0, 0, 0.3); }

.fp-viewing-page-sec-about .c-social a:hover .scicons path,
.fp-viewing-page-sec-cexp .c-social a:hover .scicons path,
.fp-viewing-page-sec-featured .c-social a:hover .scicons path,
.fp-viewing-page-sec-showcase .c-social a:hover .scicons path,
.fp-viewing-about .c-social a:hover .scicons path,
.fp-viewing-cexp .c-social a:hover .scicons path,
.fp-viewing-featured .c-social a:hover .scicons path,
.fp-viewing-showcase .c-social a:hover .scicons path,
.fp-viewing-ourhistory1 .c-social a:hover .scicons path,
.fp-viewing-aboutus .c-social a:hover .scicons path,
.fp-viewing-ourteam .c-social a:hover .scicons path,
.fp-viewing-awards .c-social a:hover .scicons path,
.fp-viewing-teammembers .c-social a:hover .scicons path,
.fp-viewing-careers .c-social a:hover .scicons path,
.fp-viewing-projectintro .c-social a:hover .scicons path,
.fp-viewing-projectfacts .c-social a:hover .scicons path,
.fp-viewing-journeybegins .c-social a:hover .scicons path,
.fp-viewing-theparking .c-social a:hover .scicons path,
.fp-viewing-more .c-social a:hover .scicons path,
.fp-viewing-yassouth .c-social a:hover .scicons path,
.fp-viewing-contact .c-social a:hover .scicons path,
.fp-viewing-Enquiryform .c-social a:hover .scicons path,
.fp-viewing-whatwedo .c-social a:hover .scicons path,
.fp-viewing-clients .c-social a:hover .scicons path,
.fp-viewing-casestudies .c-social a:hover .scicons path,
.fp-viewing-contact .c-social a:hover .scicons path {
  fill: rgba(0, 0, 0, 0.6); }

.sec-about .c-background, .sec-featured .c-background {
  width: 40%;
  left: auto;
  right: 0; }
  @media (max-width: 991px) {
    .sec-about .c-background, .sec-featured .c-background {
      position: relative;
      width: 100%;
      height: 40%; } }

.sec-about .about-content, .sec-featured .about-content {
  width: 60%;
  max-width: 590px; }
  @media (max-width: 991px) {
    .sec-about .about-content, .sec-featured .about-content {
      width: 100%;
      max-width: none;
      margin-top: 30px; } }

.sec-about .hd-style1, .sec-featured .hd-style1 {
  padding-right: 80px; }

.sec-about .cta-btn, .sec-featured .cta-btn {
  margin-top: 90px; }
  @media (max-width: 1919px) {
    .sec-about .cta-btn, .sec-featured .cta-btn {
      margin-top: 60px; } }
  @media (max-width: 1199px) {
    .sec-about .cta-btn, .sec-featured .cta-btn {
      margin-top: 40px; } }

.sec-about.toLeft .c-background, .toLeft.sec-featured .c-background {
  right: auto;
  left: 0; }

.sec-about.toLeft .about-content, .toLeft.sec-featured .about-content {
  margin-left: auto; }

.hd-style1 {
  font-size: 30px;
  line-height: 1.5;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold; }
  .hd-style1 small {
    display: block;
    font-size: 20px;
    font-weight: normal;
    font-style: normal; }
  @media (max-width: 1919px) {
    .hd-style1 {
      font-size: 26px; }
      .hd-style1 small {
        font-size: 19px; } }
  @media (max-width: 1199px) {
    .hd-style1 {
      font-size: 22px; }
      .hd-style1 small {
        font-size: 18px; } }

.servicesSlider {
  position: relative;
  width: 100%;
  height: 100%; }
  .servicesSlider .section-heading {
    display: none; }
  .servicesSlider .sliderbackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #292829;
    top: 0;
    left: 0; }
    .servicesSlider .sliderbackground .slick-list,
    .servicesSlider .sliderbackground .slick-track,
    .servicesSlider .sliderbackground .slick-slide {
      width: 100%;
      height: 100%; }
    .servicesSlider .sliderbackground .slick-slide div {
      width: 100%;
      height: 100%; }
    .servicesSlider .sliderbackground .bg-img {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;
      background-attachment: scroll;
      background-repeat: no-repeat;
      overflow: hidden; }
  .servicesSlider .contentarea {
    display: flex;
    align-items: center;
    height: 100%; }
    .servicesSlider .contentarea .flexarea {
      display: flex;
      padding: 0 80px;
      position: relative; }
      @media (max-width: 1199px) {
        .servicesSlider .contentarea .flexarea {
          padding: 0; } }
      .servicesSlider .contentarea .flexarea .leftside {
        display: block;
        width: 40%;
        flex-shrink: 0;
        min-height: 100%;
        background-color: #8dc63f; }
        .servicesSlider .contentarea .flexarea .leftside .slick-list,
        .servicesSlider .contentarea .flexarea .leftside .slick-track,
        .servicesSlider .contentarea .flexarea .leftside .slick-slide {
          width: 100%;
          height: 100%; }
        .servicesSlider .contentarea .flexarea .leftside .slick-slide div {
          width: 100%;
          height: 100%; }
        .servicesSlider .contentarea .flexarea .leftside .thumb {
          width: 100%;
          height: 100%;
          display: block;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          background-attachment: scroll; }
      .servicesSlider .contentarea .flexarea .rightside {
        background-color: #FFFFFF;
        position: relative;
        padding: 40px 40px 80px 40px;
        width: 60%;
        flex-shrink: 0; }
        .servicesSlider .contentarea .flexarea .rightside .slide-content {
          position: relative;
          width: 100%;
          padding: 10px; }
          .servicesSlider .contentarea .flexarea .rightside .slide-content .cta-btn {
            margin-top: 20px; }
        @media (max-width: 1199px) {
          .servicesSlider .contentarea .flexarea .rightside {
            padding: 25px 25px 50px 25px; } }
      .servicesSlider .contentarea .flexarea::before {
        content: '';
        height: 100%;
        width: calc(100% - 160px);
        position: absolute;
        left: 80px;
        top: 0;
        background-color: transparent;
        box-shadow: -30px 30px 50px rgba(33, 36, 34, 0.35);
        z-index: 0; }
  @media (max-width: 991px) {
    .servicesSlider .contentarea .flexarea {
      flex-direction: column; }
      .servicesSlider .contentarea .flexarea .leftside {
        width: 100%; }
        .servicesSlider .contentarea .flexarea .leftside .thumb {
          padding-top: 48%;
          display: block !important; }
      .servicesSlider .contentarea .flexarea .rightside {
        width: 100%;
        padding: 40px; }
        .servicesSlider .contentarea .flexarea .rightside .slide-content .cta-btn {
          margin-top: 10px; } }

.casestudySlider {
  position: relative;
  width: 100%;
  height: 100%; }
  .casestudySlider .sliderbackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #292829;
    top: 0;
    left: 0; }
    .casestudySlider .sliderbackground .slick-list,
    .casestudySlider .sliderbackground .slick-track,
    .casestudySlider .sliderbackground .slick-slide {
      width: 100%;
      height: 100%; }
    .casestudySlider .sliderbackground .slick-slide div {
      width: 100%;
      height: 100%; }
    .casestudySlider .sliderbackground .bg-img {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;
      background-attachment: scroll;
      background-repeat: no-repeat;
      overflow: hidden; }
  .casestudySlider .contentarea {
    display: flex;
    align-items: center;
    height: 100%; }
    .casestudySlider .contentarea .flexarea {
      display: flex;
      padding: 0 80px; }
      .casestudySlider .contentarea .flexarea .leftside {
        display: block;
        width: 40%;
        flex-shrink: 0;
        min-height: 100%; }
      .casestudySlider .contentarea .flexarea .rightside {
        position: relative;
        padding: 40px 40px 120px 40px;
        width: 60%;
        flex-shrink: 0; }
        .casestudySlider .contentarea .flexarea .rightside .slide-content {
          position: relative;
          width: 100%;
          padding: 10px; }
          .casestudySlider .contentarea .flexarea .rightside .slide-content .cta-btn {
            margin-top: 40px; }
  .casestudySlider .slidenumber small {
    display: inline-block; }
  @media (max-width: 991px) {
    .casestudySlider .contentarea {
      align-items: flex-end; }
      .casestudySlider .contentarea .flexarea {
        padding: 0 0 80px 0;
        margin-bottom: 100px; }
        .casestudySlider .contentarea .flexarea .leftside {
          display: none; }
        .casestudySlider .contentarea .flexarea .rightside {
          width: 100%;
          position: relative;
          padding: 0; }
          .casestudySlider .contentarea .flexarea .rightside .slide-content .cta-btn {
            margin-top: 10px; }
    .casestudySlider .slidenumber small {
      display: inline-block; } }

.slidernavigation {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px 40px;
  font-size: 0; }
  .slidernavigation a {
    display: inline-block;
    padding: 8px 15px; }
    .slidernavigation a .ctaicon {
      width: 30px; }
    .slidernavigation a:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.2); }
    .slidernavigation a.disabled, .slidernavigation a.slick-disabled {
      pointer-events: none; }
      .slidernavigation a.disabled .ctaicon, .slidernavigation a.slick-disabled .ctaicon {
        opacity: 0.4; }
  @media (max-width: 1199px) {
    .slidernavigation {
      padding: 25px; } }
  @media (max-width: 991px) {
    .slidernavigation {
      left: 0;
      top: 100%;
      right: auto;
      bottom: auto;
      padding: 25px 0; } }

@media (max-width: 767px) {
  .sec-services .slidernavigation a:first-child {
    border-right-color: rgba(255, 255, 255, 0.2);
    padding-left: 0; }
  .sec-services .slidernavigation a .tosvg.svgBlack path {
    fill: #FFFFFF !important; } }

.slidenumber {
  display: block;
  position: absolute;
  font-size: 50px;
  font-family: "Futura";
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
  right: 0;
  bottom: -70px;
  font-weight: bold; }
  .slidenumber .slick-dots {
    font-size: inherit; }
  .slidenumber small {
    font-size: 25px;
    font-weight: normal;
    display: none; }
  .slidenumber ul {
    padding: 0;
    list-style: none;
    margin: 0; }
    .slidenumber ul li {
      display: none; }
      .slidenumber ul li.slick-active {
        display: block; }
  @media (max-width: 1199px) {
    .slidenumber {
      font-size: 40px; } }

.historyTimeline .slidenumber {
  text-align: right;
  position: relative;
  color: #292829;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto; }
  .historyTimeline .slidenumber .slick-dots {
    text-align: right; }
  .historyTimeline .slidenumber small {
    display: inline-block; }

.fphomeslide {
  position: relative; }
  .fphomeslide .c-background {
    position: absolute; }

.sec-casestudy {
  color: #FFFFFF; }
  .sec-casestudy .slide-data .thumb {
    background-color: transparent; }
  .sec-casestudy .slide-data .slide-content {
    background-color: transparent; }
  .sec-casestudy .slidernavigation {
    padding: 40px 0; }
    .sec-casestudy .slidernavigation a:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.2); }
    .sec-casestudy .slidernavigation a:last-child {
      padding-right: 0; }

.footer-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-end; }
  .footer-nav .brand-logo {
    margin-right: 40px;
    height: auto; }
  .footer-nav .foo-nav-item {
    padding: 0 20px;
    text-transform: uppercase;
    font-size: 13px; }

.innerpage .footer-nav {
  padding-top: 40px;
  border-top: 1px solid rgba(137, 137, 137, 0.3); }

.innerpage .banner-content {
  max-width: 50%; }
  @media (max-width: 1199px) {
    .innerpage .banner-content {
      padding-right: 0;
      max-width: 60%; } }

.copyright {
  padding: 25px 0 25px 0;
  margin-top: 20px;
  border-top: 1px solid rgba(137, 137, 137, 0.3);
  font-size: 0;
  text-align: center; }
  .copyright .foo-nav-item {
    display: inline-block;
    padding: 0 40px;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1.3; }
    .copyright .foo-nav-item:last-child {
      border-left: 1px solid; }

.footer-search {
  display: flex;
  padding: 50px 0 20px 0;
  align-items: flex-end;
  justify-content: space-between; }
  .footer-search .search-box {
    display: block;
    position: relative;
    font-size: 0; }
    .footer-search .search-box form {
      font-size: 0; }
    .footer-search .search-box label {
      display: block;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .footer-search .search-box input {
      display: inline-block;
      vertical-align: top;
      width: 260px;
      height: 40px;
      border: 1px solid #cacaca;
      border-right: 0;
      border-radius: 0;
      line-height: 40px;
      padding: 0 10px;
      margin: 0;
      font-family: "Futura";
      font-size: 15px; }
      .footer-search .search-box input.error {
        border-color: red; }
      .footer-search .search-box input.valid {
        border-color: green; }
        .footer-search .search-box input.valid + button + .error + .valid {
          display: block; }
    .footer-search .search-box button {
      display: inline-block;
      vertical-align: top;
      background-color: #cacaca;
      border: 1px solid #cacaca;
      height: 40px;
      width: auto;
      max-width: 60px;
      box-shadow: none;
      cursor: pointer;
      margin-left: -1px;
      transition: all 0.35s ease 0s; }
      .footer-search .search-box button:hover {
        background-color: #292829; }
      .footer-search .search-box button .searchicon {
        height: 12px;
        line-height: 40px;
        padding: 0 10px; }
    .footer-search .search-box .validation-error {
      position: absolute;
      color: red;
      font-size: 12px !important;
      margin: 0;
      padding: 5px 0; }
      .footer-search .search-box .validation-error label {
        text-transform: none;
        font-size: 12px; }
    .footer-search .search-box .validation-valid {
      display: none;
      position: absolute;
      color: green;
      font-size: 12px !important;
      margin: 0;
      padding: 5px 0;
      text-transform: none; }

.footer-social {
  display: flex;
  flex-direction: row; }
  .footer-social a {
    display: block;
    width: 36px;
    height: 36px;
    position: relative;
    margin: 0 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #cacaca;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 50%;
    will-change: background-color;
    transition: all 0.35s ease 0s; }
    .footer-social a .scicons {
      max-width: 100%;
      max-height: 100%;
      display: block; }
      .footer-social a .scicons path {
        transition: all 0.35s ease 0s;
        fill: #cacaca !important; }
    .footer-social a span {
      display: none; }
    .footer-social a:hover {
      background-color: #cacaca; }
      .footer-social a:hover .scicons path {
        fill: #FFFFFF !important; }
  .footer-social.contact-social a {
    width: 59px;
    height: 59px;
    margin: 0 12px;
    padding: 15px;
    line-height: 39px; }
    .footer-social.contact-social a .scicons {
      display: inline-block; }

.c-menuScreen {
  z-index: 19;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.9) url(../img/background/bg25.jpg) center center no-repeat scroll;
  background-size: cover;
  display: none;
  overflow: hidden; }
  .c-menuScreen .menuscreen-cover {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    padding-top: 120px;
    position: relative; }
  .c-menuScreen .c-btn-menu {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    padding: 55px 40px 0 40px;
    width: auto;
    height: auto;
    display: table; }
    .c-menuScreen .c-btn-menu .closeicon {
      width: 20px;
      height: 20px; }
      .c-menuScreen .c-btn-menu .closeicon polygon {
        fill: #FFFFFF; }
    @media (max-width: 1919px) {
      .c-menuScreen .c-btn-menu {
        padding: 37px 25px; } }
  .c-menuScreen .c-navigation {
    position: relative;
    display: block;
    width: 100%;
    overflow-y: auto; }
    .c-menuScreen .c-navigation > ul {
      display: block;
      list-style: none;
      padding: 0;
      margin: 0; }
      .c-menuScreen .c-navigation > ul > li {
        padding: 40px 160px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        background-color: rgba(255, 255, 255, 0);
        transition: all 0.35s ease 0s; }
        .c-menuScreen .c-navigation > ul > li > a {
          display: block;
          color: rgba(255, 255, 255, 0.25);
          font-size: 32px;
          font-weight: bold;
          font-style: italic;
          text-transform: uppercase;
          transition: all 0.35s ease 0s;
          max-width: 1200px;
          margin: 0 auto; }
        .c-menuScreen .c-navigation > ul > li .c-sub-nevigation {
          display: none;
          list-style: none;
          padding: 0;
          margin: 0;
          font-size: 0;
          max-width: 1200px;
          margin: 0 auto; }
          .c-menuScreen .c-navigation > ul > li .c-sub-nevigation > li {
            position: relative;
            display: inline-block; }
            .c-menuScreen .c-navigation > ul > li .c-sub-nevigation > li > a {
              display: block;
              font-size: 22px;
              font-weight: normal;
              padding: 40px 0 2px 0;
              margin: 0 30px;
              color: #ffffff;
              border-bottom: 1px solid rgba(255, 255, 255, 0);
              transition: all 0.35s ease 0s;
              will-change: color, border-bottom-color;
              text-transform: uppercase; }
            .c-menuScreen .c-navigation > ul > li .c-sub-nevigation > li:first-child a {
              margin-left: 0; }
            .c-menuScreen .c-navigation > ul > li .c-sub-nevigation > li.active a, .c-menuScreen .c-navigation > ul > li .c-sub-nevigation > li:hover a {
              color: #8dc63f;
              border-bottom-color: #8dc63f; }
        .c-menuScreen .c-navigation > ul > li.js-hasChild > a:after {
          content: "";
          width: 24px;
          height: 24px;
          margin-left: 50px;
          display: inline-block;
          background-image: url(../img/icons/ic-angle-right-white.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transform-origin: center;
          transform: rotate(0deg);
          opacity: 0.25;
          transition: 0.35s all ease 0s; }
        .c-menuScreen .c-navigation > ul > li.js-hasChild.active > a:after {
          transform: rotate(90deg);
          opacity: 1; }
        .c-menuScreen .c-navigation > ul > li.active, .c-menuScreen .c-navigation > ul > li:hover {
          background-color: rgba(255, 255, 255, 0.1); }
          .c-menuScreen .c-navigation > ul > li.active a, .c-menuScreen .c-navigation > ul > li:hover a {
            color: white; }
            .c-menuScreen .c-navigation > ul > li.active a:after, .c-menuScreen .c-navigation > ul > li:hover a:after {
              opacity: 1; }
  @media (max-width: 1199px) {
    .c-menuScreen .c-navigation > ul > li {
      padding: 25px 75px; }
      .c-menuScreen .c-navigation > ul > li > a {
        font-size: 24px; }
      .c-menuScreen .c-navigation > ul > li .c-sub-nevigation > li > a {
        font-size: 18px;
        padding: 25px 0 2px 0; } }

.fp-viewing-footer .c-social,
.fp-viewing-footer .c-fullpage-menu,
.fp-viewing-footer .c-chat-icons,
.fp-viewing-awards .c-social,
.fp-viewing-awards .c-fullpage-menu,
.fp-viewing-awards .c-chat-icons,
.fp-viewing-showcase .c-social,
.fp-viewing-showcase .c-fullpage-menu,
.fp-viewing-showcase .c-chat-icons,
.fp-viewing-Solutuon .c-social,
.fp-viewing-Solutuon .c-fullpage-menu,
.fp-viewing-Solutuon .c-chat-icons,
.fp-viewing-video .c-social,
.fp-viewing-video .c-fullpage-menu,
.fp-viewing-video .c-chat-icons {
  opacity: 0;
  visibility: hidden; }

.fp-viewing-teammembers .c-fullpage-menu,
.fp-viewing-teammembers .c-chat-icons {
  opacity: 0;
  visibility: hidden; }

.c-social,
.c-chat-icons {
  opacity: 1;
  transition: all 0.35s ease 0s;
  visibility: visible; }

.popup-is-active body {
  overflow: hidden !important; }

.menuopen {
  overflow: hidden !important; }
  .menuopen .fullpagesections {
    overflow: hidden; }
  .menuopen > .c-fullpage-menu,
  .menuopen > .c-social,
  .menuopen > .c-scroll-down,
  .menuopen > .c-chat-icons {
    opacity: 0;
    transition: opacity 0.25s ease 0s, margin 0s; }
  .menuopen .c-search-box {
    opacity: 0;
    visibility: hidden; }

/* ==================================== */
/* Popup CSS */
/* ==================================== */
.c-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%; }

.c-popup .overlay {
  display: block;
  height: 100%;
  width: 100%;
  background: transparent;
  transition: all .3s; }

.c-popup.popup--open .overlay {
  background: rgba(0, 0, 0, 0.5); }

.c-popup .popup {
  display: none;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 740px;
  padding: 20px;
  max-height: 100%;
  width: 100%;
  opacity: 0;
  top: 40%;
  transition: all .3s 0s;
  overflow-y: auto; }

.c-popup .popup.popup--full-screen {
  top: 0;
  left: 0;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: none; }

.c-popup .popup.popup--full-screen.active {
  top: 0; }

.c-popup .popup.popup--full-screen .popup-wrap {
  padding: 0;
  height: 100%; }

.c-popup .popup.popup--full-screen .popup-wrap .plyr {
  height: 100%; }

.c-popup .popup .popup-wrap {
  background: #fff;
  padding: 20px; }

.c-popup .popup.active {
  top: 50%;
  opacity: 1;
  transition-delay: .3s; }

.c-popup .popup.popup--full-screen .popup-action {
  position: absolute;
  top: 10px;
  right: 17px;
  z-index: 999;
  margin: 0; }

.c-popup .title {
  font-size: 1.5625em;
  line-height: 1.3em;
  margin: 0 0 15px;
  font-weight: bold;
  color: #8dc63f; }

.c-popup .txt-wrap {
  max-width: 500px;
  margin: 0 auto; }

.c-popup .deco {
  display: block;
  margin: 5px 0 15px 0;
  border: none;
  position: relative;
  height: 1px;
  background: #9f9a9a; }

.c-popup .deco:after {
  content: '';
  display: block;
  width: 80px;
  height: 6px;
  background: #8dc63f;
  position: absolute;
  bottom: 0px;
  left: 20px; }

.c-popup .popup-action {
  text-align: center;
  margin-top: 20px; }

.c-popup .js-video {
  height: 100%; }

/* ==================================== */
/* END - Popup CSS */
/* ==================================== */
/* ==================================== */
/* Popup Close BUtton  */
/* ==================================== */
.c-close {
  display: inline-block;
  width: 27px;
  height: 27px;
  background: url(../img/icons/ic-close.svg) center no-repeat scroll;
  background-size: 16px 16px;
  background-color: #292829;
  border-radius: 50%; }

.c-close span {
  display: none; }

/* ==================================== */
/* END - Popup Close BUtton  */
/* ==================================== */
/* Some responsive css for close button and popup */
@media (max-width: 767px) {
  .c-popup .deco:after {
    width: 60px;
    height: 4px;
    left: 15px; }
  .c-popup .popup .popup-wrap {
    padding: 15px; }
  .popup .c-form-1 {
    position: static;
    margin: 0; }
  .popup .c-form-1 .wrap {
    padding: 0; }
  .popup .c-form-1 select,
  .popup .c-form-1 .select2-container {
    width: 100% !important; } }

/* This will keep the video to be complete visible rather than partially! */
.plyr__video-wrapper {
  height: 100%;
  padding: 0 !important; }

.customsections .sec-footer {
  padding: 40px 40px 0; }

@media (max-width: 991px) {
  .customsections .sec-footer {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px; } }

@media (max-width: 767px) {
  .customsections .sec-footer {
    padding-top: 0; } }

@media (max-width: 374px) {
  .customsections .sec-footer {
    padding-left: 10px;
    padding-right: 10px; } }

.sec-pgcontent {
  padding: 80px 0; }
  .sec-pgcontent.form-sec {
    padding-bottom: 0; }
  @media (max-width: 991px) {
    .sec-pgcontent {
      padding: 40px 0; } }
  @media (max-width: 767px) {
    .sec-pgcontent {
      padding: 25px 0; }
      .sec-pgcontent.form-sec {
        padding-bottom: 25px; } }

.blog-detail .blog-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }

.blog-detail .blog-info {
  margin-top: 20px;
  display: flex;
  flex-align: center; }
  .blog-detail .blog-info .author-thumb {
    width: 60px;
    height: 60px;
    display: flex;
    overflow: hidden;
    border-radius: 50%;
    background-color: #FFFFFF;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px; }
    .blog-detail .blog-info .author-thumb img {
      width: 100%;
      height: auto; }
  .blog-detail .blog-info .author p {
    font-family: "Futura";
    font-size: 20px;
    line-height: 1.5; }
    .blog-detail .blog-info .author p small {
      display: block;
      font-size: 18px;
      opacity: 0.8; }

.blog-detail .blog-social {
  display: flex;
  flex-direction: row; }
  .blog-detail .blog-social a {
    display: block;
    width: 60px;
    height: 60px;
    position: relative;
    margin: 0 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #cacaca;
    color: #FFFFFF;
    padding: 16px;
    border-radius: 50%;
    will-change: background-color;
    transition: all 0.35s ease 0s; }
    .blog-detail .blog-social a .scicons {
      max-width: 100%;
      max-height: 100%;
      display: block; }
      .blog-detail .blog-social a .scicons path {
        transition: all 0.35s ease 0s;
        fill: #cacaca !important; }
    .blog-detail .blog-social a span {
      display: none; }
    .blog-detail .blog-social a:hover {
      background-color: #cacaca; }
      .blog-detail .blog-social a:hover .scicons path {
        fill: #FFFFFF !important; }

.blog-listing {
  display: flex;
  position: relative;
  margin: 0 -12px;
  flex-direction: row;
  flex-wrap: wrap; }
  .blog-listing .blog-item {
    display: block;
    position: relative;
    padding: 20px 12px;
    width: 33.3333%; }
    @media (max-width: 767px) {
      .blog-listing .blog-item {
        width: 100%; } }
    .blog-listing .blog-item h3 {
      display: block;
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 5px; }
    .blog-listing .blog-item p {
      font-size: 20px;
      line-height: 1.5;
      font-family: "Futura";
      max-height: 70px;
      overflow: hidden;
      text-overflow: ellipsis; }
    .blog-listing .blog-item .blog-thumb {
      width: 100%;
      padding-top: 80%;
      margin: 15px 0;
      position: relative;
      background-color: #cacaca;
      background-attachment: scroll;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top; }
      @media (max-width: 767px) {
        .blog-listing .blog-item .blog-thumb {
          margin: 0; } }
    .blog-listing .blog-item .blog-info {
      margin-top: 20px;
      display: flex;
      flex-align: center; }
      .blog-listing .blog-item .blog-info .author-thumb {
        width: 60px;
        height: 60px;
        display: flex;
        overflow: hidden;
        border-radius: 50%;
        background-color: #FFFFFF;
        flex-direction: column;
        justify-content: center;
        margin-right: 15px; }
        .blog-listing .blog-item .blog-info .author-thumb img {
          width: 100%;
          height: auto; }
      .blog-listing .blog-item .blog-info .author p {
        font-family: "Futura";
        font-size: 20px;
        line-height: 1.5; }
        .blog-listing .blog-item .blog-info .author p small {
          display: block;
          font-size: 18px;
          opacity: 0.8; }
  @media (max-width: 1919px) {
    .blog-listing .blog-item h3 {
      font-size: 26px; } }
  @media (max-width: 1439px) {
    .blog-listing .blog-item h3 {
      font-size: 23px; } }
  @media (max-width: 1199px) {
    .blog-listing .blog-item h3 {
      font-size: 21px; } }
  @media (max-width: 767px) {
    .blog-listing .blog-item h3 {
      font-size: 18px;
      margin-bottom: 10px; } }
  .blog-listing.slick-slider .blog-item {
    width: 100%; }
  .blog-listing .carousellisting {
    width: 100%; }
    .blog-listing .carousellisting .slick-track {
      margin-left: 0;
      margin-right: 0; }
  .blog-listing .sliderOptions {
    width: 100%; }

.sliderOptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 0;
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .sliderOptions {
      justify-content: space-between; } }
  @media (max-width: 767px) {
    .sliderOptions {
      padding: 25px 0;
      margin-bottom: 0px; } }
  .sliderOptions.flexend {
    justify-content: flex-end; }

.sliderarrows {
  position: relative;
  font-size: 0;
  margin-left: 40px; }
  .sliderarrows a {
    display: inline-block;
    padding: 8px 15px; }
    .sliderarrows a .ctaicon {
      width: 30px; }
    .sliderarrows a:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.2); }
    .sliderarrows a.disabled, .sliderarrows a.slick-disabled {
      pointer-events: none; }
      .sliderarrows a.disabled .ctaicon, .sliderarrows a.slick-disabled .ctaicon {
        opacity: 0.4; }

.slick-dots {
  display: block;
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: 0;
  list-style: none;
  font-size: 0;
  clear: both; }
  .slick-dots li {
    display: inline-block;
    margin: 0 3px; }
    .slick-dots li button {
      width: 13px;
      height: 13px;
      display: block;
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid #727272;
      border-radius: 50%;
      padding: 0;
      font-size: 0;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.35s ease 0s; }
      @media (max-width: 767px) {
        .slick-dots li button {
          width: 10px;
          height: 10px; } }
    .slick-dots li.slick-active button, .slick-dots li:hover button {
      background-color: #727272; }

.customgrid {
  display: block;
  margin-right: -12px;
  margin-left: -12px;
  font-size: 0; }
  .customgrid .grid {
    width: 33.333333%;
    display: inline-block;
    vertical-align: top;
    padding-right: 12px;
    padding-left: 12px; }
    .customgrid .grid.grid-half {
      width: 50%; }

.sec-subscribe {
  background-color: #fcfcfc;
  padding: 60px 0;
  color: #656667; }
  .sec-subscribe h3 {
    margin-bottom: 5px; }
  @media (max-width: 767px) {
    .sec-subscribe {
      display: none; } }

.subscribeform {
  display: flex;
  flex-direction: row;
  margin-top: 30px; }
  .subscribeform input {
    margin-right: 30px;
    border-color: #e7eaeb;
    color: #abacac; }
  .subscribeform .cta-btn {
    flex-shrink: 0; }
  .subscribeform .form-control::-moz-placeholder {
    color: #abacac;
    opacity: 1; }
  .subscribeform .form-control:-ms-input-placeholder {
    color: #abacac; }
  .subscribeform .form-control::-webkit-input-placeholder {
    color: #abacac; }
  @media (max-width: 991px) {
    .subscribeform input {
      margin-right: 10px; } }

.flexcontrol {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.sticky-cover {
  position: relative;
  height: 94px; }
  @media (max-width: 1919px) {
    .sticky-cover {
      height: 86px; } }
  @media (max-width: 767px) {
    .sticky-cover {
      height: 74px; } }
  @media (max-width: 359px) {
    .sticky-cover {
      height: 54px; } }

.sec-blognav {
  position: absolute;
  padding: 30px 0;
  background-color: #fcfcfc;
  z-index: 5;
  width: 100%;
  top: 0; }
  .sec-blognav.stick {
    position: fixed;
    top: 94px; }
    @media (max-width: 1919px) {
      .sec-blognav.stick {
        top: 86px; } }
    @media (max-width: 767px) {
      .sec-blognav.stick {
        top: 74px; } }
    @media (max-width: 359px) {
      .sec-blognav.stick {
        top: 54px; } }
  .sec-blognav .nav {
    display: inline-block; }
    .sec-blognav .nav a {
      text-transform: uppercase;
      font-size: 19px;
      opacity: 0.6;
      padding: 0 30px; }
      .sec-blognav .nav a:first-child {
        padding-left: 0; }
      .sec-blognav .nav a:last-child {
        padding-right: 0; }
      .sec-blognav .nav a.active, .sec-blognav .nav a:hover {
        opacity: 1;
        position: relative; }
        .sec-blognav .nav a.active:after, .sec-blognav .nav a:hover:after {
          content: "";
          height: 2px;
          background-color: #292829;
          position: absolute;
          bottom: -5px;
          left: 30px;
          right: 30px; }
        .sec-blognav .nav a.active:first-child:after, .sec-blognav .nav a:hover:first-child:after {
          left: 0; }
        .sec-blognav .nav a.active:last-child:after, .sec-blognav .nav a:hover:last-child:after {
          right: 0; }
  .sec-blognav .blog-search {
    display: flex; }
    .sec-blognav .blog-search .c-btn-search {
      cursor: pointer;
      box-shadow: 0;
      outline: none;
      border: 0;
      padding: 0;
      margin: 0;
      display: inline-block;
      background-color: transparent; }
      .sec-blognav .blog-search .c-btn-search .searchicon {
        display: block;
        width: 34px;
        height: 34px; }
        @media (max-width: 1919px) {
          .sec-blognav .blog-search .c-btn-search .searchicon {
            width: 26px;
            height: 26px; } }
        .sec-blognav .blog-search .c-btn-search .searchicon path {
          fill: #292829 !important;
          stroke: none !important;
          transition: all 0.45s ease 0s; }
    .sec-blognav .blog-search input {
      border-radius: 0;
      border: 0;
      box-shadow: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      width: 250px;
      margin-right: 10px;
      font-size: 19px;
      font-family: "Futura";
      opacity: 1;
      visibility: visible; }
      .sec-blognav .blog-search input::placeholder {
        opacity: 1;
        color: #292829; }

.historyTimeline {
  display: block;
  position: relative;
  padding: 40px 0 0 0;
  overflow: hidden; }
  .historyTimeline .timlineButtons {
    position: relative;
    width: 100%;
    margin-bottom: 80px; }
    @media (max-width: 991px) {
      .historyTimeline .timlineButtons {
        margin-bottom: 40px; } }
    .historyTimeline .timlineButtons:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 15px;
      width: 100%;
      height: 1px;
      background-color: #c9c9c9;
      z-index: 0; }
    .historyTimeline .timlineButtons .timelinebtn {
      display: block;
      position: relative;
      padding: 0;
      margin: 0;
      font-size: 0;
      z-index: 1; }
      .historyTimeline .timlineButtons .timelinebtn .itemSlide {
        padding: 80px 80px 0 80px;
        display: inline-block;
        font-size: 20px;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        text-align: center; }
        @media (max-width: 1199px) {
          .historyTimeline .timlineButtons .timelinebtn .itemSlide {
            font-size: 14px; } }
        .historyTimeline .timlineButtons .timelinebtn .itemSlide > span {
          position: absolute;
          display: block;
          left: 50%;
          transform: translateX(-50%) scale(1);
          bottom: 20px;
          z-index: 3;
          color: #c9c9c9;
          transition: all 0.35s ease 0s; }
        .historyTimeline .timlineButtons .timelinebtn .itemSlide:before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 8px;
          height: 8px;
          background-color: #c9c9c9;
          transform: translateX(-50%);
          border-radius: 50%;
          z-index: 2; }
        .historyTimeline .timlineButtons .timelinebtn .itemSlide:after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -11px;
          width: 30px;
          height: 30px;
          background-color: #FFFFFF;
          transform: translateX(-50%);
          border-radius: 50%;
          z-index: 1;
          opacity: 0; }
      .historyTimeline .timlineButtons .timelinebtn .slick-current .itemSlide > span {
        transform: translateX(-50%) scale(1.3);
        color: #292829; }
      .historyTimeline .timlineButtons .timelinebtn .slick-current .itemSlide:after {
        opacity: 1; }
      .historyTimeline .timlineButtons .timelinebtn .slick-current .itemSlide:before {
        background-color: #292829; }

@media (max-width: 991px) {
  .js-timelineBlocks-1 .slick-dots {
    padding: 10px 0; } }

.timelineBlocks {
  display: block;
  position: relative; }
  .timelineBlocks .slideContent {
    padding: 80px 80px 0 80px;
    width: 70%; }
    @media (max-width: 991px) {
      .timelineBlocks .slideContent {
        padding: 40px 0 0 0; } }
  .timelineBlocks .thumbsBox {
    display: block;
    text-align: center;
    position: relative; }
    .timelineBlocks .thumbsBox ul {
      width: 100%;
      position: relative;
      font-size: 0; }
      .timelineBlocks .thumbsBox ul li {
        width: 33.3333%;
        display: inline-block;
        z-index: 1;
        position: relative;
        vertical-align: top;
        text-align: center;
        transform: translate(125%, 0) translateZ(0);
        transform-origin: center;
        transition: all 0.35s ease 1s;
        opacity: 0; }
        .timelineBlocks .thumbsBox ul li img {
          max-width: 100%;
          position: relative;
          z-index: 0;
          max-height: 300px;
          display: block;
          margin: 0 auto; }
        .timelineBlocks .thumbsBox ul li .banner-playvdo {
          position: absolute;
          z-index: 1;
          left: 50%;
          top: 50%; }
        .timelineBlocks .thumbsBox ul li:nth-child(1) img {
          margin-top: 5%; }
        .timelineBlocks .thumbsBox ul li:nth-child(2) {
          transform: translate(150%, 0) translateZ(0); }
          .timelineBlocks .thumbsBox ul li:nth-child(2) img {
            margin-top: 25%; }
  .timelineBlocks .slick-current ul li {
    opacity: 1;
    transform: translate(0%, 0%) translateZ(0);
    transition: all 0.4s ease 0.8s; }
    .timelineBlocks .slick-current ul li:nth-child(2) {
      transform: translate(25%, 0) translateZ(0);
      transition: all 0.9s ease 0.9s; }
    .timelineBlocks .slick-current ul li:nth-child(1) {
      transition: all 0.6s ease 0.6s; }

/*FIle Input*/
.custom-fileselect input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }
  .custom-fileselect input.error + label {
    border-color: red; }
  .custom-fileselect input + label {
    position: relative;
    display: block;
    width: 100%;
    height: 54px;
    padding: 7px 20px;
    font-size: 18px;
    line-height: 54px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #292829;
    background-color: #FFFFFF; }
    .custom-fileselect input + label span {
      width: 100%;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 50px;
      height: 100%;
      line-height: 38px; }
    .custom-fileselect input + label .iconUpload {
      position: absolute;
      width: 23px;
      right: 20px;
      top: 10px; }

.formBox {
  padding: 40px 0; }
  .formBox .form-group {
    margin-bottom: 30px; }
  @media (max-width: 767px) {
    .formBox {
      padding: 25px 0;
      overflow: hidden; } }
  .formBox .validation-error {
    color: red;
    font-size: 12px !important;
    margin: 0;
    padding: 5px 0; }
    .formBox .validation-error label {
      font-size: 12px;
      display: block; }

.textContent + .formBox {
  padding-top: 80px; }
  @media (max-width: 767px) {
    .textContent + .formBox {
      padding-top: 40px; } }

.block-img {
  margin: 0 0 40px 0; }

.para-img {
  float: left;
  max-width: 40%;
  margin-right: 20px;
  display: inline-block;
  margin-top: 10px;
  position: relative; }
  .para-img img {
    width: 100%; }
  .para-img.right {
    float: right;
    margin-right: 0px;
    margin-left: 20px; }

hr {
  border: none;
  border-bottom: 1px solid #a8a8aa;
  margin: 40px 0; }
  hr.padded-hr {
    margin: 120px 0; }
  @media (max-width: 767px) {
    hr.padded-hr {
      margin: 25px 0; } }

.sec-teammembers .fp-tableCell {
  display: block;
  padding-top: 94px !important; }

.sec-teammembers .flexcontrol {
  height: 100%;
  overflow: hidden; }

.sec-teammembers .teamsection {
  display: block;
  height: 100%; }

.sec-teammembers .team-leftSide,
.sec-teammembers .team-rightSide {
  height: 100%;
  width: 50%;
  background-color: #FFFFFF; }

.sec-teammembers .teamscroll {
  max-width: 585px;
  margin: 0 0 0 auto;
  padding: 80px 20px 80px 0;
  overflow: hidden;
  height: 100%; }
  @media (max-width: 1919px) {
    .sec-teammembers .teamscroll {
      margin: 0 auto 0 0; } }
  @media (max-width: 1439px) {
    .sec-teammembers .teamscroll {
      max-width: 496px; } }
  @media (max-width: 1199px) {
    .sec-teammembers .teamscroll {
      max-width: 375px; } }
  @media (max-width: 991px) {
    .sec-teammembers .teamscroll {
      max-width: 100%; } }

.sec-teammembers .memberitem {
  display: block;
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid rgba(95, 95, 95, 0.5);
  opacity: 0.5;
  transition: all 0.35s ease 0s;
  will-change: opacity; }
  .sec-teammembers .memberitem:last-child {
    border-bottom-color: rgba(0, 0, 0, 0); }
  .sec-teammembers .memberitem.active {
    opacity: 1; }
  .sec-teammembers .memberitem .hd-style1 {
    margin: 0; }
    .sec-teammembers .memberitem .hd-style1 small {
      font-family: "Georgia";
      text-transform: none; }

.sec-teammembers .team-detail {
  position: relative;
  height: 100%; }

.sec-teammembers .member-detail {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease 0s; }
  .sec-teammembers .member-detail.active {
    opacity: 1;
    visibility: visible; }
  .sec-teammembers .member-detail .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .sec-teammembers .member-detail .content {
    opacity: 0;
    padding: 80px;
    background-color: rgba(41, 40, 41, 0.8);
    height: 100%;
    position: relative;
    z-index: 2;
    color: #cacaca;
    font-family: "Futura";
    display: flex;
    flex-direction: column;
    transition: all 0.35s ease 0s; }
    @media (max-width: 1199px) {
      .sec-teammembers .member-detail .content {
        padding: 40px 80px 40px 25px; } }
    .sec-teammembers .member-detail .content .about-team {
      height: calc(100% - 46px); }
    .sec-teammembers .member-detail .content p {
      font-family: "Futura"; }
    .sec-teammembers .member-detail .content .scrollable {
      padding-right: 40px; }
      @media (max-width: 1199px) {
        .sec-teammembers .member-detail .content .scrollable {
          padding-right: 0; } }
  .sec-teammembers .member-detail.active {
    opacity: 1;
    visibility: visible; }
  .sec-teammembers .member-detail:hover .content, .sec-teammembers .member-detail.selected .content {
    opacity: 1; }

.sec-teammembers .mCustomScrollBox .mCSB_scrollTools {
  opacity: 0; }
  .sec-teammembers .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #cacaca;
    width: 8px;
    border-radius: 0; }
  .sec-teammembers .mCustomScrollBox .mCSB_scrollTools .mCSB_draggerRail {
    display: none; }

.sec-teammembers .mCustomScrollBox:hover .mCSB_scrollTools {
  opacity: 1; }

.mCSB_inside > .mCSB_container {
  margin-right: 15px; }

.content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }
  .content-head .teamicons {
    height: 23px;
    width: 23px; }
  .content-head .member-social a {
    margin: 0 10px;
    display: inline-block; }
  @media (max-width: 991px) {
    .content-head {
      margin-top: 20px; } }

.is--not-on-top .c-main-header {
  background: #FFFFFF; }

.is--not-on-top .header-logo.brand-logo.brand-white {
  background-image: url(../img/brand/logo-green.png); }

.is--not-on-top .c-btn-menu .tosvg.svgWhite path {
  fill: #8dc63f !important; }

.is--not-on-top .c-search-box .c-btn-search .searchicon path {
  fill: #8dc63f !important; }

.is--not-on-top .c-search-box.searching {
  background-color: #f0f0f0; }

.is--not-on-top .c-search-box.searching .c-btn-search .searchicon path {
  fill: #292829 !important; }

.is--not-on-top.menuopen .c-main-header {
  background: transparent; }

.is--not-on-top.menuopen .brand-logo.brand-white {
  background-image: url(../img/brand/logo-white.png); }

.is--not-on-top.menuopen .c-search-box.searching {
  background-color: #FFFFFF; }

.is--not-on-top.menuopen .c-search-box .c-btn-search .searchicon path {
  fill: #FFFFFF !important; }

.is--not-on-top.menuopen .c-btn-menu .tosvg.svgWhite path {
  fill: #FFFFFF !important; }

.visible-mobile {
  display: none !important; }

.visible-tabletP {
  display: none !important; }

.sec-services .fp-tableCell .servicesSlider,
.sec-services .fp-tableCell .casestudySlider,
.sec-casestudy .fp-tableCell .servicesSlider,
.sec-casestudy .fp-tableCell .casestudySlider {
  padding-top: 94px !important; }
  @media (max-width: 1919px) {
    .sec-services .fp-tableCell .servicesSlider,
    .sec-services .fp-tableCell .casestudySlider,
    .sec-casestudy .fp-tableCell .servicesSlider,
    .sec-casestudy .fp-tableCell .casestudySlider {
      padding-top: 86px !important; } }
  @media (max-width: 767px) {
    .sec-services .fp-tableCell .servicesSlider,
    .sec-services .fp-tableCell .casestudySlider,
    .sec-casestudy .fp-tableCell .servicesSlider,
    .sec-casestudy .fp-tableCell .casestudySlider {
      padding-top: 74px !important; } }
  @media (max-width: 359px) {
    .sec-services .fp-tableCell .servicesSlider,
    .sec-services .fp-tableCell .casestudySlider,
    .sec-casestudy .fp-tableCell .servicesSlider,
    .sec-casestudy .fp-tableCell .casestudySlider {
      padding-top: 54px !important; } }

.showcase .slick-dots {
  clear: both; }

.showcase .slick-slide .item {
  pointer-events: none; }

.showcase .slick-slide.slick-current .item {
  pointer-events: auto; }
  .showcase .slick-slide.slick-current .item.no-hover {
    pointer-events: none; }

input,
select,
textarea {
  -webkit-appearance: none; }

select.form-control {
  background-image: url(../img/icons/ic-angle-down.svg);
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 18px;
  background-origin: right; }

@media (max-width: 1199px) {
  .menuopen > .c-chat-icons,
  .menuopen > .c-fullpage-menu,
  .menuopen > .c-main-header {
    margin-right: 0; } }

@media (max-width: 991px) {
  .hidden-tabletP {
    display: none !important; }
  .visible-tabletP {
    display: block !important; }
    .visible-tabletP.fp-section {
      display: table !important; }
  .teamAccordian.accordion-box {
    margin: 20px 0 0 0; }
    .teamAccordian.accordion-box .accordion-item:first-child:after {
      display: none; }
  .showcase .grid.grid-half {
    width: 100%; }
  .fullpagesections .fp-auto-height.fp-section .fp-tableCell {
    padding: 25px 0; }
  .fullpagesections .fp-section.sec-footer .fp-tableCell {
    padding: 25px 25px 0; }
  .counter-box > .counter-item {
    padding: 10px 20px; }
    .counter-box > .counter-item p {
      font-size: 16px; } }

@media (max-width: 767px) {
  .showcase .grid .item .item-content .hd-style1 {
    text-align: center;
    padding-bottom: 10px; }
  .showcase .grid .item .item-content p {
    font-size: 14px;
    padding-top: 10px;
    line-height: 1.6;
    text-align: center; }
  .customsections .sec-footer {
    padding: 0; }
  .sec-featured {
    background-color: transparent; }
  .sec-clients .textContent.textCenter {
    padding: 30px 0; }
    .sec-clients .textContent.textCenter .hd-style1 {
      margin-bottom: 10px; }
  .sec-video-fp {
    padding: 25px;
    width: 100%; }
    .sec-video-fp .c-background {
      padding-top: 80%;
      position: relative; }
  .sec-home .banner-content {
    max-width: none;
    width: 100%;
    padding: 0 25px 40px 25px; }
  .header-logo {
    width: 85px;
    height: 34px; }
  .c-btn-menu {
    width: 30px;
    height: 23px; }
  .c-search-box {
    padding: 6px 8px; }
    .c-search-box .c-btn-search .searchicon {
      width: 22px;
      height: 22px; }
    .c-search-box input {
      font-size: 14px; }
    .c-search-box.searching input {
      width: 140px; }
  .sec-home .banner-content .cta-btn {
    margin-top: 10px; }
  .is--not-on-top .c-main-header {
    background: #fff; }
  .sec-about, .sec-featured,
  .sec-featured,
  .sec-casestudies,
  .sec-careers,
  .sec-contact {
    padding-top: 25px;
    padding-bottom: 25px; }
    .sec-about .c-background, .sec-featured .c-background,
    .sec-featured .c-background,
    .sec-casestudies .c-background,
    .sec-careers .c-background,
    .sec-contact .c-background {
      height: auto;
      width: auto;
      margin-left: 25px;
      margin-right: 25px; }
      .sec-about .c-background .bg-img, .sec-featured .c-background .bg-img,
      .sec-featured .c-background .bg-img,
      .sec-casestudies .c-background .bg-img,
      .sec-careers .c-background .bg-img,
      .sec-contact .c-background .bg-img {
        padding-top: 60%;
        position: relative;
        transform: none;
        top: auto;
        left: auto; }
    .sec-about .hd-style1, .sec-featured .hd-style1,
    .sec-featured .hd-style1,
    .sec-casestudies .hd-style1,
    .sec-careers .hd-style1,
    .sec-contact .hd-style1 {
      padding: 0; }
    .sec-about .cta-btn, .sec-featured .cta-btn,
    .sec-featured .cta-btn,
    .sec-casestudies .cta-btn,
    .sec-careers .cta-btn,
    .sec-contact .cta-btn {
      margin-top: 10px; }
  .servicesSlider .contentarea {
    padding: 25px 0;
    position: relative;
    padding-bottom: 70px; }
    .servicesSlider .contentarea .section-heading {
      text-align: left;
      color: #FFFFFF;
      display: block;
      margin-bottom: 10px; }
    .servicesSlider .contentarea .flexarea .rightside {
      padding: 20px; }
      .servicesSlider .contentarea .flexarea .rightside .slide-content {
        padding: 0; }
        .servicesSlider .contentarea .flexarea .rightside .slide-content .hd-style1 {
          margin-bottom: 10px; }
        .servicesSlider .contentarea .flexarea .rightside .slide-content p {
          font-size: 14px; }
        .servicesSlider .contentarea .flexarea .rightside .slide-content .cta-btn {
          font-size: 16px;
          padding: 12px 12px; }
    .servicesSlider .contentarea .flexarea:before {
      width: 100%;
      left: 0;
      top: 0; }
  .casestudySlider .contentarea {
    padding: 210px 0 40px; }
    .casestudySlider .contentarea .flexarea {
      margin-bottom: 0; }
      .casestudySlider .contentarea .flexarea .rightside .slide-content {
        padding: 0; }
  .sec-footer .footer-search {
    align-items: center;
    flex-direction: column;
    text-align: center; }
  .sec-footer .copyright {
    border: 0;
    margin-top: 0;
    padding-top: 10px; }
    .sec-footer .copyright .foo-nav-item {
      font-size: 12px;
      padding: 10px 0 0; }
    .sec-footer .copyright .foo-nav-item:last-child {
      display: none; }
  .sec-footer .footer-nav {
    display: none;
    text-align: center;
    font-size: 0; }
    .sec-footer .footer-nav .foo-nav-item {
      padding: 0 18px;
      font-size: 16px;
      border-right: 1px solid;
      line-height: 10px; }
      .sec-footer .footer-nav .foo-nav-item:last-child {
        border-right: 0; }
  .sec-footer .footer-search {
    padding-top: 20px;
    padding-bottom: 40px; }
    .sec-footer .footer-search .search-box {
      border-bottom: 1px solid rgba(138, 138, 138, 0.3);
      width: 100%;
      padding-bottom: 40px;
      margin-bottom: 30px;
      padding: 0 25px 40px;
      text-align: left; }
      .sec-footer .footer-search .search-box label {
        font-size: 16px;
        margin-bottom: 20px;
        text-align: left;
        max-width: 290px; }
      .sec-footer .footer-search .search-box input {
        width: 280px;
        width: calc(100% - 65px); }
    .sec-footer .footer-search .brand-logo {
      margin-bottom: 30px; }
  .sec-footer .footer-social a {
    width: 46px;
    height: 46px;
    margin: 0 7px;
    padding: 13px; }
  .slidenumber {
    font-size: 36px;
    bottom: 0; }
    .slidenumber small {
      font-size: 20px; }
  .servicesSlider .slidenumber {
    bottom: 10px;
    color: #aaa;
    right: 10px; }
  .slidernavigation {
    padding-top: 25px; }
    .slidernavigation a {
      padding-top: 0;
      padding-bottom: 0; }
      .slidernavigation a:first-child {
        padding-left: 0; }
  .visible-mobile {
    display: block !important; }
  .c-menuScreen .menuscreen-cover {
    padding-top: 90px; }
  .c-menuScreen .c-navigation > ul > li {
    padding: 30px 35px; }
    .c-menuScreen .c-navigation > ul > li .c-sub-nevigation > li > a {
      font-size: 16px;
      margin: 0 25px 0 0;
      padding-top: 15px; }
    .c-menuScreen .c-navigation > ul > li.js-hasChild > a:after {
      width: 18px;
      height: 18px;
      margin-left: 20px; }
  .sec-aboutus,
  .sec-team {
    padding-top: 25px;
    padding-bottom: 25px; }
  .textContent {
    padding: 20px 0; }
    .textContent p {
      margin-bottom: 20px; }
  hr {
    margin: 20px 25px; }
  .container > hr {
    margin: 20px 0; }
    .container > hr + .hd-style1 {
      padding-top: 20px; }
  .para-styleBold {
    margin-bottom: 15px; }
  .fp-section > .container > hr {
    margin-bottom: 0;
    margin-top: 25px; }
  .menuopen.is--not-on-top .c-main-header {
    background: transparent; }
  .showcase .grid .item {
    text-align: left; }
  .sec-history {
    padding-top: 25px; }
  .historyTimeline {
    padding: 40px 0 25px; }
  .textContent.textCenter {
    padding: 40px 0;
    text-align: left; }
  .counter-box {
    flex-direction: column; }
    .counter-box > .counter-item {
      margin: 0;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      color: #292829;
      padding: 40px 0;
      text-align: left;
      display: flex;
      align-items: center; }
      .counter-box > .counter-item span {
        font-size: 50px; }
      .counter-box > .counter-item > span {
        min-width: 140px; }
      .counter-box > .counter-item p {
        width: 100%;
        margin: 0;
        font-size: 16px; }
  .timelineBlocks .slideContent {
    width: 100%;
    padding: 0; }
  .timelineBlocks .thumbsBox ul {
    text-align: left;
    margin-top: 30px; }
    .timelineBlocks .thumbsBox ul li {
      width: 75%; }
      .timelineBlocks .thumbsBox ul li:first-child {
        display: none; }
      .timelineBlocks .thumbsBox ul li:nth-child(3) {
        position: absolute;
        right: 0;
        top: 0; }
  .timelineBlocks .slick-current ul li:nth-child(2) {
    transform: translate(0%) translateZ(0); }
  .container-fluid .container {
    padding-left: 0;
    padding-right: 0; }
  .c-menuScreen .c-btn-menu {
    padding-top: 28px; }
  .innerpage .footer-nav {
    border: none;
    padding-top: 0; }
  .historyTimeline .slidenumber {
    display: none;
    margin-top: 10px; }
  .showcase.slick-initialized {
    padding: 0 25px;
    overflow: hidden; }
    .showcase.slick-initialized .slick-list {
      overflow: visible; }
    .showcase.slick-initialized .grid {
      padding: 25px 3px; }
      .showcase.slick-initialized .grid .item .item-content {
        display: none; }
    .showcase.slick-initialized .slick-slide {
      opacity: 0.4;
      transition: opacity 0.35s ease 0s;
      will-change: opacity; }
      .showcase.slick-initialized .slick-slide.slick-current {
        opacity: 1; }
    .showcase.slick-initialized.title-visible .grid .item .item-content {
      display: block; }
  .sec-blognav .blog-search {
    display: none; }
  .sec-blognav .nav a {
    padding: 0 10px; }
    .sec-blognav .nav a.active:after, .sec-blognav .nav a:hover:after {
      left: 10px;
      right: 10px; }
  .sec-blognav .flexcontrol {
    justify-content: center; }
  .blog-detail .blog-social a {
    width: 40px;
    height: 40px;
    padding: 10px; }
  .blog-detail .blog-info .author-thumb {
    width: 50px;
    height: 50px; }
  .blog-detail .blog-info .author p {
    margin-bottom: 0; }
  .para-img {
    margin-top: 4px;
    max-width: 100%; }
    .para-img.right {
      margin: 0; }
  .accordion-box .acc-heads {
    display: none; }
  .accordion-box .accordion-item .acc-title {
    flex-wrap: wrap;
    padding: 10px 0; }
    .accordion-box .accordion-item .acc-title > h2 {
      width: auto;
      flex: 1 1 50%;
      margin: 0;
      order: 3;
      font-size: 18px;
      padding: 10px 0; }
      .accordion-box .accordion-item .acc-title > h2:first-child {
        width: 100%;
        font-size: 22px;
        order: 1; }
    .accordion-box .accordion-item .acc-title:after {
      order: 2; }
  .accordion-box .accordion-item .acc-content .acc-cta {
    justify-content: space-between; }
    .accordion-box .accordion-item .acc-content .acc-cta .cta-btn {
      margin-left: 0; }
  .customgrid .grid.grid-half {
    width: 100%; }
  .footer-social.contact-social a {
    width: 45px;
    height: 45px;
    margin: 0px 3px;
    padding: 13px;
    line-height: 24px; }
    .footer-social.contact-social a svg {
      width: 17px;
      height: 17px; }
  .footer-social a .scicons {
    width: 18px;
    height: 18px; }
  .slidingBox {
    flex-direction: column; }
    .slidingBox h2 {
      padding: 0 15px;
      margin: 10px 0;
      font-size: 26px; }
    .slidingBox .sB-item {
      padding: 0px;
      width: 100% !important;
      margin: 10px 0; }
      .slidingBox .sB-item .sB-title {
        display: none; }
      .slidingBox .sB-item .sB-itemInner {
        width: 100%;
        visibility: visible;
        opacity: 1; }
        .slidingBox .sB-item .sB-itemInner .sB-content {
          padding: 0 15px;
          flex-direction: column; }
          .slidingBox .sB-item .sB-itemInner .sB-content .addressTXT,
          .slidingBox .sB-item .sB-itemInner .sB-content .numbersTXT {
            margin-bottom: 10px; }
            .slidingBox .sB-item .sB-itemInner .sB-content .addressTXT p,
            .slidingBox .sB-item .sB-itemInner .sB-content .numbersTXT p {
              font-size: 16px; }
            .slidingBox .sB-item .sB-itemInner .sB-content .addressTXT span,
            .slidingBox .sB-item .sB-itemInner .sB-content .numbersTXT span {
              font-size: 14px; }
          .slidingBox .sB-item .sB-itemInner .sB-content .empTXT {
            width: 100%;
            padding: 10px 0;
            display: none; }
            .slidingBox .sB-item .sB-itemInner .sB-content .empTXT svg {
              margin-left: 0;
              margin-right: 15px; }
  .showcase .grid .item .item-content {
    padding-left: 10px;
    padding-right: 10px; }
  .form-control {
    padding: 7px 10px; }
  .showcase.slick-initialized.title-visible .slick-current .grid .item:before {
    background-color: rgba(0, 0, 0, 0.85);
    transform: translateY(0); }
  .showcase.slick-initialized.title-visible .slick-current .grid .item .item-content {
    top: 0; }
    .showcase.slick-initialized.title-visible .slick-current .grid .item .item-content .hd-style1 {
      transform: translateY(0);
      margin-top: 0; }
    .showcase.slick-initialized.title-visible .slick-current .grid .item .item-content p {
      max-height: 999px;
      transition: all 1s ease .25s;
      display: block;
      opacity: 1; }
  .showcase .grid .item .item-content {
    padding-top: 20px;
    padding-bottom: 20px; }
    .showcase .grid .item .item-content .hd-style1 {
      font-size: 18px; }
    .showcase .grid .item .item-content p {
      font-size: 13px; } }

@media (max-width: 359px) {
  .hd-style1 {
    font-size: 20px; }
  .sec-video-fp {
    padding: 10px; }
  .showcase.slick-initialized {
    padding: 0 10px; }
    .showcase.slick-initialized .grid {
      padding: 25px 2px; }
  .c-main-header {
    padding: 10px; }
  .header-logo {
    width: 75px;
    left: 10px;
    top: 10px; }
  .sec-about .c-background, .sec-featured .c-background,
  .sec-featured .c-background {
    margin-left: 10px;
    margin-right: 10px; }
  .sec-home .banner-content {
    padding: 0 10px 20px; }
    .sec-home .banner-content p {
      font-size: 16px; }
    .sec-home .banner-content h2 {
      font-size: 34px; }
  .cta-btn {
    font-size: 16px;
    padding: 10px 12px; }
  .c-search-box.searching input {
    width: 130px; }
  .c-chat-icons {
    right: 10px;
    padding: 10px 0; }
  .c-menuScreen .c-btn-menu {
    padding: 17px 10px; }
  .sec-footer .footer-nav .foo-nav-item {
    padding: 0 14px; }
  hr {
    margin: 15px 10px; }
  .container > hr {
    margin: 15px 0; }
    .container > hr + .hd-style1 {
      padding-top: 15px; }
  .sec-footer .footer-search .search-box {
    padding-left: 10px;
    padding-right: 10px; } }

@media (min-width: 1024px) and (max-height: 850px) {
  .casestudySlider .contentarea .flexarea .rightside {
    padding: 20px 0 80px; }
  .sec-casestudy .slidernavigation {
    padding: 0 0 90px; }
  .slidenumber {
    bottom: -60px; }
  .servicesSlider .contentarea .flexarea .rightside {
    padding: 20px 40px 60px; }
  .servicesSlider .contentarea .flexarea .rightside .slide-content .cta-btn {
    margin-top: 15px; }
  .casestudySlider .contentarea .flexarea .rightside .slidenumber {
    bottom: -20px; } }

@media (min-width: 1024px) and (max-height: 700px) {
  .casestudySlider .contentarea .flexarea .rightside .slide-content {
    padding: 1px; }
    .casestudySlider .contentarea .flexarea .rightside .slide-content h1 {
      margin-bottom: 15px; }
    .casestudySlider .contentarea .flexarea .rightside .slide-content p {
      line-height: 1.4; }
    .casestudySlider .contentarea .flexarea .rightside .slide-content .cta-btn {
      margin-top: 10px; }
  .casestudySlider .contentarea .flexarea .rightside .slidenumber {
    bottom: 30px; } }
